import React from 'react'
import { Navbar } from '../../global/components/navbar/navbar'
import { Container, FlexBox } from '../../global/style/global.styles'
import { Footer } from '../../global/components/footer'
import PageStart from '../../global/components/pageStart'
import { SpacerLeftRightLarge, SpacerTopBottomXXXL } from '../../design/spacer/spacer'
import { AddNewBtn } from '../../global/components/addNewBtn'
import { ActionBtn } from '../../global/components/actionBtn'
import contactBtnBg from '../../assets/images/contactBtnBg.png'
import { Outlet } from 'react-router-dom'
import PageEnd from '../../global/components/pageEnd'
import { CRMWrapper } from './style/crm.styles'
import { Tooltip } from '@mui/material'

export const CRM = (props) => {
  return (
    <CRMWrapper>
      <Navbar />
      <PageStart />
      {
        props.location.pathname === "/crm"
        &&
        <Container>
          <h1>
            Leads Management
          </h1>
          <SpacerTopBottomXXXL />
          <FlexBox>
            <AddNewBtn
              disabled
              title="Add New Lead"
            />
            <SpacerLeftRightLarge />
            <ActionBtn
              title="Contact Form"
              bgImg={contactBtnBg}
              link="/crm/contactForm"
            />
          </FlexBox>
        </Container>
      }
      <Outlet />
      <PageEnd />
      <Footer />
    </CRMWrapper>
  )
}
