import React, { useState } from 'react'
import { ServicedBlockContainer } from '../style/crm.styles'
import { FlexBox, FlexBoxSB, FlexColumn, FlexColumnSB } from '../../../global/style/global.styles'
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import { SpacerLeftRightSmall, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall } from '../../../design/spacer/spacer';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import { Backdrop, Box, Divider, Fade, Modal, Tooltip } from '@mui/material';
import moment from 'moment';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import { DeleteAlert } from '../../../global/components/deleteAlert';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';

export const ServicedBlock = (props) => {

    const DeleteModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px',
        outline: 'none'
    };

    const [deleteModal, setDeleteModal] = useState(false)

    const handleDelete = async () => {
        await deleteDoc(doc(db, "leads", props.id))
        props.setRefresh(!props.refresh)
        setDeleteModal(false)
    }

    return (
        <>
            <ServicedBlockContainer>
                <FlexColumnSB style={{ height: "100%" }}>
                    <div>
                        <FlexBoxSB>
                            <FlexBox>
                                <SupervisedUserCircleRoundedIcon
                                    sx={{
                                        fontSize: 40,
                                        color: '#7BA6CD'
                                    }}
                                />
                                <SpacerLeftRightSmall />
                                <div style={{
                                    fontWeight: 700
                                }}>
                                    {props.name}
                                </div>
                            </FlexBox>
                            <Tooltip title='Delete' placement='top' onClick={() => setDeleteModal(true)}>
                                <CancelPresentationRoundedIcon
                                    color='error'
                                    sx={{
                                        cursor: "pointer"
                                    }}
                                />
                            </Tooltip>
                        </FlexBoxSB>
                        <SpacerTopBottomLarge>
                            <FlexBox>
                                <div
                                    style={{
                                        width: 40,
                                        height: 40
                                    }}
                                />
                                <div>
                                    {
                                        props.email
                                        &&
                                        <>
                                            <FlexBox>
                                                <EmailRoundedIcon
                                                    fontSize='small'
                                                />
                                                <SpacerLeftRightSmall />
                                                <div style={{
                                                    fontSize: 12,
                                                    fontWeight: 600
                                                }}>
                                                    {props.email}
                                                </div>
                                            </FlexBox>
                                            <SpacerTopBottomMedium />
                                        </>
                                    }
                                    {
                                        props.phone
                                        &&
                                        <>
                                            <FlexBox>
                                                <LocalPhoneRoundedIcon
                                                    fontSize='small'
                                                />
                                                <SpacerLeftRightSmall />
                                                <div style={{
                                                    fontSize: 12,
                                                    fontWeight: 600
                                                }}>
                                                    {props.phone}
                                                </div>
                                            </FlexBox>
                                            <SpacerTopBottomMedium />
                                        </>
                                    }
                                    {
                                        props.comName
                                        &&
                                        <FlexBox>
                                            <ApartmentRoundedIcon
                                                fontSize='small'
                                            />
                                            <SpacerLeftRightSmall />
                                            <div style={{
                                                fontSize: 12,
                                                fontWeight: 600
                                            }}>
                                                {props.comName}
                                            </div>
                                        </FlexBox>
                                    }
                                </div>
                            </FlexBox>
                        </SpacerTopBottomLarge>
                        <Divider />
                        <SpacerTopBottomLarge>
                            <FlexBox style={{ justifyContent: 'end' }}>
                                <div style={{
                                    fontSize: 10,
                                    fontWeight: 600
                                }}>
                                    {moment(props.createdAt).format("DD MMMM YYYY")}
                                </div>
                            </FlexBox>
                            <SpacerTopBottomMedium />
                            <div style={{
                                fontSize: 14
                            }}>
                                {props.req}
                            </div>
                        </SpacerTopBottomLarge>
                    </div>
                    <FlexBox style={{ justifyContent: 'end' }}>
                        <div style={{
                            fontSize: 12
                        }}>
                            {props.captured}
                        </div>
                    </FlexBox>
                </FlexColumnSB>
            </ServicedBlockContainer>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={deleteModal}
                onClose={() => setDeleteModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={deleteModal}>
                    <Box sx={DeleteModalStyle}>
                        <DeleteAlert
                            handleCancel={() => setDeleteModal(false)}
                            handleDelete={handleDelete}
                            deleteItemDetail={props.name}
                        />
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}
