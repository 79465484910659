import styled from 'styled-components'

// ----------------------------------------CONTAINERS----------------------------------------//

export const Container = styled.div`
    padding-top: ${(props) => props.theme.space[8]};
    padding-bottom: ${(props) => props.theme.space[8]};
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[20]};
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[32]};
        padding-right: ${(props) => props.theme.space[32]};
    }
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[12]};
        padding-right: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 850px) {
        padding-top: ${(props) => props.theme.space[4]};
        padding-bottom: ${(props) => props.theme.space[4]};
        padding-left: ${(props) => props.theme.space[6]};
        padding-right: ${(props) => props.theme.space[6]};
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 450px) {
        padding-top: ${(props) => props.theme.space[2]};
        padding-bottom: ${(props) => props.theme.space[2]};
        padding-left: ${(props) => props.theme.space[4]};
        padding-right: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 370px) {
        padding-top: ${(props) => props.theme.space[1]};
        padding-bottom: ${(props) => props.theme.space[1]};
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const Squeezer = styled.div`
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[20]};
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[32]};
        padding-right: ${(props) => props.theme.space[32]};
    }
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[12]};
        padding-right: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[6]};
        padding-right: ${(props) => props.theme.space[6]};
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[4]};
        padding-right: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const Sandwich = styled.div`
    padding-top: ${(props) => props.theme.space[8]};
    padding-bottom: ${(props) => props.theme.space[8]};

    @media only screen and (min-width: 1800px) {
    }
`;

export const SandwichHalf = styled.div`
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
`;

export const SandwichRealHalf = styled.div`
    padding-top: ${(props) => props.theme.space[4]};
    padding-bottom: ${(props) => props.theme.space[4]};
`;

// ----------------------------------------FLEX----------------------------------------//

export const FlexBoxSB = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const FlexBoxSA = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

export const FlexBoxSE = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`;

export const FlexBox = styled.div`
    display: flex;
    align-items: center;
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FlexColumnSA = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

export const FlexColumnSB = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const FlexColumnSE = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

// ----------------------------------------ALIGNMENT----------------------------------------//

export const AlignCenter = styled.div`
    text-align: center;
`;

// ----------------------------------------POSITION----------------------------------------//


export const AbsoluteCenter = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
`;

export const AbsoluteCenterBottom = styled.div`
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
`;

export const AbsoluteCenterLeft = styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
`;

export const AbsoluteBottomRight = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
`;

export const AbsoluteTopRight = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

export const AbsoluteLogo = styled.div`
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%,-50%);
`;

export const RelativeContainer = styled.div`
    position: relative;
`;

// ----------------------------------------FOOTER----------------------------------------//

export const FooterContainer = styled.div`
    background-color: #000000;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 2;
    padding-top: ${(props) => props.theme.space[2]};
    padding-bottom: ${(props) => props.theme.space[2]};
    padding-left: ${(props) => props.theme.space[6]};
    padding-right: ${(props) => props.theme.space[6]};

    @media only screen and (max-width: 450px) {
        padding-top: ${(props) => props.theme.space[2]};
        padding-bottom: ${(props) => props.theme.space[2]};
        padding-left: ${(props) => props.theme.space[4]};
        padding-right: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const ExternalLink = styled.a`
    text-decoration: none;
    color: ${(props) => props.theme.colors.text.white};
    font-size: 10px;
    &:hover {
        color: #EBFF00;
    }
`;

// ----------------------------------------MAGNIFY----------------------------------------//

export const MagnifyIcon = styled.div`
    &:hover {
        transform: scale(1.08);
        transition: all 0.3s ease;
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.5s ease;
    }
`;

// ----------------------------------------INPUT----------------------------------------//

export const FormLabel = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    letter-spacing: 0.02em;
`;

export const FormSubLabel = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey60};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    letter-spacing: 0.02em;
    font-style: italic;
`;

export const FormInput = styled.input`
    border: 1px solid #AFAFAF;
    width: 100%;
    min-height: 40px;
    padding: 4px 8px;
    border-radius: 2px;

`;

export const FormSelect = styled.select`
    border: 1px solid #AFAFAF;
    width: 100%;
    min-height: 40px;
    padding: 4px 8px;
    border-radius: 2px;
`;

export const FormSelectOption = styled.option`
    
`;

export const FormTextArea = styled.textarea`
    border: 1px solid #AFAFAF;
    width: 100%;
    padding: 4px 8px;
    border-radius: 2px;
    resize: none;
    vertical-align: top;
    line-height: 24px;
`;

export const FormThumbnailImg = styled.img`
    width: 400px;
     @media only screen and (max-width: 650px) {
        width: 100%;
    }
`;

export const FormThumbnailImg23 = styled.img`
    width: 200px;
    height: 300px;
`;

export const FormThumbnailImg169 = styled.img`
    width: 480px;
    height: 270px;
     @media only screen and (max-width: 650px) {
        width: 100%;
    }
`;

export const FormThumbnailImg54 = styled.img`
    width: 400px;
    height: 320px;
     @media only screen and (max-width: 650px) {
        width: 100%;
    }
`;

export const FormThumbnailImg34 = styled.img`
    width: 300px;
    height: 400px;
     @media only screen and (max-width: 650px) {
        width: 100%;
    }
`;

// ----------------------------------------General----------------------------------------//

export const ImpNoteWrapper = styled.div`
    
`;

export const ImpNoteContainer = styled.div`
    padding-top: ${(props) => props.theme.space[4]};
    padding-bottom: ${(props) => props.theme.space[4]};
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[20]};
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[32]};
        padding-right: ${(props) => props.theme.space[32]};
    }
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[12]};
        padding-right: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[6]};
        padding-right: ${(props) => props.theme.space[6]};
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 450px) {
        padding-top: ${(props) => props.theme.space[2]};
        padding-bottom: ${(props) => props.theme.space[2]};
        padding-left: ${(props) => props.theme.space[4]};
        padding-right: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 370px) {
        padding-top: ${(props) => props.theme.space[1]};
        padding-bottom: ${(props) => props.theme.space[1]};
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const ImpNoteHeading = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    letter-spacing: 0.04em;
    font-weight: 300;
`;

export const ImpNoteText = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    letter-spacing: 0.02em;
    line-height: 24px; 
    text-align: justify;
`;

// ----------------------------------------General----------------------------------------//

export const AddNewBtncontainer = styled.a`
    border: 4px dashed #4BD59B;
    border-radius: 8px;
    width: 195px;
    height: 130px;
    text-decoration: none;
    position: relative;
    cursor: pointer;

    &:hover {
        transform: scale(1.03);
        transition: all 0.5s ease;
        background-color: #D7FFEE;
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.5s ease;
    }
`;

export const AddNewBtncontainerDisabled = styled.a`
    border: 4px dashed #C9C9C9;
    border-radius: 8px;
    width: 195px;
    height: 130px;
    text-decoration: none;
    position: relative;
    background-color: #F5F5F9;
`;

export const PurposeBtnContainer = styled.a`
    border-radius: 8px;
    width: 195px;
    height: 130px;
    text-decoration: none;
    position: relative;
    cursor: pointer;

    &:hover {
        transform: scale(1.03);
        transition: all 0.5s ease;
        background-color: #FFFFFF;
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.5s ease;
    }
`;

export const ActionBtnContainer = styled.a`
    border-radius: 8px;
    width: 195px;
    height: 130px;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0px 1px 4px 0px rgba(170,170,170,0.75);
-webkit-box-shadow: 0px 1px 4px 0px rgba(170,170,170,0.75);
-moz-box-shadow: 0px 1px 4px 0px rgba(170,170,170,0.75);

    &:hover {
        transform: scale(1.03);
        transition: all 0.5s ease;
        box-shadow: 0px 3px 17px 2px rgba(175,175,175,0.75);
-webkit-box-shadow: 0px 3px 17px 2px rgba(175,175,175,0.75);
-moz-box-shadow: 0px 3px 17px 2px rgba(175,175,175,0.75);
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.5s ease;
    }
`;


