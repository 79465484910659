import React, { useContext, useEffect, useState } from 'react'
import { Container, FlexBox, FlexBoxSA, Sandwich, Squeezer } from '../../../global/style/global.styles'
import { AddNewBtn } from '../../../global/components/addNewBtn'
import { Outlet } from 'react-router-dom'
import { SpacerLeftRightLarge, SpacerTopBottomLarge, SpacerTopBottomXL, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { Button, Grid, LinearProgress } from '@mui/material'
import { ApiContext } from '../../../context/api/api.provider'
import { BlogInfoTile } from '../components/blogInfoTile'
import { PurposeBtn } from '../../../global/components/purposeBtn'
import shrugEg from '../../../assets/images/shrugImg.png'
import { ImgHolder } from '../../../global/components/imgHolder'

export const Blog = (props) => {

    const { blogs, blogsCollectionRef, blogDrafts, loadingBlogs } = useContext(ApiContext);

    // const [analectsData, setAnalectsData] = useState([])
    // const [filterId, setFilterId] = useState("all")

    // useEffect(() => {
    //     if (filterId === "all") {
    //         setAnalectsData(blogs?.sort(function (a, b) { return new Date(b.date) - new Date(a.date) }))
    //     } else {
    //         setAnalectsData(blogs?.sort(function (a, b) { return new Date(b.date) - new Date(a.date) })
    //             .filter((x) => x.topic.toLowerCase() === filterId))
    //     }
    // }, [])

    return (
        <>
            <Squeezer>
                <Button
                    variant='outlined'
                    size='small'
                    sx={{ textTransform: "none" }}
                    color='error'
                    onClick={() => props.navigate(-1)}>
                    Go back
                </Button>
                <SpacerTopBottomXXXL />
            </Squeezer>
            {
                props.location.pathname === "/cms/blog"
                    ?
                    <Squeezer>
                        <SpacerTopBottomXL>
                            <FlexBox>
                                <AddNewBtn
                                    title="Add New"
                                    link="/cms/blog/add-new"
                                />
                                <SpacerLeftRightLarge />
                                <PurposeBtn
                                    color="#E5B300"
                                    title="Drafts"
                                    num={blogDrafts.length}
                                    link="/cms/blog/drafts"
                                />
                            </FlexBox>
                        </SpacerTopBottomXL>
                    </Squeezer>
                    :
                    <Outlet />
            }
            {
                blogs.length > 0
                    ?
                    <>
                        {
                            props.location.pathname === "/cms/blog"
                            &&
                            blogs.filter((x) => x.status === "active").length > 0
                            &&
                            <Squeezer>
                                <SpacerTopBottomXXXL />
                                <h3>
                                    Active Blogs
                                </h3>
                                <SpacerTopBottomXL />
                                {
                                    loadingBlogs
                                        ?
                                        <LinearProgress
                                            color='success'
                                        />
                                        :
                                        <Grid container spacing={4}>
                                            {
                                                blogs?.filter((x) => x.status === "active").map((item, index) => (
                                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} key={index}>
                                                        <BlogInfoTile
                                                            width={props.width}
                                                            location={props.location}
                                                            navigate={props.navigate}
                                                            data={item}
                                                            blogsCollectionRef={blogsCollectionRef}
                                                            refresh={props.refresh}
                                                            setRefresh={props.setRefresh}
                                                        />
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                }
                            </Squeezer>
                        }
                        {
                            props.location.pathname === "/cms/blog"
                            &&
                            blogs.filter((x) => x.status === "inactive").length > 0
                            &&
                            <Squeezer>
                                <SpacerTopBottomXXXL />
                                <h3>
                                    Inactive Blogs
                                </h3>
                                <SpacerTopBottomXL />
                                {
                                    loadingBlogs
                                        ?
                                        <LinearProgress
                                            color='inherit'
                                        />
                                        :
                                        <Grid container spacing={4}>
                                            {
                                                blogs?.filter((x) => x.status === "inactive").map((item, index) => (
                                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} key={index}>
                                                        <BlogInfoTile
                                                            width={props.width}
                                                            location={props.location}
                                                            navigate={props.navigate}
                                                            data={item}
                                                            blogsCollectionRef={blogsCollectionRef}
                                                            refresh={props.refresh}
                                                            setRefresh={props.setRefresh}
                                                        />
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                }
                            </Squeezer>
                        }
                    </>
                    :
                    !loadingBlogs
                    &&
                    <FlexBoxSA>
                        <Sandwich>
                            <ImgHolder
                                img={shrugEg}
                                width={props.width > 850 ? 600 : "100%"}
                            />
                        </Sandwich>
                    </FlexBoxSA>
            }
        </>
    )
}
