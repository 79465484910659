import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomXL } from '../../../design/spacer/spacer'
import LOGOWHITE from '../../../assets/icons/logoWhite.png'
import LOGOBLACK from '../../../assets/icons/logoBlack.png'
import { HamburgerContainer, MenuContainer, NavLogo, NavLogoHolder } from './navbar.styles';
import { Container, FlexBoxSB, Sandwich } from '../../style/global.styles';
import { IconHolder } from '../iconHolder';
import dashboardIcon from '../../../assets/icons/dashboard.png'

export default function SideBar(props) {

    const navigate = useNavigate();

    const [state, setState] = useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <MenuContainer>
                    <Link to="/cms"
                        style={{
                            textDecoration: 'none'
                        }}>
                        <div>CMS</div>
                    </Link>
                    <Sandwich />
                    <Link to="/crm"
                        style={{
                            textDecoration: 'none'
                        }}>
                        <div>CRM</div>
                    </Link>
                </MenuContainer>
            </List>
        </Box>
    );

    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment>
                        <FlexBoxSB>
                            <a href='/dashboard' style={{ textDecoration: "none" }}>
                                <IconHolder
                                    icon={dashboardIcon}
                                    width={40}
                                    height={40}
                                />
                            </a>
                            <HamburgerContainer onClick={toggleDrawer(anchor, true)}>
                                <MenuRoundedIcon
                                    size="large"
                                    sx={{ color: 'white' }}
                                />
                            </HamburgerContainer>
                        </FlexBoxSB>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}