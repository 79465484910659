import React from 'react'
import styled from 'styled-components'

const PageStart = (props) => {
    return (
        <PageStartContainer />
    )
}

const PageStartContainer = styled.div`
    padding-top: 100px;

    @media only screen and (max-width: 850px) {
        padding-top: 0;
    }
`;

export default PageStart