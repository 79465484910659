// Defaults
import React, { useEffect, useState } from 'react';

// Ext-libs


// Styles
import './navbar.css'
import { NavLogo, NavLogoHolder, NavbarContainer } from './navbar.styles';

// Int-libs
import LOGOWHITE from '../../../assets/icons/logoWhite.png'
import LOGOBLACK from '../../../assets/icons/logoBlack.png'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { SpacerLeftRightLarge, SpacerLeftRightMedium, SpacerLeftRightXL, SpacerLeftRightXXL, SpacerLeftRightXXXL } from '../../../design/spacer/spacer';
import useWindowDimensions from '../screenSize';
import { FlexBox, FlexBoxSB } from '../../style/global.styles';
import SideBar from './sidebar';
import { IconHolder } from '../iconHolder';
import dashboardIcon from '../../../assets/icons/dashboard.png'
import { NavProfile } from './navProfile';

// Main
export const Navbar = (props) => {

    const navigate = useNavigate();

    const location = useLocation();

    const { height, width } = useWindowDimensions();

    const [scroll, setScroll] = useState(false)

    const handleScroll = () => {

        if (window.scrollY > 80) {
            setScroll(true)
        } else {
            setScroll(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    }, []);

    return (
        <NavbarContainer style={
            scroll
                ?
                {
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    padding: "16px 40px",
                    transition: "all ease-in 0.2s"
                }
                :
                { transition: "all ease-in 0.4s" }
        }>
            {
                width > 850
                    ?
                    <FlexBoxSB>
                        <a href='/dashboard' style={{ textDecoration: "none" }}>
                            <IconHolder
                                icon={dashboardIcon}
                                width={40}
                                height={40}
                            />
                        </a>
                        <FlexBox>
                            <a href="/cms" style={{ textDecoration: 'none' }}>
                                <div style={{ color: "black", fontWeight: 600 }}>
                                    Content
                                </div>
                            </a>
                            {
                                width > 1100
                                    ?
                                    <SpacerLeftRightXXXL />
                                    :
                                    <SpacerLeftRightXXL />
                            }
                            <a href='/crm' style={{ textDecoration: 'none' }}>
                                <div style={{ color: "black", fontWeight: 600 }}>
                                    Leads
                                </div>
                            </a>
                            {
                                width > 1100
                                    ?
                                    <SpacerLeftRightXXL />
                                    :
                                    <SpacerLeftRightXL />
                            }
                            <NavProfile
                                width={width}
                                navigate={navigate}
                            />
                        </FlexBox>
                    </FlexBoxSB>
                    :
                    <SideBar
                        color={props.color}
                    />
            }
        </NavbarContainer>
    );
};