import { Backdrop, Box, Button, CircularProgress, Fade, Grid, Modal, Tooltip } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { AbsoluteBottomRight, AbsoluteCenter, FlexBox, FlexBoxSB, FormInput, FormLabel, FormSelect, FormSelectOption, FormSubLabel, FormTextArea, FormThumbnailImg, FormThumbnailImg169, FormThumbnailImg23, FormThumbnailImg34, FormThumbnailImg54, MagnifyIcon, Sandwich, SandwichHalf, Squeezer } from '../../../global/style/global.styles'
import { SpacerBottomSmall, SpacerLeftRightMedium, SpacerRightMedium, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomXL, SpacerTopBottomXXL, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { ApiContext } from '../../../context/api/api.provider'
import { ThumbnailImg } from '../../profile/style/profile.styles'
import { ContentBlock } from '../components/contentBlock'
import axios from 'axios'
import { addDoc } from 'firebase/firestore'
import { LoadingButton } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { AddNewBlogWrapper, PreviewContainer, PreviewPosition } from '../style/cms.styles'
import { BlogPage } from '../screens/blogPage'
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import VerticalAlignTopRoundedIcon from '@mui/icons-material/VerticalAlignTopRounded';
import VerticalAlignBottomRoundedIcon from '@mui/icons-material/VerticalAlignBottomRounded';

export const AddNewBlog = (props) => {

    const [scroll, setScroll] = useState(false)
    const bottomRef = useRef(null);

    const handleScroll = () => {

        if (window.scrollY > 80) {
            setScroll(true)
        } else {
            setScroll(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    }, []);

    const scrollToTop = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }
    const scrollToBottom = () => {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    const previewModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        outline: 'none',
        backgroundColor: 'white',
        overflowY: 'auto',
        width: "90%",
        height: "90%"
    };

    const { blogsCollectionRef, blogDraftsCollectionRef } = useContext(ApiContext);
    const { profile } = useContext(ApiContext);

    const [previewModal, setPreviewModal] = useState(false);

    const [loadingUpload, setLoadingUpload] = useState("");
    const [loadingDraft, setLoadingDraft] = useState("");

    const [heading, setHeading] = useState("");
    const [subHeading, setSubHeading] = useState("");
    const [author, setAuthor] = useState("");
    const [numOfWords, setNumOfWords] = useState("");
    const [duration, setDuration] = useState("");
    const [numOfContents, setNumOfContents] = useState("");
    const [mastery, setMastery] = useState("");
    const [topic, setTopic] = useState("");
    const [description, setDescription] = useState("");
    const [tags, setTags] = useState("");
    const [format, setFormat] = useState("A1");
    const [dpFile, setDpFile] = useState("");
    const [bannerFile169, setBannerFile169] = useState("");
    const [bannerFile54, setBannerFile54] = useState("");
    const [bannerFile34, setBannerFile34] = useState("");
    const [contentBlockStatus, setContentBlockStatus] = useState("New");
    const [heroTextcolor, setHeroTextColor] = useState("");
    const [contentBlock, setContentBlock] = useState([
        {
            block: 1,
            spacing: "",
            title: "",
            subTitle: "",
            para: "",
            ol: "",
            ul: "",
            img: "",
            vid: "",
            quote: "",
            titleI: "",
            titleB: "",
            titleU: "",
            titleBI: "",
            titleBU: "",
            titleIU: "",
            titleBIU: "",
            subTitleI: "",
            subTitleB: "",
            subTitleU: "",
            subTitleBI: "",
            subTitleBU: "",
            subTitleIU: "",
            subTitleBIU: "",
            paraI: "",
            paraB: "",
            paraU: "",
            paraBI: "",
            paraBU: "",
            paraIU: "",
            paraBIU: "",
            quoteI: "",
            quoteB: "",
            quoteU: "",
            quoteBI: "",
            quoteBU: "",
            quoteIU: "",
            quoteBIU: "",
        }
    ])

    const handleStringChangeContentBlock = (index, event) => {
        const values = [...contentBlock];
        values[index][event.target.name] = event.target.value;
        setContentBlock(values);
    }

    const handleMediaChangeContentBlock = (index, event) => {
        const values = [...contentBlock];
        values[index][event.target.name] = event.target.files[0];
        setContentBlock(values);
    }

    const handleOlChangeContentBlock = (index, event, ol) => {
        const values = [...contentBlock];
        values[index][event.target.name] = ol;
        setContentBlock(values);
    }

    const handleUlChangeContentBlock = (index, event, ul) => {
        const values = [...contentBlock];
        values[index][event.target.name] = ul;
        setContentBlock(values);
    }

    const contentBlockAddRow = (index) => {
        setContentBlock([...contentBlock, {
            block: index + 2,
            spacing: "",
            title: "",
            subTitle: "",
            para: "",
            ol: "",
            ul: "",
            img: "",
            vid: "",
            quote: "",
            titleI: "",
            titleB: "",
            titleU: "",
            titleBI: "",
            titleBU: "",
            titleIU: "",
            titleBIU: "",
            subTitleI: "",
            subTitleB: "",
            subTitleU: "",
            subTitleBI: "",
            subTitleBU: "",
            subTitleIU: "",
            subTitleBIU: "",
            paraI: "",
            paraB: "",
            paraU: "",
            paraBI: "",
            paraBU: "",
            paraIU: "",
            paraBIU: "",
            quoteI: "",
            quoteB: "",
            quoteU: "",
            quoteBI: "",
            quoteBU: "",
            quoteIU: "",
            quoteBIU: "",
        }])
    }

    const contentBlockSubtractRow = (index) => {
        const values = [...contentBlock];
        values.splice(index, 1);
        setContentBlock(values);
    }

    const addImmBlock = (index) => {
        const values = [...contentBlock];
        values.splice(index, 0, {
            block: index + 1,
            spacing: "",
            title: "",
            subTitle: "",
            para: "",
            ol: "",
            ul: "",
            img: "",
            vid: "",
            quote: "",
            titleI: "",
            titleB: "",
            titleU: "",
            titleBI: "",
            titleBU: "",
            titleIU: "",
            titleBIU: "",
            subTitleI: "",
            subTitleB: "",
            subTitleU: "",
            subTitleBI: "",
            subTitleBU: "",
            subTitleIU: "",
            subTitleBIU: "",
            paraI: "",
            paraB: "",
            paraU: "",
            paraBI: "",
            paraBU: "",
            paraIU: "",
            paraBIU: "",
            quoteI: "",
            quoteB: "",
            quoteU: "",
            quoteBI: "",
            quoteBU: "",
            quoteIU: "",
            quoteBIU: "",
        })
        setContentBlock(values);
    }

    const handleClearContentBlock = () => {
        setContentBlock([{
            block: 1,
            spacing: "",
            title: "",
            subTitle: "",
            para: "",
            ol: "",
            ul: "",
            img: "",
            vid: "",
            quote: "",
            titleI: "",
            titleB: "",
            titleU: "",
            titleBI: "",
            titleBU: "",
            titleIU: "",
            titleBIU: "",
            subTitleI: "",
            subTitleB: "",
            subTitleU: "",
            subTitleBI: "",
            subTitleBU: "",
            subTitleIU: "",
            subTitleBIU: "",
            paraI: "",
            paraB: "",
            paraU: "",
            paraBI: "",
            paraBU: "",
            paraIU: "",
            paraBIU: "",
            quoteI: "",
            quoteB: "",
            quoteU: "",
            quoteBI: "",
            quoteBU: "",
            quoteIU: "",
            quoteBIU: "",
        }])
        setContentBlockStatus("Fresh")
    }

    const handleCleanSlate = (index) => {
        const values = [...contentBlock];
        values[index] = {
            block: index + 1,
            spacing: "",
            title: "",
            subTitle: "",
            para: "",
            ol: "",
            ul: "",
            img: "",
            vid: "",
            quote: "",
            titleI: "",
            titleB: "",
            titleU: "",
            titleBI: "",
            titleBU: "",
            titleIU: "",
            titleBIU: "",
            subTitleI: "",
            subTitleB: "",
            subTitleU: "",
            subTitleBI: "",
            subTitleBU: "",
            subTitleIU: "",
            subTitleBIU: "",
            paraI: "",
            paraB: "",
            paraU: "",
            paraBI: "",
            paraBU: "",
            paraIU: "",
            paraBIU: "",
            quoteI: "",
            quoteB: "",
            quoteU: "",
            quoteBI: "",
            quoteBU: "",
            quoteIU: "",
            quoteBIU: "",
        };
        setContentBlock(values);
    }

    const countWords = (str) => {
        return str.trim().split(/\s+/).length;
    }

    // const [contentBlockRowEdit, setContentBlockRowEdit] = useState()

    // const handleDeleteRowContentBlock = async (index, count) => {

    //     const ogArr = productData[0]?.contentBlock;
    //     const spArr = ogArr.splice(index, count)

    //     const rowDeleteData = {
    //         contentBlock: ogArr
    //     }

    //     const docRef = doc(db, "products", productId);
    //     await setDoc(docRef, rowDeleteData, {
    //         merge: true
    //     }).then(() => console.log("Row deleted"));

    //     props.setRefresh(!props.refresh)
    // }

    const handleUpload = () => {

        // const refinedContentBlock = contentBlock.filter((x, index) => index + 1 !== contentBlock.length)
        const files = [
            ...contentBlock,
            {
                type: "dp23",
                img: dpFile
            },
            {
                type: "banner169",
                img: bannerFile169
            },
            {
                type: "banner54",
                img: bannerFile54
            },
            {
                type: "banner34",
                img: bannerFile34
            }
        ]

        const arr = []

        const uploaders = files.map((item, index) => {
            const data = new FormData();
            data.append("file", item.img)
            data.append("upload_preset", process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET)
            data.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME)

            setLoadingUpload(true)

            if (item.img) {
                return axios.post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`, data)
                    .then((response) => {
                        const data = response.data
                        // console.log(data)
                        const imageUrl = data.url;
                        // console.log(imageUrl)
                        arr.push({
                            block: item.block,
                            type: item.type ? item.type : "content",
                            img: imageUrl,
                            spacing: item.spacing,
                            title: item.title,
                            subTitle: item.subTitle,
                            para: item.para,
                            ol: item.ol,
                            ul: item.ul,
                            vid: item.vid,
                            quote: item.quote,
                            titleI: item.titleI,
                            titleB: item.titleB,
                            titleU: item.titleU,
                            titleBI: item.titleBI,
                            titleBU: item.titleBU,
                            titleIU: item.titleIU,
                            titleBIU: item.titleBIU,
                            subTitleI: item.subTitleI,
                            subTitleB: item.subTitleB,
                            subTitleU: item.subTitleU,
                            subTitleBI: item.subTitleBI,
                            subTitleBU: item.subTitleBI,
                            subTitleIU: item.subTitleBI,
                            subTitleBIU: item.subTitleBIU,
                            paraI: item.paraI,
                            paraB: item.paraB,
                            paraU: item.paraU,
                            paraBI: item.paraBI,
                            paraBU: item.paraBU,
                            paraIU: item.paraIU,
                            paraBIU: item.paraBIU,
                            quoteI: item.quoteI,
                            quoteB: item.quoteB,
                            quoteU: item.quoteU,
                            quoteBI: item.quoteBI,
                            quoteBU: item.quoteBU,
                            quoteIU: item.quoteIU,
                            quoteBIU: item.quoteBIU,
                        })
                    }).catch((err) => {
                        console.log(err)
                    })
            } else {
                arr.push({
                    block: item.block,
                    type: item.type ? item.type : "content",
                    img: item.img,
                    spacing: item.spacing,
                    title: item.title,
                    subTitle: item.subTitle,
                    para: item.para,
                    ol: item.ol,
                    ul: item.ul,
                    vid: item.vid,
                    quote: item.quote,
                    titleI: item.titleI,
                    titleB: item.titleB,
                    titleU: item.titleU,
                    titleBI: item.titleBI,
                    titleBU: item.titleBU,
                    titleIU: item.titleIU,
                    titleBIU: item.titleBIU,
                    subTitleI: item.subTitleI,
                    subTitleB: item.subTitleB,
                    subTitleU: item.subTitleU,
                    subTitleBI: item.subTitleBI,
                    subTitleBU: item.subTitleBI,
                    subTitleIU: item.subTitleBI,
                    subTitleBIU: item.subTitleBIU,
                    paraI: item.paraI,
                    paraB: item.paraB,
                    paraU: item.paraU,
                    paraBI: item.paraBI,
                    paraBU: item.paraBU,
                    paraIU: item.paraIU,
                    paraBIU: item.paraBIU,
                    quoteI: item.quoteI,
                    quoteB: item.quoteB,
                    quoteU: item.quoteU,
                    quoteBI: item.quoteBI,
                    quoteBU: item.quoteBU,
                    quoteIU: item.quoteIU,
                    quoteBIU: item.quoteBIU,
                })
            }
        })
        axios.all(uploaders).then(async () => {

            const dpArr = arr.filter((x) => x.type !== "content").map((item) => (
                {
                    type: item.type,
                    url: item.img
                }
            ))

            const newBlog = {
                heading: heading,
                subHeading: subHeading,
                author: author,
                numOfWords: numOfWords,
                duration: duration,
                numOfContents: numOfContents,
                mastery: mastery,
                topic: topic,
                description: description,
                tags: tags,
                format: format,
                dp: dpArr,
                heroTextcolor: heroTextcolor ? heroTextcolor : "black",
                content: arr.filter((x) => x.type === "content"),
                keywords: `${heading},${subHeading},${author},${mastery},${topic},${description},${tags}`,
                createdAt: new Date(),
                status: "active",
                feature: 0
            }

            addDoc(blogsCollectionRef, newBlog);

            props.setRefresh(!props.refresh)
            setHeading("")
            setSubHeading("")
            setAuthor("")
            setNumOfWords("")
            setNumOfContents("")
            setMastery("")
            setTopic("")
            setDescription("")
            setTags("")
            setFormat("")
            setDpFile("")
            setBannerFile169("")
            setBannerFile54("")
            setBannerFile34("")
            setHeroTextColor("black")
            handleClearContentBlock()
            setLoadingUpload(false)
            props.navigate('/cms/blog')

        })
    }

    const handleDraft = () => {

        // const refinedContentBlock = contentBlock.filter((x, index) => index + 1 !== contentBlock.length)
        const files = [
            ...contentBlock,
            {
                type: "dp23",
                img: dpFile
            },
            {
                type: "banner169",
                img: bannerFile169
            },
            {
                type: "banner54",
                img: bannerFile54
            },
            {
                type: "banner34",
                img: bannerFile34
            }
        ]

        const arr = []

        const uploaders = files.map((item, index) => {
            const data = new FormData();
            data.append("file", item.img)
            data.append("upload_preset", process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET)
            data.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME)

            setLoadingDraft(true)

            if (item.img) {
                return axios.post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`, data)
                    .then((response) => {
                        const data = response.data
                        // console.log(data)
                        const imageUrl = data.url;
                        // console.log(imageUrl)
                        arr.push({
                            block: item.block,
                            type: item.type ? item.type : "content",
                            img: imageUrl,
                            spacing: item.spacing,
                            title: item.title,
                            subTitle: item.subTitle,
                            para: item.para,
                            ol: item.ol,
                            ul: item.ul,
                            vid: item.vid,
                            quote: item.quote,
                            titleI: item.titleI,
                            titleB: item.titleB,
                            titleU: item.titleU,
                            titleBI: item.titleBI,
                            titleBU: item.titleBU,
                            titleIU: item.titleIU,
                            titleBIU: item.titleBIU,
                            subTitleI: item.subTitleI,
                            subTitleB: item.subTitleB,
                            subTitleU: item.subTitleU,
                            subTitleBI: item.subTitleBI,
                            subTitleBU: item.subTitleBI,
                            subTitleIU: item.subTitleBI,
                            subTitleBIU: item.subTitleBIU,
                            paraI: item.paraI,
                            paraB: item.paraB,
                            paraU: item.paraU,
                            paraBI: item.paraBI,
                            paraBU: item.paraBU,
                            paraIU: item.paraIU,
                            paraBIU: item.paraBIU,
                            quoteI: item.quoteI,
                            quoteB: item.quoteB,
                            quoteU: item.quoteU,
                            quoteBI: item.quoteBI,
                            quoteBU: item.quoteBU,
                            quoteIU: item.quoteIU,
                            quoteBIU: item.quoteBIU,
                        })
                    }).catch((err) => {
                        console.log(err)
                    })
            } else {
                arr.push({
                    block: item.block,
                    type: item.type ? item.type : "content",
                    img: item.img,
                    spacing: item.spacing,
                    title: item.title,
                    subTitle: item.subTitle,
                    para: item.para,
                    ol: item.ol,
                    ul: item.ul,
                    vid: item.vid,
                    quote: item.quote,
                    titleI: item.titleI,
                    titleB: item.titleB,
                    titleU: item.titleU,
                    titleBI: item.titleBI,
                    titleBU: item.titleBU,
                    titleIU: item.titleIU,
                    titleBIU: item.titleBIU,
                    subTitleI: item.subTitleI,
                    subTitleB: item.subTitleB,
                    subTitleU: item.subTitleU,
                    subTitleBI: item.subTitleBI,
                    subTitleBU: item.subTitleBI,
                    subTitleIU: item.subTitleBI,
                    subTitleBIU: item.subTitleBIU,
                    paraI: item.paraI,
                    paraB: item.paraB,
                    paraU: item.paraU,
                    paraBI: item.paraBI,
                    paraBU: item.paraBU,
                    paraIU: item.paraIU,
                    paraBIU: item.paraBIU,
                    quoteI: item.quoteI,
                    quoteB: item.quoteB,
                    quoteU: item.quoteU,
                    quoteBI: item.quoteBI,
                    quoteBU: item.quoteBU,
                    quoteIU: item.quoteIU,
                    quoteBIU: item.quoteBIU,
                })
            }
        })
        axios.all(uploaders).then(async () => {

            const dpArr = arr.filter((x) => x.type !== "content").map((item) => (
                {
                    type: item.type,
                    url: item.img
                }
            ))

            const newBlog = {
                heading: heading,
                subHeading: subHeading,
                author: author,
                numOfWords: numOfWords,
                duration: duration,
                numOfContents: numOfContents,
                mastery: mastery,
                topic: topic,
                description: description,
                tags: tags,
                format: format,
                dp: dpArr,
                heroTextcolor: heroTextcolor ? heroTextcolor : "black",
                content: arr.filter((x) => x.type === "content"),
                keywords: `${heading},${subHeading},${author},${mastery},${topic},${description},${tags}`,
                createdAt: new Date(),
                status: "active",
                feature: 0
            }

            addDoc(blogDraftsCollectionRef, newBlog);

            setHeading("")
            setSubHeading("")
            setAuthor("")
            setNumOfWords("")
            setNumOfContents("")
            setMastery("")
            setTopic("")
            setDescription("")
            setTags("")
            setFormat("")
            setDpFile("")
            setBannerFile169("")
            setBannerFile54("")
            setBannerFile34("")
            setHeroTextColor("black")
            handleClearContentBlock()
            setLoadingDraft(false)
            props.setRefresh(!props.refresh)
            props.navigate('/cms/blog')

        })
    }

    const handleClearAll = () => {
        setHeading("")
        setSubHeading("")
        setAuthor("")
        setNumOfWords("")
        setNumOfContents("")
        setMastery("")
        setTopic("")
        setDescription("")
        setTags("")
        setFormat("")
        setDpFile("")
        setBannerFile169("")
        setBannerFile54("")
        setBannerFile34("")
        setHeroTextColor("black")
        handleClearContentBlock()
    }

    const handlePreviewModalClose = () => {
        setPreviewModal(false);
    }

    return (
        <AddNewBlogWrapper>
            <PreviewPosition>
                {
                    scroll &&
                    <>
                        <Tooltip title='Scroll Top' placement='top' onClick={scrollToTop}>
                            <MagnifyIcon>
                                <PreviewContainer style={{ backgroundColor: "#812FE9" }}>
                                    <AbsoluteCenter>
                                        <VerticalAlignTopRoundedIcon
                                            color='warning'
                                            sx={{
                                                cursor: "pointer",
                                                color: "white",
                                                paddingTop: "4px"
                                            }}
                                        />
                                    </AbsoluteCenter>
                                </PreviewContainer>
                            </MagnifyIcon>
                        </Tooltip>
                        <SpacerTopBottomLarge />
                        <Tooltip title='Scroll Bottom' placement='top' onClick={scrollToBottom}>
                            <MagnifyIcon>
                                <PreviewContainer style={{ backgroundColor: "#812FE9" }}>
                                    <AbsoluteCenter>
                                        <VerticalAlignBottomRoundedIcon
                                            color='warning'
                                            sx={{
                                                cursor: "pointer",
                                                color: "white",
                                                paddingTop: "4px"
                                            }}
                                        />
                                    </AbsoluteCenter>
                                </PreviewContainer>
                            </MagnifyIcon>
                        </Tooltip>
                        <SpacerTopBottomLarge />
                        <Tooltip title='Save Draft' placement='top' onClick={heading ? handleDraft : () => { }}>
                            <MagnifyIcon>
                                <PreviewContainer style={
                                    loadingDraft
                                        ?
                                        {}
                                        :
                                        { backgroundColor: !heading ? "#C9C9C9" : "#ED6C02" }}>
                                    <AbsoluteCenter>
                                        {
                                            loadingDraft
                                                ?
                                                <CircularProgress
                                                    color='inherit'
                                                />
                                                :
                                                <EditNoteRoundedIcon
                                                    color='warning'
                                                    sx={{
                                                        cursor: "pointer",
                                                        color: "white",
                                                        paddingTop: "4px"
                                                    }}
                                                />
                                        }
                                    </AbsoluteCenter>
                                </PreviewContainer>
                            </MagnifyIcon>
                        </Tooltip>
                        <SpacerTopBottomLarge />
                    </>
                }
                <Tooltip title='Preview' placement='top' onClick={() => setPreviewModal(true)}>
                    <MagnifyIcon>
                        <PreviewContainer style={{ backgroundColor: "black" }}>
                            <AbsoluteCenter>
                                <VisibilityRoundedIcon
                                    sx={{
                                        cursor: "pointer",
                                        color: "white",
                                        paddingTop: "4px"
                                    }}
                                />
                            </AbsoluteCenter>
                        </PreviewContainer>
                    </MagnifyIcon>
                </Tooltip>
            </PreviewPosition>
            <Squeezer>
                <FlexBoxSB style={{ borderBottom: "1px solid gray", paddingBottom: 4, display: props.width > 450 ? "flex" : "block" }}>
                    <h1>Add new blog</h1>
                    {
                        props.width < 450
                        &&
                        <SpacerTopBottomLarge />
                    }
                    <FlexBox>
                        <Button
                            variant='contained'
                            color='error'
                            sx={{ textTransform: "none" }}
                            onClick={handleClearAll}>
                            Clear All
                        </Button>
                        <SpacerLeftRightMedium />
                        {
                            loadingDraft
                                ?
                                <LoadingButton
                                    loading
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    sx={{ textTransform: "none" }}
                                    variant="outlined"
                                >
                                    Saving
                                </LoadingButton>
                                :
                                <Button
                                    variant='contained'
                                    color='warning'
                                    sx={{ textTransform: "none" }}
                                    onClick={handleDraft}
                                    disabled={
                                        !heading
                                    }>
                                    Save Draft
                                </Button>
                        }
                        <SpacerLeftRightMedium />
                        {
                            loadingUpload
                                ?
                                <LoadingButton
                                    loading
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    sx={{ textTransform: "none" }}
                                    variant="outlined"
                                >
                                    Uploading
                                </LoadingButton>
                                :
                                <Button
                                    variant='contained'
                                    color='success'
                                    sx={{ textTransform: "none" }}
                                    onClick={handleUpload}
                                    disabled={
                                        !heading || !author || !numOfWords || !duration || !numOfContents || !mastery || !topic || !description || !format || !dpFile || !bannerFile169 || !bannerFile34 || !bannerFile54 || contentBlock.length === 1
                                    }>
                                    Upload
                                </Button>
                        }
                    </FlexBox>
                </FlexBoxSB>
                <SpacerTopBottomXXL />
                <form>
                    <h3 style={{ textDecoration: 'underline', textUnderlineOffset: 4, color: "gray" }}>
                        Basic Information
                    </h3>
                    <SpacerTopBottomXXL />
                    <Grid container spacing={5}>
                        <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                            <FormLabel>
                                Heading
                            </FormLabel>
                            <SpacerTopBottomMedium />
                            <FormInput
                                name="heading"
                                type='text'
                                value={heading}
                                onChange={(e) => setHeading(e.target.value)}
                            />
                        </Grid>
                        <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                            <FormLabel>
                                Sub-heading
                            </FormLabel>
                            <SpacerTopBottomMedium />
                            <FormInput
                                name="subHeading"
                                type='text'
                                value={subHeading}
                                onChange={(e) => setSubHeading(e.target.value)}
                            />
                        </Grid>
                        <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                            <FormLabel>
                                Author
                            </FormLabel>
                            <SpacerTopBottomMedium />
                            <FormSelect
                                name="author"
                                value={author}
                                onChange={(e) => setAuthor(e.target.value)}>
                                <FormSelectOption value="">
                                    -- choose --
                                </FormSelectOption>
                                <FormSelectOption value="marketingDept">
                                    Marketing Dept
                                </FormSelectOption>
                                <FormSelectOption value={props.user?.uid}>
                                    {`${profile?.firstname} ${profile?.lastname}`}
                                </FormSelectOption>
                            </FormSelect>
                        </Grid>
                        <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                            <FormLabel>
                                Num of words
                            </FormLabel>
                            <SpacerTopBottomMedium />
                            <FormInput
                                name="numOfWords"
                                type='number'
                                value={numOfWords}
                                onChange={(e) => setNumOfWords(e.target.value)}
                            />
                        </Grid>
                        <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                            <FlexBoxSB>
                                <FormLabel>
                                    Duration
                                </FormLabel>
                                <FormSubLabel>
                                    (minutes)
                                </FormSubLabel>
                            </FlexBoxSB>
                            <SpacerTopBottomMedium />
                            <FormInput
                                name="duration"
                                type='number'
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                            />
                        </Grid>
                        <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                            <FormLabel>
                                Num of contents
                            </FormLabel>
                            <SpacerTopBottomMedium />
                            <FormInput
                                name="numOfContents"
                                type='number'
                                value={numOfContents}
                                onChange={(e) => setNumOfContents(e.target.value)}
                            />
                        </Grid>
                        <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                            <FormLabel>
                                Mastery
                            </FormLabel>
                            <SpacerTopBottomMedium />
                            <FormSelect
                                name="mastery"
                                value={mastery}
                                onChange={(e) => setMastery(e.target.value)}>
                                <FormSelectOption value="">
                                    -- choose --
                                </FormSelectOption>
                                <FormSelectOption value="design">
                                    Design
                                </FormSelectOption>
                                <FormSelectOption value="development">
                                    Development
                                </FormSelectOption>
                                <FormSelectOption value="marketing">
                                    Marketing
                                </FormSelectOption>
                                <FormSelectOption value="testing">
                                    Testing
                                </FormSelectOption>
                            </FormSelect>
                        </Grid>
                        <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                            <FormLabel>
                                Topic
                            </FormLabel>
                            <SpacerTopBottomMedium />
                            <FormInput
                                name="topic"
                                type='text'
                                value={topic}
                                onChange={(e) => setTopic(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <SpacerTopBottomXXXL />
                    <h3 style={{ textDecoration: 'underline', textUnderlineOffset: 4, color: "gray" }}>
                        Outlook
                    </h3>
                    <SpacerTopBottomXXL />
                    <Grid container spacing={5}>
                        <Grid item xl={4.5} lg={4.5} md={6} sm={12} xs={12}>
                            <FlexBoxSB>
                                <FormLabel>
                                    Description
                                </FormLabel>
                                <FormSubLabel>
                                    {`(${description ? countWords(description) : "0"}/50 words)`}
                                </FormSubLabel>
                            </FlexBoxSB>
                            <SpacerTopBottomMedium />
                            <FormTextArea
                                rows={5}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Grid>
                        <Grid item xl={4.5} lg={4.5} md={6} sm={12} xs={12}>
                            <FlexBoxSB>
                                <FormLabel>
                                    Other tags
                                </FormLabel>
                                <FormSubLabel>
                                    (seperate with comma)
                                </FormSubLabel>
                            </FlexBoxSB>
                            <SpacerTopBottomMedium />
                            <FormTextArea
                                rows={5}
                                value={tags}
                                onChange={(e) => setTags(e.target.value)}
                            />
                        </Grid>
                        <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
                            <FormLabel>
                                Format
                            </FormLabel>
                            <SpacerTopBottomMedium />
                            <FormSelect
                                name="format"
                                value={format}
                                onChange={(e) => setFormat(e.target.value)}>
                                <FormSelectOption>
                                    -- choose --
                                </FormSelectOption>
                                <FormSelectOption value="A1">
                                    A1
                                </FormSelectOption>
                            </FormSelect>
                            <SpacerTopBottomLarge />
                            <FormLabel>
                                Hero text color
                            </FormLabel>
                            <SpacerTopBottomMedium />
                            <FormSelect
                                name="heroTextColor"
                                value={heroTextcolor}
                                onChange={(e) => setHeroTextColor(e.target.value)}>
                                <FormSelectOption>
                                    -- choose --
                                </FormSelectOption>
                                <FormSelectOption value="white">
                                    White
                                </FormSelectOption>
                                <FormSelectOption value="black">
                                    Black
                                </FormSelectOption>
                            </FormSelect>
                        </Grid>
                        <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                            <FlexBoxSB>
                                <FormLabel>
                                    Display Picture
                                </FormLabel>
                                <FormSubLabel>
                                    (Ratio: 2:3)
                                </FormSubLabel>
                            </FlexBoxSB>
                            <SpacerTopBottomMedium />
                            <FormInput
                                id='dpFile'
                                name="dpFile"
                                type='file'
                                onChange={e => setDpFile(e.target.files[0])}
                            />
                            {
                                dpFile
                                &&
                                <FormThumbnailImg23 src={dpFile && URL.createObjectURL(dpFile)} />
                            }
                        </Grid>
                        <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                            <FlexBoxSB>
                                <FormLabel>
                                    Banner Picture- Large
                                </FormLabel>
                                <FormSubLabel>
                                    (Ratio: 16:9)
                                </FormSubLabel>
                            </FlexBoxSB>
                            <SpacerTopBottomMedium />
                            <FormInput
                                id='bannerFile169'
                                name="bannerFile169"
                                type='file'
                                onChange={e => setBannerFile169(e.target.files[0])}
                            />
                            {
                                bannerFile169
                                &&
                                <FormThumbnailImg169 src={bannerFile169 && URL.createObjectURL(bannerFile169)} />
                            }
                        </Grid>
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <FlexBoxSB>
                                <FormLabel>
                                    Banner Picture- medium
                                </FormLabel>
                                <FormSubLabel>
                                    (Ratio: 5:4)
                                </FormSubLabel>
                            </FlexBoxSB>
                            <SpacerTopBottomMedium />
                            <FormInput
                                id='bannerFile54'
                                name="bannerFile54"
                                type='file'
                                onChange={e => setBannerFile54(e.target.files[0])}
                            />
                            {
                                bannerFile54
                                &&
                                <FormThumbnailImg54 src={bannerFile54 && URL.createObjectURL(bannerFile54)} />
                            }
                        </Grid>
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <FlexBoxSB>
                                <FormLabel>
                                    Banner Picture- Mobile
                                </FormLabel>
                                <FormSubLabel>
                                    (Ratio: 3:4)
                                </FormSubLabel>
                            </FlexBoxSB>
                            <SpacerTopBottomMedium />
                            <FormInput
                                id='bannerFile34'
                                name="bannerFile34"
                                type='file'
                                onChange={e => setBannerFile34(e.target.files[0])}
                            />
                            {
                                bannerFile34
                                &&
                                <FormThumbnailImg34 src={bannerFile34 && URL.createObjectURL(bannerFile34)} />
                            }
                        </Grid>
                    </Grid>
                    <SpacerTopBottomXXXL />
                    <FlexBoxSB>
                        <h3 style={{ textDecoration: 'underline', textUnderlineOffset: 4, color: "gray" }}>
                            Content
                        </h3>
                        <Button
                            variant='outlined'
                            color='error'
                            size='small'
                            sx={{ textTransform: "none" }}
                            onClick={handleClearContentBlock}>
                            Clear all content
                        </Button>
                    </FlexBoxSB>
                    <SpacerBottomSmall />
                    {
                        contentBlock?.map((item, index) => (
                            <ContentBlock
                                key={index}
                                index={index}
                                data={item}
                                contentBlockAddRow={() => contentBlockAddRow(index)}
                                contentBlockSubtractRow={() => contentBlockSubtractRow(index)}
                                contentBlockStatus={contentBlockStatus}
                                contentBlock={contentBlock}
                                onChangeString={(e) => handleStringChangeContentBlock(index, e)}
                                onChangeMedia={(e) => handleMediaChangeContentBlock(index, e)}
                                handleOlChangeContentBlock={handleOlChangeContentBlock}
                                handleUlChangeContentBlock={handleUlChangeContentBlock}
                                handleCleanSlate={() => handleCleanSlate(index)}
                            />
                        ))
                    }
                </form>
            </Squeezer>
            <div ref={bottomRef} />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={previewModal}
                onClose={handlePreviewModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={previewModal}>
                    <Box sx={previewModalStyle}>
                        <BlogPage
                            width={props.width}
                            closeModal={handlePreviewModalClose}
                            heading={heading}
                            subHeading={subHeading}
                            author={author}
                            numOfWords={numOfWords}
                            duration={duration}
                            numOfContents={numOfContents}
                            mastery={mastery}
                            topic={topic}
                            description={description}
                            tags={tags}
                            format={format}
                            dpFile={dpFile}
                            bannerFile169={bannerFile169}
                            bannerFile54={bannerFile54}
                            bannerFile34={bannerFile34}
                            heroTextcolor={heroTextcolor}
                            contentBlock={contentBlock}
                            heroTextcolor={heroTextcolor}
                        />
                    </Box>
                </Fade>
            </Modal>
        </AddNewBlogWrapper>
    )
}
