import React from 'react'
import { Squeezer } from '../../../global/style/global.styles'

export const GeneralArchive = (props) => {
    return (
        <>
            <Squeezer>

            </Squeezer>
        </>
    )
}
