import React from 'react'
import { BlogContainer, BlogHeroDatePosition, BlogHeroDateText, BlogHeroDurationPosition, BlogHeroDurationText, BlogHeroHeading, BlogHeroHeadingPosition, BlogHeroTopicPosition, BlogHeroTopicText, BlogHeroWrapper } from '../style/cms.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import { AbsoluteCenter } from '../../../global/style/global.styles'
import moment from 'moment'
import { Layer } from '../../../global/components/layer'

export const BlogHero = (props) => {

    return (
        <>
            <BlogHeroWrapper>
                {
                    props.blogData
                        ?
                        <ImgHolder
                            img={
                                props.width > 1100
                                    ?
                                    props.blogData?.dp.filter((x) => x.type === "banner169")
                                    &&
                                    props.blogData?.dp.filter((x) => x.type === "banner169")[0]?.url
                                    :
                                    props.width > 650
                                        ?
                                        props.blogData?.dp.filter((x) => x.type === "banner54")
                                        &&
                                        props.blogData?.dp.filter((x) => x.type === "banner54")[0]?.url
                                        :
                                        props.blogData?.dp.filter((x) => x.type === "banner34")
                                        &&
                                        props.blogData?.dp.filter((x) => x.type === "banner34")[0]?.url
                            }
                            width="100%"
                            height="100%"
                        />
                        :
                        <ImgHolder
                            img={
                                props.width > 1100
                                    ?
                                    props.banner169
                                    &&
                                    URL.createObjectURL(props.banner169)
                                    :
                                    props.width > 650
                                        ?
                                        props.banner54
                                        &&
                                        URL.createObjectURL(props.banner54)
                                        :
                                        props.banner34
                                        &&
                                        URL.createObjectURL(props.banner34)
                            }
                            width="100%"
                            height="100%"
                        />
                }
                <Layer
                    shade={
                        props.heroTextColor === "white"
                        &&
                        "rgba(0, 0, 0, 0.1)"
                    }
                />
                <BlogHeroTopicPosition>
                    <BlogHeroTopicText style={{ color: props.heroTextColor }}>
                        {props.topic?.toUpperCase()}
                    </BlogHeroTopicText>
                </BlogHeroTopicPosition>
                <BlogHeroHeadingPosition>
                    <BlogHeroHeading style={{ color: props.heroTextColor }}>
                        {props.heading}
                    </BlogHeroHeading>
                </BlogHeroHeadingPosition>
                <BlogHeroDatePosition>
                    <BlogHeroDateText style={{ color: props.heroTextColor }}>
                        {props.heading && moment(props.date).format("DD MMM YYYY")}
                    </BlogHeroDateText>
                </BlogHeroDatePosition>
                <BlogHeroDurationPosition>
                    <BlogHeroDurationText style={{ color: props.heroTextColor }}>
                        {`${props.duration} min`}
                    </BlogHeroDurationText>
                </BlogHeroDurationPosition>
            </BlogHeroWrapper>
        </>
    )
}