import React from 'react'
import { useParams } from 'react-router-dom'
import { Squeezer } from '../../../global/style/global.styles'

export const PagesCms = (props) => {

    const { pageId } = useParams()

    return (
        <>
            <Squeezer>
                {pageId}
            </Squeezer>
        </>
    )
}
