import React from 'react'
import { Squeezer } from '../../../global/style/global.styles'
import { SpacerTopBottomXL } from '../../../design/spacer/spacer'

export const SocialArchive = (props) => {


    
    return (
        <>
            <Squeezer>
                <h3>
                    Social Network Links
                </h3>
                <SpacerTopBottomXL />

            </Squeezer>
        </>
    )
}
