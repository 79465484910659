import { useContext } from "react";
import { AuthContext } from "./context/auth/auth.provider";
import { UpdateContext } from "./context/update/update.provider";
import ProtectedRoute from "./global/components/protectedRoute";
import useWindowDimensions from "./global/components/screenSize";
import { CMS } from "./pages/CMS/cms";
import { AddNewBlog } from "./pages/CMS/interface/addNewBlog";
import { Blog } from "./pages/CMS/screens/blog";
import { Website } from "./pages/CMS/screens/website";
import { CRM } from "./pages/CRM/crm";
import { Dashboard } from "./pages/dashboard/dashboard";
import { Login } from "./pages/login/login";
import { Profile } from "./pages/profile/profile";
import { ContactForm } from "./pages/CRM/screen/contactForm";
import { UpdateBlog } from "./pages/CMS/interface/updateBlog";
import { Drafts } from "./pages/CMS/screens/drafts";
import { Serviced } from "./pages/CRM/screen/serviced";
import { GeneralArchive } from "./pages/CMS/screens/generalArchive";
import { SocialArchive } from "./pages/CMS/screens/socialArchive";
import { PagesCms } from "./pages/CMS/screens/pagesCms";

const { Routes, Route, useNavigate, useLocation } = require("react-router-dom");

function App() {

  const { user } = useContext(AuthContext);
  const { refresh, setRefresh } = useContext(UpdateContext);

  const navigate = useNavigate();
  const location = useLocation();
  const { height, width } = useWindowDimensions();

  return (
    <Routes>
      <Route path="/" element={
        <Login
          width={width}
          navigate={navigate}
          location={location}
          user={user}
          refresh={refresh}
          setRefresh={setRefresh}
        />} />
      <Route path="profile" element={
        <ProtectedRoute>
          <Profile
            width={width}
            navigate={navigate}
            location={location}
            user={user}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </ProtectedRoute>
      } />
      <Route path="/dashboard" element={<ProtectedRoute>
        <Dashboard
          width={width}
          navigate={navigate}
          location={location}
          user={user}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </ProtectedRoute>} />
      <Route path="/cms" element={<ProtectedRoute>
        <CMS
          width={width}
          navigate={navigate}
          height={height}
          location={location}
          user={user}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </ProtectedRoute>}>
        <Route path="website" element={<Website
          width={width}
          navigate={navigate}
          location={location}
          user={user}
          refresh={refresh}
          setRefresh={setRefresh}
        />}>
          <Route path="archive/general" element={<GeneralArchive
            width={width}
            navigate={navigate}
            location={location}
            user={user}
            refresh={refresh}
            setRefresh={setRefresh}
          />} />
          <Route path="archive/social" element={<SocialArchive
            width={width}
            navigate={navigate}
            location={location}
            user={user}
            refresh={refresh}
            setRefresh={setRefresh}
          />} />
          <Route path="pages/:pageId" element={<PagesCms
            width={width}
            navigate={navigate}
            location={location}
            user={user}
            refresh={refresh}
            setRefresh={setRefresh}
          />} />
        </Route>
        <Route path="blog" element={<Blog
          width={width}
          navigate={navigate}
          location={location}
          user={user}
          refresh={refresh}
          setRefresh={setRefresh}
        />}>
          <Route path="add-new" element={<AddNewBlog
            width={width}
            navigate={navigate}
            location={location}
            user={user}
            refresh={refresh}
            setRefresh={setRefresh}
          />} />
          <Route path="live/:blogId" element={<UpdateBlog
            width={width}
            navigate={navigate}
            location={location}
            user={user}
            refresh={refresh}
            setRefresh={setRefresh}
          />} />
          <Route path="drafts" element={<Drafts
            width={width}
            navigate={navigate}
            location={location}
            user={user}
            refresh={refresh}
            setRefresh={setRefresh}
          />} />
          <Route path="draft/:blogId" element={<UpdateBlog
            width={width}
            navigate={navigate}
            location={location}
            user={user}
            refresh={refresh}
            setRefresh={setRefresh}
          />} />
        </Route>
      </Route>
      <Route path="/crm" element={<ProtectedRoute>
        <CRM
          width={width}
          navigate={navigate}
          location={location}
          user={user}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </ProtectedRoute>}>
        <Route path="contactForm" element={<ContactForm
          width={width}
          navigate={navigate}
          location={location}
          user={user}
          refresh={refresh}
          setRefresh={setRefresh}
        />}>
          <Route path="serviced" element={<Serviced
            width={width}
            navigate={navigate}
            location={location}
            user={user}
            refresh={refresh}
            setRefresh={setRefresh}
          />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
