import { Button } from '@mui/material'
import React from 'react'
import { SpacerLeftRightLarge, SpacerLeftRightXXXL, SpacerTopBottomXL, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { FlexBox, FlexBoxSA, Sandwich, Squeezer } from '../../../global/style/global.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import shrugEg from '../../../assets/images/shrugImg.png'
import generalArchiveIco from '../../../assets/icons/generalArchiveIco.png'
import socialArchiveIco from '../../../assets/icons/socialArchiveIco.png'
import { IconHolder } from '../../../global/components/iconHolder'
import { Outlet } from 'react-router-dom'
import { PagesTile } from '../components/pagesTile'

export const Website = (props) => {

  return (
    <>
      <Squeezer>
        <Button
          variant='outlined'
          size='small'
          sx={{ textTransform: "none" }}
          color='error'
          onClick={() => props.navigate(-1)}>
          Go back
        </Button>
        <SpacerTopBottomXXXL />
        {/* <FlexBoxSA>
          <Sandwich>
            <ImgHolder
              img={shrugEg}
              width={props.width > 850 ? 600 : "100%"}
            />
          </Sandwich>
        </FlexBoxSA> */}
      </Squeezer>
      {
        props.location.pathname === "/cms/website"
        &&
        <Squeezer>
          <h3>
            Archive
          </h3>
          <SpacerTopBottomXL />
          <FlexBox>
            <IconHolder
              icon={generalArchiveIco}
              width={200}
              height={200}
              clicked={() => props.navigate('/cms/website/archive/general')}
              title="General"
              placement="top"
            />
            <SpacerLeftRightXXXL />
            <IconHolder
              icon={socialArchiveIco}
              width={200}
              height={200}
              clicked={() => props.navigate('/cms/website/archive/social')}
              title="Social"
              placement="top"
            />
          </FlexBox>
          <SpacerTopBottomXXXL />
          <h3>
            Pages
          </h3>
          <SpacerTopBottomXL />
          {
            PAGESDATA.map((item, index) => (
              <div key={index} style={{ float: 'left' }}>
                <PagesTile
                  data={item}
                  width={props.width}
                  navigate={props.navigate}
                  location={props.location}
                  user={props.user}
                  refresh={props.refresh}
                  setRefresh={props.setRefresh}
                />
              </div>
            ))
          }
        </Squeezer>
      }
      <Outlet />
    </>
  )
}

const PAGESDATA = [
  {
    id: 1,
    seq: 1,
    name: "Home",
    url: "/"
  },
  {
    id: 2,
    seq: 2,
    name: "Contact",
    url: "/"
  },
  {
    id: 3,
    seq: 3,
    name: "About",
    url: "/"
  },
  {
    id: 4,
    seq: 4,
    name: "Services",
    url: "/"
  },
  {
    id: 5,
    seq: 5,
    name: "Design",
    url: "/"
  },
  {
    id: 6,
    seq: 6,
    name: "Development",
    url: "/"
  },
  {
    id: 7,
    seq: 7,
    name: "Marketing",
    url: "/"
  },
  {
    id: 8,
    seq: 8,
    name: "Testing",
    url: "/"
  },
  {
    id: 9,
    seq: 9,
    name: "Attribution",
    url: "/"
  },
]
