import React from 'react'
import { PagesTileContainer } from '../style/cms.styles'
import { AbsoluteCenter } from '../../../global/style/global.styles'

export const PagesTile = (props) => {
    return (
        <>
            <PagesTileContainer onClick={() => props.navigate(`/cms/website/pages/${props.data?.name.toLowerCase()}`)}>
                <AbsoluteCenter>
                    <div style={{
                        fontSize: 32,
                        fontWeight: 300,
                        letterSpacing: "0.02em",
                        color: "#6E6D70"
                    }}>
                        {props.data?.name}
                    </div>
                </AbsoluteCenter>
            </PagesTileContainer>
        </>
    )
}
