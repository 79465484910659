import { Button, Grid, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { ApiContext } from '../../../context/api/api.provider'
import { AuthContext } from '../../../context/auth/auth.provider'
import { UpdateContext } from '../../../context/update/update.provider'
import useWindowDimensions from '../../../global/components/screenSize'
import { AbsoluteEditIcon, DpHolder, DpImg, EditIconHolder, EditIconImg, ThumbnailImg } from '../style/profile.styles'
import userPH from '../../../assets/icons/user.png'
import editIcon from '../../../assets/icons/edit.png'
import { collection, deleteDoc, doc, serverTimestamp, setDoc } from 'firebase/firestore'
import { db, storage } from '../../../firebase/firebase'
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { Container, FlexBox, FlexBoxSA, FlexBoxSB, MagnifyIcon, Sandwich, SandwichRealHalf, Squeezer } from '../../../global/style/global.styles'
import { IconHolder } from '../../../global/components/iconHolder'
import { SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall, SpacerTopBottomXL, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'

export const ProfileDetails = (props) => {

    const { profile } = useContext(ApiContext);
    const { user } = useContext(AuthContext);
    const { profileRefresh, setProfileRefresh } = useContext(UpdateContext);

    const [editProfile, setEditProfile] = useState(false)

    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [dp, setDp] = useState("")

    const [file, setFile] = useState("");
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleUpdate = async () => {
        setLoading(true);

        if (file) {
            const ext = file?.name
            const randomId = doc(collection(db, "temp")).id
            const storageRef = ref(storage, `DP/${randomId}${ext?.substring(ext?.lastIndexOf("."))}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const prog = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(prog);
                },
                (error) => console.log(error),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

                        await setDoc(doc(db, "users", user.uid), {
                            firstname: firstname ? firstname : profile.firstname,
                            lastname: lastname ? lastname : profile.lastname,
                            dp: downloadURL,
                            userId: user.uid,
                            updatedAt: serverTimestamp()
                        })

                        setLoading(false)
                        setFile("")
                        setProfileRefresh(!profileRefresh);
                        setEditProfile(false);

                        if (profile?.dp) {
                            // Create a reference to the file to delete
                            const fileRef = ref(storage, profile?.dp);

                            // Delete the file
                            deleteObject(fileRef).then(() => {
                                // File deleted successfully
                            }).catch((error) => {
                                // Uh-oh, an error occurred!
                            });
                        }
                    });
                }
            );


        } else {
            await setDoc(doc(db, "users", user.uid), {
                firstname: firstname ? firstname : profile.firstname,
                lastname: lastname ? lastname : profile.lastname,
                userId: user.uid,
                updatedAt: serverTimestamp()
            })

            setLoading(false)
            setFile("")
            setProfileRefresh(!profileRefresh);
            setEditProfile(false);
        }
    }

    const cancelEdit = () => {
        setEditProfile(false);
        setFile("")
    }

    return (
        <>
            <Container>
                <FlexBoxSB>
                    <h1>
                        Profile
                    </h1>
                    <FlexBox style={{ justifyContent: 'end' }}>
                        {
                            editProfile
                                ?
                                <Button
                                    variant='outlined'
                                    fullWidth={props.width > 850 ? false : true}
                                    color='error'
                                    onClick={cancelEdit}>
                                    Cancel edit
                                </Button>
                                :
                                <Button
                                    variant='outlined'
                                    fullWidth={props.width > 850 ? false : true}
                                    color='secondary'
                                    onClick={() => setEditProfile(true)}>
                                    Edit Profile
                                </Button>
                        }
                    </FlexBox>
                </FlexBoxSB>
                <SpacerTopBottomXXXL />
                <Grid container spacing={8}>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <FlexBoxSA>
                            <DpHolder>
                                {
                                    file
                                        ?
                                        <ThumbnailImg src={file && URL.createObjectURL(file)} />
                                        :
                                        <DpImg src={profile?.dp ? profile?.dp : userPH} />
                                }
                                {
                                    !profile?.dp
                                        ?
                                        <AbsoluteEditIcon>
                                            <label htmlFor='file'>
                                                <MagnifyIcon>
                                                    <EditIconHolder>
                                                        <EditIconImg src={editIcon} />
                                                    </EditIconHolder>
                                                </MagnifyIcon>
                                                <input
                                                    id="file"
                                                    type='file'
                                                    style={{ display: 'none' }}
                                                    onChange={e => setFile(e.target.files[0])}
                                                />
                                            </label>
                                        </AbsoluteEditIcon>
                                        :
                                        editProfile
                                        &&
                                        <AbsoluteEditIcon>
                                            <label htmlFor='file'>
                                                <MagnifyIcon>
                                                    <EditIconHolder>
                                                        <EditIconImg src={editIcon} />
                                                    </EditIconHolder>
                                                </MagnifyIcon>
                                                <input
                                                    id="file"
                                                    type='file'
                                                    style={{ display: 'none' }}
                                                    onChange={e => setFile(e.target.files[0])}
                                                />
                                            </label>
                                        </AbsoluteEditIcon>
                                }
                            </DpHolder>
                        </FlexBoxSA>
                        <SpacerTopBottomMedium />
                        {
                            file
                            &&
                            <FlexBoxSA>
                                <div>
                                    File selected: {file?.name}
                                </div>
                            </FlexBoxSA>
                        }
                    </Grid>
                    <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                        <Grid container>
                            <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={12}>
                                <div>
                                    First name
                                </div>
                                <SpacerTopBottomSmall />
                                {
                                    profile?.firstname
                                        ?
                                        editProfile
                                            ?
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                size='small'
                                                fullWidth
                                                placeholder={profile.firstname}
                                                value={firstname}
                                                onChange={(e) => setFirstname(e.target.value)}
                                            />
                                            :
                                            <div>
                                                {profile?.firstname}
                                            </div>
                                        :
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={firstname}
                                            onChange={(e) => setFirstname(e.target.value)}
                                        />
                                }
                            </Grid>
                            <Grid xl={1} lg={1} md={1} sm={1} xs={12}>
                                {
                                    props.width < 650
                                    &&
                                    <SpacerTopBottomXL />
                                }
                            </Grid>
                            <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={12}>
                                <div>
                                    Last name
                                </div>
                                <SpacerTopBottomSmall />
                                {
                                    profile?.lastname
                                        ?
                                        editProfile
                                            ?
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                size='small'
                                                fullWidth
                                                placeholder={profile.lastname}
                                                value={lastname}
                                                onChange={(e) => setLastname(e.target.value)}
                                            />
                                            :
                                            <div>
                                                {profile?.lastname}
                                            </div>
                                        :
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={lastname}
                                            onChange={(e) => setLastname(e.target.value)}
                                        />
                                }
                            </Grid>
                            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                                <SpacerTopBottomXL />
                            </Grid>
                            <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={12}>
                                <div>
                                    Username / Email address
                                </div>
                                <SpacerTopBottomSmall />
                                <div>
                                    {user?.email}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Sandwich />
                <FlexBox style={{ justifyContent: 'end' }}>
                    {
                        loading ?
                            <LoadingButton
                                loading
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="outlined"
                                fullWidth={props.width > 850 ? false : true}
                            >
                                Updating
                            </LoadingButton>
                            :
                            <Button
                                variant='contained'
                                fullWidth={props.width > 850 ? false : true}
                                color='warning'
                                onClick={handleUpdate}>
                                Update
                            </Button>
                    }
                </FlexBox>
            </Container>
        </>
    )
}
