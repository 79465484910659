import React, { useContext } from 'react'
import { FlexBoxSA, Sandwich, Squeezer } from '../../../global/style/global.styles'
import { ApiContext } from '../../../context/api/api.provider';
import { SpacerTopBottomXL } from '../../../design/spacer/spacer';
import { Grid, LinearProgress } from '@mui/material';
import { ImgHolder } from '../../../global/components/imgHolder';
import shrugEg from '../../../assets/images/shrugImg.png'
import { ServicedBlock } from '../components/servicedBlock';

export const Serviced = (props) => {

    const {
        leads,
        leadsCollectionRef,
        loadingLeads
    } = useContext(ApiContext);

    return (
        <>
            <Squeezer>
                <h3>
                    Serviced
                </h3>
                <SpacerTopBottomXL />
                {
                    loadingLeads
                        ?
                        <LinearProgress
                            color='warning'
                        />
                        :
                        leads?.filter((x) => x.serviced === true).length > 0
                            ?
                            <Grid container>
                                {
                                    leads?.filter((x) => x.serviced === true).map((item, index) => (
                                        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                                            <>
                                                <ServicedBlock
                                                    key={index}
                                                    id={item.id}
                                                    date={item.createdAt}
                                                    name={item.name}
                                                    email={item.email}
                                                    phone={item.phone}
                                                    comName={item.comName}
                                                    captured={item.captured}
                                                    req={item.req}
                                                    refresh={props.refresh}
                                                    setRefresh={props.setRefresh}
                                                />
                                            </>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                            :
                            !loadingLeads
                            &&
                            <FlexBoxSA>
                                <Sandwich>
                                    <ImgHolder
                                        img={shrugEg}
                                        width={props.width > 850 ? 600 : "100%"}
                                    />
                                </Sandwich>
                            </FlexBoxSA>
                }
            </Squeezer>
        </>
    )
}
