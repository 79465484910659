import { Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { SpacerTopBottomSmall } from '../../../design/spacer/spacer'
import { Logout, Settings } from '@mui/icons-material'
import { AuthContext } from '../../../context/auth/auth.provider'
import { ApiContext } from '../../../context/api/api.provider'
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';
import ContactSupportRoundedIcon from '@mui/icons-material/ContactSupportRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import PieChartRoundedIcon from '@mui/icons-material/PieChartRounded';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import GavelIcon from '@mui/icons-material/Gavel';
import userPH from '../../../assets/icons/user.png'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { ArchiveContext } from '../../../context/archive/archive.provider'

export const NavProfile = (props) => {

    const { logout } = useContext(AuthContext);
    const { profile } = useContext(ApiContext);
    const { version } = useContext(ArchiveContext);

    const handleLogout = () => {
        logout()
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigateProfile = () => {
        props.navigate('/profile')
    }

    return (
        <>
            <Tooltip title={`${profile?.firstname} ${profile?.lastname}`}>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Avatar alt="Profile Picture" src={profile?.dp ? profile?.dp : userPH} />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={navigateProfile}>
                    <Avatar alt="Profile Picture" src={profile?.dp ? profile?.dp : userPH} />
                    Profile
                </MenuItem>
                <MenuItem disabled>
                    <ListItemIcon>
                        <PeopleAltRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Switch account
                </MenuItem>
                {/* <NavLink exact to="/portfolio" style={{ textDecoration: 'none', color: '#212121' }}>
                    <MenuItem>
                        <ListItemIcon>
                            <PieChartRoundedIcon fontSize="small" />
                        </ListItemIcon>
                        Portfolio
                    </MenuItem>
                </NavLink> */}
                <MenuItem disabled>
                    <ListItemIcon>
                        <SchoolRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Academia
                </MenuItem>
                <SpacerTopBottomSmall>
                    <Divider />
                </SpacerTopBottomSmall>
                <MenuItem disabled>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>
                <MenuItem disabled>
                    <ListItemIcon>
                        <FormatListNumberedRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Terms & Conditions
                </MenuItem>
                <MenuItem disabled>
                    <ListItemIcon>
                        <ContactSupportRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Support
                </MenuItem>
                <MenuItem style={{ cursor: 'default' }}>
                    <ListItemIcon>
                        <InfoRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    {`Version: ${version}`}
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" color='error' />
                    </ListItemIcon>
                    <div style={{ color: '#c62828' }}>
                        Logout
                    </div>
                </MenuItem>
            </Menu>
        </>
    )
}
