import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import App from './App';
import { ApiProvider } from './context/api/api.provider';
import { AuthProvider } from './context/auth/auth.provider';
import { UpdateProvider } from './context/update/update.provider';
import { theme } from './design/theme';
import { ArchiveProvider } from './context/archive/archive.provider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <AuthProvider>
        <UpdateProvider>
          <ApiProvider>
            <ArchiveProvider>
              <App />
            </ArchiveProvider>
          </ApiProvider>
        </UpdateProvider>
      </AuthProvider>
    </BrowserRouter>
  </ThemeProvider>
);
