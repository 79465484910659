import React from 'react'
import { SpacerLeftRightMedium } from '../../../design/spacer/spacer'
import { FlexBox, FlexBoxSA } from '../../../global/style/global.styles'
import { Logo, LogoHolder } from '../style/login.styles'
import mainLogo from '../../../assets/icons/logoBlack.png'

export const AppLogo = (props) => {

    return (
        <>
            <FlexBoxSA>
                <FlexBox>
                    {/* <LogoHolder>
                        <Logo src={mainLogo} />
                    </LogoHolder> */}

                    <SpacerLeftRightMedium />
                    {
                        props.width > 650
                            ?
                            <h1>
                                Admin Portal
                            </h1>
                            :
                            <h2>
                                Admin Portal
                            </h2>
                    }
                </FlexBox>
            </FlexBoxSA>
        </>
    )
}