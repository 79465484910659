import React from 'react'
import { Navbar } from '../../global/components/navbar/navbar'
import { Footer } from '../../global/components/footer'
import { Container } from '../../global/style/global.styles'
import PageStart from '../../global/components/pageStart'
import PageEnd from '../../global/components/pageEnd'

export const Dashboard = (props) => {
  
  return (
    <>
      <Navbar />
      <PageStart />
      <Container>
        <h1>
          Dashboard
        </h1>
      </Container>
      <PageEnd />
      <Footer />
    </>
  )
}
