import React from 'react'
import styled from 'styled-components'

export const Layer = (props) => {
    return (
        <>
            <LayerWrapper
                style={{
                    backgroundColor: props.shade,
                    borderRadius: props.borderRadius,
                    zIndex: props.zIndex
                }}
            />
        </>
    )
}

const LayerWrapper = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
`;