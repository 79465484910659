import React, { createContext, useState } from 'react'

export const UpdateContext = createContext()

export const UpdateProvider = ({ children }) => {

    const [refresh, setRefresh] = useState(false)
    const [profileRefresh, setProfileRefresh] = useState(false)

    return (
        <UpdateContext.Provider
            value={{
                refresh,
                setRefresh,
                profileRefresh,
                setProfileRefresh
            }}
        >
            {children}
        </UpdateContext.Provider>
    )
}