import React from 'react'
import { AbsoluteCenter, AlignCenter, FlexBoxSA, PurposeBtnContainer } from '../style/global.styles'
import EditNoteIcon from '@mui/icons-material/EditNote';
import { SpacerTopBottomMedium } from '../../design/spacer/spacer';

export const PurposeBtn = (props) => {
    return (
        <>
            <PurposeBtnContainer style={{ border: `4px solid ${props.color}` }} href={props.link}>
                <AbsoluteCenter>
                    <FlexBoxSA>
                        <EditNoteIcon
                            sx={{
                                color: props.color
                            }}
                        />
                    </FlexBoxSA>
                    <SpacerTopBottomMedium />
                    <AlignCenter>
                        <h5 style={{ color: props.color }}>
                            {`${props.title} (${props.num ? props.num : 0})`}
                        </h5>
                    </AlignCenter>
                </AbsoluteCenter>
            </PurposeBtnContainer>
        </>
    )
}
