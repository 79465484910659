import styled from 'styled-components'

// ----------------------------------------CONTAINERS----------------------------------------//

export const CRMWrapper = styled.div`
    background-color: #F5F5F9;
    min-height: 100vh;
`;

export const ServicedBlockContainer = styled.div`
    padding-top: ${(props) => props.theme.space[3]};
    padding-bottom: ${(props) => props.theme.space[3]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]};
    border-radius: 16px;
    background-color: #FFFEF7;
    width: 240px;
    height: 360px;
    box-shadow: 0px 1px 5px 0px rgba(206,206,206,0.75);
-webkit-box-shadow: 0px 1px 5px 0px rgba(206,206,206,0.75);
-moz-box-shadow: 0px 1px 5px 0px rgba(206,206,206,0.75);
`;