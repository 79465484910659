import React from 'react'
import { BlogContentImg, BlogContentLi, BlogContentPara, BlogContentSubTitle, BlogContentTitle } from '../style/cms.styles'
import { SpacerBottomXL, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall, SpacerTopBottomXL, SpacerTopBottomXXL, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { Sandwich, SandwichHalf, SandwichRealHalf } from '../../../global/style/global.styles'

export const A1BlogFormat = (props) => {

    const dataBlocks = props.contentBlock.sort((a, b) => a.block - b.block).filter((x) => x.block !== props.contentBlock.length + 1)

    return (
        <>
            {
                dataBlocks.sort((a, b) => (a.block > b.block) ? 1 : ((b.block > a.block) ? -1 : 0))
                    .map((item, index) => (
                        <div key={index}>
                            {
                                item.title
                                    ?
                                    <BlogContentTitle>
                                        {item.title}
                                    </BlogContentTitle>
                                    :
                                    item.titleB
                                        ?
                                        <BlogContentTitle style={{ fontWeight: 700 }}>
                                            {item.titleB}
                                        </BlogContentTitle>
                                        :
                                        item.titleI
                                            ?
                                            <BlogContentTitle style={{ fontStyle: "italic" }}>
                                                {item.titleI}
                                            </BlogContentTitle>
                                            :
                                            item.titleU
                                                ?
                                                <BlogContentTitle style={{ textDecoration: "underline" }}>
                                                    {item.titleU}
                                                </BlogContentTitle>
                                                :
                                                item.titleBI
                                                    ?
                                                    <BlogContentTitle style={{ fontWeight: 700, fontStyle: "italic" }}>
                                                        {item.titleBI}
                                                    </BlogContentTitle>
                                                    :
                                                    item.titleBU
                                                        ?
                                                        <BlogContentTitle style={{ fontWeight: 700, textDecoration: "underline" }}>
                                                            {item.titleBU}
                                                        </BlogContentTitle>
                                                        :
                                                        item.titleUI
                                                            ?
                                                            <BlogContentTitle style={{ fontStyle: "italic", textDecoration: "underline" }}>
                                                                {item.titleUI}
                                                            </BlogContentTitle>
                                                            :
                                                            item.titleBIU
                                                                ?
                                                                <BlogContentTitle style={{ fontWeight: 700, fontStyle: "italic", textDecoration: "underline" }} >
                                                                    {item.titleBIU}
                                                                </BlogContentTitle>
                                                                :
                                                                item.subTitle
                                                                    ?
                                                                    <BlogContentSubTitle>
                                                                        {item.subTitle}
                                                                    </BlogContentSubTitle>
                                                                    :
                                                                    item.subTitleB
                                                                        ?
                                                                        <BlogContentSubTitle style={{ fontWeight: 700 }}>
                                                                            {item.subTitleB}
                                                                        </BlogContentSubTitle>
                                                                        :
                                                                        item.subTitleI
                                                                            ?
                                                                            <BlogContentSubTitle style={{ fontStyle: "italic" }}>
                                                                                {item.subTitleI}
                                                                            </BlogContentSubTitle>
                                                                            :
                                                                            item.subTitleU
                                                                                ?
                                                                                <BlogContentSubTitle style={{ textDecoration: "underline" }}>
                                                                                    {item.subTitleU}
                                                                                </BlogContentSubTitle>
                                                                                :
                                                                                item.subTitleBI
                                                                                    ?
                                                                                    <BlogContentSubTitle style={{ fontWeight: 700, fontStyle: "italic" }}>
                                                                                        {item.subTitleBI}
                                                                                    </BlogContentSubTitle>
                                                                                    :
                                                                                    item.subTitleBU
                                                                                        ?
                                                                                        <BlogContentSubTitle style={{ fontWeight: 700, textDecoration: "underline" }}>
                                                                                            {item.subTitleBU}
                                                                                        </BlogContentSubTitle>
                                                                                        :
                                                                                        item.subTitleUI
                                                                                            ?
                                                                                            <BlogContentSubTitle style={{ fontStyle: "italic", textDecoration: "underline" }}>
                                                                                                {item.subTitleUI}
                                                                                            </BlogContentSubTitle>
                                                                                            :
                                                                                            item.subTitleBIU
                                                                                                ?
                                                                                                <BlogContentSubTitle style={{ fontWeight: 700, fontStyle: "italic", textDecoration: "underline" }} >
                                                                                                    {item.subTitleBIU}
                                                                                                </BlogContentSubTitle>
                                                                                                :
                                                                                                item.para
                                                                                                    ?
                                                                                                    <BlogContentPara>
                                                                                                        {item.para}
                                                                                                    </BlogContentPara>
                                                                                                    :
                                                                                                    item.paraB
                                                                                                        ?
                                                                                                        <BlogContentPara style={{ fontWeight: 700 }}>
                                                                                                            {item.paraB}
                                                                                                        </BlogContentPara>
                                                                                                        :
                                                                                                        item.paraI
                                                                                                            ?
                                                                                                            <BlogContentPara style={{ fontStyle: "italic" }}>
                                                                                                                {item.paraI}
                                                                                                            </BlogContentPara>
                                                                                                            :
                                                                                                            item.paraU
                                                                                                                ?
                                                                                                                <BlogContentPara style={{ textDecoration: "underline" }}>
                                                                                                                    {item.paraU}
                                                                                                                </BlogContentPara>
                                                                                                                :
                                                                                                                item.paraBI
                                                                                                                    ?
                                                                                                                    <BlogContentPara style={{ fontWeight: 700, fontStyle: "italic" }}>
                                                                                                                        {item.paraBI}
                                                                                                                    </BlogContentPara>
                                                                                                                    :
                                                                                                                    item.paraBU
                                                                                                                        ?
                                                                                                                        <BlogContentPara style={{ fontWeight: 700, textDecoration: "underline" }}>
                                                                                                                            {item.paraBU}
                                                                                                                        </BlogContentPara>
                                                                                                                        :
                                                                                                                        item.paraUI
                                                                                                                            ?
                                                                                                                            <BlogContentPara style={{ fontStyle: "italic", textDecoration: "underline" }}>
                                                                                                                                {item.paraUI}
                                                                                                                            </BlogContentPara>
                                                                                                                            :
                                                                                                                            item.paraBIU
                                                                                                                                ?
                                                                                                                                <BlogContentPara style={{ fontWeight: 700, fontStyle: "italic", textDecoration: "underline" }} >
                                                                                                                                    {item.paraBIU}
                                                                                                                                </BlogContentPara>
                                                                                                                                :
                                                                                                                                item.ol
                                                                                                                                    ?
                                                                                                                                    <ol>
                                                                                                                                        {
                                                                                                                                            item.ol?.map((listItem) => (
                                                                                                                                                <BlogContentLi>{listItem.ol}</BlogContentLi>
                                                                                                                                            ))
                                                                                                                                        }
                                                                                                                                    </ol>
                                                                                                                                    :
                                                                                                                                    item.ul
                                                                                                                                        ?
                                                                                                                                        <ul>
                                                                                                                                            {
                                                                                                                                                item.ul?.map((listItem) => (
                                                                                                                                                    <BlogContentLi>{listItem.ul}</BlogContentLi>
                                                                                                                                                ))
                                                                                                                                            }
                                                                                                                                        </ul>
                                                                                                                                        :
                                                                                                                                        item.img
                                                                                                                                            ?
                                                                                                                                            <BlogContentImg
                                                                                                                                                src={typeof item.img === 'object' ? URL.createObjectURL(item.img) : item.img}
                                                                                                                                            />
                                                                                                                                            :
                                                                                                                                            item.spacing === "small"
                                                                                                                                                ?
                                                                                                                                                <SpacerTopBottomSmall />
                                                                                                                                                :
                                                                                                                                                item.spacing === "medium"
                                                                                                                                                    ?
                                                                                                                                                    <SpacerTopBottomMedium />
                                                                                                                                                    :
                                                                                                                                                    item.spacing === "large"
                                                                                                                                                        ?
                                                                                                                                                        <SpacerTopBottomLarge />
                                                                                                                                                        :
                                                                                                                                                        item.spacing === "xl"
                                                                                                                                                            ?
                                                                                                                                                            <SpacerTopBottomXL />
                                                                                                                                                            :
                                                                                                                                                            item.spacing === "xxl"
                                                                                                                                                                ?
                                                                                                                                                                <SpacerTopBottomXXL />
                                                                                                                                                                :
                                                                                                                                                                item.spacing === "xxxl"
                                                                                                                                                                    ?
                                                                                                                                                                    <SpacerTopBottomXXXL />
                                                                                                                                                                    :
                                                                                                                                                                    item.spacing === "max1"
                                                                                                                                                                        ?
                                                                                                                                                                        <SandwichHalf />
                                                                                                                                                                        :
                                                                                                                                                                        item.spacing === "max2"
                                                                                                                                                                        &&
                                                                                                                                                                        <Sandwich />
                            }
                        </div>
                    ))
            }
        </>
    )
}
