// DEFAULT
import React from 'react'

// INTERFACE
import { LoginForm } from './interface/loginForm'
import { AppLogo } from './interface/appLogo'
import { LoginWrapper } from './style/login.styles'
import { LoginFooter } from './interface/loginFooter'
import { Layer } from '../../global/components/layer'
import { ImgHolder } from '../../global/components/imgHolder'
import { AbsoluteCenter, FlexColumnSE } from '../../global/style/global.styles'
import loginBg from '../../assets/images/loginBg.png'

// MAIN
export const Login = (props) => {
    return (
        <>
            <LoginWrapper>
                <ImgHolder
                    img={loginBg}
                    width={'100%'}
                    height={'100%'}
                />
                {/* <Layer
                    shade={'rgba(0,0,0,0.1)'}
                /> */}
                <AbsoluteCenter style={{ height: '100%' }}>
                    <FlexColumnSE style={{ height: '100%' }}>
                        <AppLogo 
                            width={props.width}
                        />
                        <LoginForm 
                            width={props.width}
                        />
                        <LoginFooter 
                            width={props.width}
                        />
                    </FlexColumnSE>
                </AbsoluteCenter>
            </LoginWrapper>
        </>

    )
}