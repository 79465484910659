import React from 'react'
import { InspiriseLogo, InspiriseLogoHolder, LoginFooterContainer } from '../style/login.styles'
import { FlexBoxSA } from '../../../global/style/global.styles'
import { SpacerTopBottomSmall } from '../../../design/spacer/spacer'
import logoRectDark from '../../../assets/icons/Rectangle Dark.png'

export const LoginFooter = (props) => {
    return (
        <>
            <LoginFooterContainer>
                <FlexBoxSA>
                    <a href='http://www.inspirise.com/' target="_blank" style={{ textDecoration: 'none' }}>
                        <div>
                            Powered by:
                        </div>
                        <SpacerTopBottomSmall />
                        <InspiriseLogoHolder>
                            <InspiriseLogo src={logoRectDark} />
                        </InspiriseLogoHolder>
                    </a>
                </FlexBoxSA>
            </LoginFooterContainer>
        </>
    )
}