import React from 'react'
import { BlogContainer, BlogContentPara } from '../style/cms.styles'
import { A1BlogFormat } from '../interface/a1BlogFormat'

export const BlogContent = (props) => {
    return (
        <>
            <BlogContainer>
                {
                    props.format === "A1"
                    &&
                    <A1BlogFormat
                        width={props.width}
                        contentBlock={props.contentBlock}
                        blogData={props.blogData}
                    />
                }
            </BlogContainer>
        </>
    )
}