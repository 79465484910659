import styled from 'styled-components'

export const LoginWrapper = styled.div`
    height: 100vh;
    position: relative;
    overflow-y: hidden;
`;

export const LoginFormComponent = styled.div`
    border-radius: 8px;
    padding: ${(props) => props.theme.space[5]};
    box-shadow: 0px 3px 7px 1px rgba(207,207,207,0.75);
-webkit-box-shadow: 0px 3px 7px 1px rgba(207,207,207,0.75);
-moz-box-shadow: 0px 3px 7px 1px rgba(207,207,207,0.75);
    background-color: #FFFFFF;
    min-width: 400px;
    margin: 0 auto;
    
    @media only screen and (max-width: 450px) {
        min-width: 320px;
        padding: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 350px) {
        min-width: 280px;
        padding: ${(props) => props.theme.space[4]};
    }
`;

export const LogoHolder = styled.div`
    width: 96px;
    height: 96px;
    
    @media only screen and (max-width: 350px) {
        width: 80px;
        height: 80px;
    }
`;

export const Logo = styled.img`
    height: 100%;
    width: 100%;
`;

export const LoginFooterContainer = styled.div`

`;

export const InspiriseLogoHolder = styled.div`
    width: 200px;

    @media only screen and (max-width: 350px) {
        width: 120px;
    }
`;

export const InspiriseLogo = styled.img`
    height: 100%;
    width: 100%;
`;