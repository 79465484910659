import React from 'react'
import { AbsoluteCenter, ActionBtnContainer } from '../style/global.styles'
import { Layer } from './layer'

export const ActionBtn = (props) => {
    return (
        <>
            <ActionBtnContainer
                href={props.link}
                onClick={props.clicked}
                style={{
                    backgroundImage: `url(${props.bgImg})`
                }}>
                <Layer
                    shade="rgba(0, 0, 0, 0.1)"
                    borderRadius={8}
                />
                <AbsoluteCenter>
                    <h3 style={{ color: "white", textAlign: "center" }}>
                        {props.title}
                    </h3>
                </AbsoluteCenter>
            </ActionBtnContainer>
        </>
    )
}
