import React, { useContext } from 'react'
import { FlexBoxSA, FlexBoxSB, Sandwich, Squeezer } from '../../../global/style/global.styles'
import { Button, Grid, LinearProgress } from '@mui/material'
import { SpacerTopBottomXL, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import shrugEg from '../../../assets/images/shrugImg.png'
import { ImgHolder } from '../../../global/components/imgHolder'
import { ApiContext } from '../../../context/api/api.provider'
import { CMSTableRow } from '../components/cmsTableRow'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Outlet } from 'react-router-dom'

export const ContactForm = (props) => {

    const {
        leads,
        leadsCollectionRef,
        loadingLeads
    } = useContext(ApiContext);

    return (
        <>
            <Squeezer>
                <Button
                    variant='outlined'
                    size='small'
                    sx={{ textTransform: "none" }}
                    color='error'
                    onClick={() => props.navigate(-1)}>
                    Go back
                </Button>
                <SpacerTopBottomXXXL />
            </Squeezer>
            {
                props.location.pathname === "/crm/contactForm"
                &&
                <Squeezer>
                    <FlexBoxSB>
                        <h3>
                            Contact Form
                        </h3>
                        {
                            leads?.filter((x) => x.serviced).length > 0
                            &&
                            <Button
                                variant='outlined'
                                color='info'
                                sx={{
                                    textTransform: "none"
                                }}
                                startIcon={<CheckBoxOutlinedIcon />}
                                onClick={() => props.navigate("/crm/contactForm/serviced")}>
                                Serviced
                            </Button>
                        }
                    </FlexBoxSB>
                    <SpacerTopBottomXL />
                    {
                        loadingLeads
                            ?
                            <LinearProgress
                                color='warning'
                            />
                            :
                            leads?.filter((x) => !x.serviced).length > 0
                                ?
                                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr>
                                            <th
                                                width="12%"
                                                style={{
                                                    padding: '8px',
                                                    border: '1px solid #B0B2B2',
                                                    borderBottom: '1px solid #000000',
                                                    textAlign: 'left'
                                                }}>
                                                Date
                                            </th>
                                            <th
                                                style={{
                                                    padding: '8px',
                                                    border: '1px solid #B0B2B2',
                                                    borderBottom: '1px solid #000000',
                                                    textAlign: 'left'
                                                }}>
                                                Name
                                            </th>
                                            <th
                                                style={{
                                                    padding: '8px',
                                                    border: '1px solid #B0B2B2',
                                                    borderBottom: '1px solid #000000',
                                                    textAlign: 'left'
                                                }}>
                                                Email
                                            </th>
                                            <th
                                                style={{
                                                    padding: '8px',
                                                    border: '1px solid #B0B2B2',
                                                    borderBottom: '1px solid #000000',
                                                    textAlign: 'left'
                                                }}>
                                                Phone
                                            </th>
                                            <th
                                                style={{
                                                    padding: '8px',
                                                    border: '1px solid #B0B2B2',
                                                    borderBottom: '1px solid #000000',
                                                    textAlign: 'left'
                                                }}>
                                                Company
                                            </th>
                                            <th
                                                style={{
                                                    padding: '8px',
                                                    border: '1px solid #B0B2B2',
                                                    borderBottom: '1px solid #000000',
                                                    textAlign: 'left'
                                                }}>
                                                Source
                                            </th>
                                            <th
                                                width="25%"
                                                style={{
                                                    padding: '8px',
                                                    border: '1px solid #B0B2B2',
                                                    borderBottom: '1px solid #000000',
                                                    textAlign: 'left'
                                                }}>
                                                Requirements
                                            </th>
                                            <th
                                                width="20%"
                                                style={{
                                                    padding: '8px',
                                                    border: '1px solid #B0B2B2',
                                                    borderBottom: '1px solid #000000',
                                                    textAlign: 'left'
                                                }}>
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ height: '100px', overflowY: 'auto' }}>
                                        {
                                            leads?.filter((x) => !x.serviced).map((item, index) => (
                                                <CMSTableRow
                                                    key={index}
                                                    id={item.id}
                                                    date={item.createdAt}
                                                    name={item.name}
                                                    email={item.email}
                                                    phone={item.phone}
                                                    comName={item.comName}
                                                    captured={item.captured}
                                                    req={item.req}
                                                    refresh={props.refresh}
                                                    setRefresh={props.setRefresh}
                                                />
                                            ))
                                        }
                                    </tbody>
                                </table>
                                :
                                !loadingLeads
                                &&
                                <FlexBoxSA>
                                    <Sandwich>
                                        <ImgHolder
                                            img={shrugEg}
                                            width={props.width > 850 ? 600 : "100%"}
                                        />
                                    </Sandwich>
                                </FlexBoxSA>
                    }
                </Squeezer>
            }
            <Outlet />
        </>
    )
}
