import React from 'react'
import { BlogTileActBarContainer } from '../style/cms.styles'
import { Tooltip } from '@mui/material'
import { AbsoluteCenter, MagnifyIcon } from '../../../global/style/global.styles';
import { SpacerTopBottomLarge } from '../../../design/spacer/spacer';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import StarHalfRoundedIcon from '@mui/icons-material/StarHalfRounded';

export const BlogTileActBar = (props) => {
    return (
        <>
            {
                props.draft
                    ?
                    <>
                        <MagnifyIcon
                            style={{ backgroundColor: "rgba(0,0,0,0.2)", padding: 8, borderRadius: "50%" }}
                            onClick={props.clickedDelete}>
                            <Tooltip title='Delete' placement='right'>
                                <DeleteRoundedIcon
                                    color='error'
                                />
                            </Tooltip>
                        </MagnifyIcon>
                    </>
                    :
                    <BlogTileActBarContainer>
                        <AbsoluteCenter>
                            <div>
                                <Tooltip title='Edit' placement='right' onClick={props.clickedEdit}>
                                    <EditNoteRoundedIcon
                                        color='secondary'
                                    />
                                </Tooltip>
                                <SpacerTopBottomLarge />
                                {
                                    props.feature === 1
                                        ?
                                        <Tooltip title='Feature' placement='right' onClick={props.clickedUnFeature}>
                                            <StarRateRoundedIcon
                                                color='warning'
                                            />
                                        </Tooltip>
                                        :
                                        props.feature === 2
                                            ?
                                            <Tooltip title='Feature' placement='right' onClick={props.clickedUnFeature}>
                                                <StarHalfRoundedIcon
                                                    color='warning'
                                                />
                                            </Tooltip>
                                            :
                                            props.feature === 0
                                                ?
                                                <Tooltip title='Feature' placement='right' onClick={props.clickedFeature}>
                                                    <StarOutlineRoundedIcon
                                                        color='warning'
                                                    />
                                                </Tooltip>
                                                :
                                                <StarOutlineRoundedIcon
                                                    sx={{
                                                        color: "gray"
                                                    }}
                                                />
                                }
                                <SpacerTopBottomLarge />
                                {
                                    props.status === "active"
                                        ?
                                        <Tooltip title='Make inactive' placement='right' onClick={props.clickedInactive}>
                                            <VisibilityOffRoundedIcon />
                                        </Tooltip>
                                        :
                                        <Tooltip title='Make active' placement='right' onClick={props.clickedActive}>
                                            <VisibilityRoundedIcon
                                                color='info'
                                            />
                                        </Tooltip>
                                }
                                <SpacerTopBottomLarge />
                                <Tooltip title='Delete' placement='right' onClick={props.clickedDelete}>
                                    <DeleteRoundedIcon
                                        color='error'
                                    />
                                </Tooltip>
                            </div>
                        </AbsoluteCenter>
                    </BlogTileActBarContainer>
            }
        </>
    )
}
