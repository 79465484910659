import React, { useEffect, useState } from 'react'
import { FlexBox, FlexBoxSA, FlexBoxSB, FormInput, FormLabel, FormSelect, FormSelectOption, FormSubLabel, FormSubLabelFormTextArea, FormTextArea } from '../../../global/style/global.styles'
import { SpacerLeftRightLarge, SpacerLeftRightMedium, SpacerLeftRightSmall, SpacerRightMedium, SpacerRightSmall, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { Backdrop, Box, Button, Fade, Grid, Modal, Switch, Tooltip } from '@mui/material'
import { BlogContentImg, ContentBlockContainer } from '../style/cms.styles'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { ImgHolder } from '../../../global/components/imgHolder'
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import _ from 'lodash'
import { LoadingButton } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save';
import { DeleteAlert } from '../../../global/components/deleteAlert'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export const ContentBlock = (props) => {

    const DeleteModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px',
        outline: 'none'
    };

    const [deleteBlockModal, setDeleteBlockModal] = useState(false)

    const [contentType, setContentType] = useState("");
    const [adjBlock, setAdjBlock] = useState("");

    const handleSetContentType = (ct) => {
        setContentType(ct)
        props.contentBlockAddRow(props.index)
    }

    useEffect(() => {
        if (props.contentBlockStatus === "Fresh") {
            setContentType("")
        }
    }, [props.contentBlockStatus])

    const blockSubtractRow = () => {
        setContentType("")
        props.contentBlockSubtractRow()
    }

    const [ol, setOl] = useState([
        {
            ol: ""
        }
    ])
    const [ul, setUl] = useState([
        {
            ul: ""
        }
    ])

    const handleStringChangeOl = (index, event) => {
        const values = [...ol];
        values[index][event.target.name] = event.target.value;
        setOl(values);
        props.handleOlChangeContentBlock(props.index, event, ol)
    }

    const handleStringChangeUl = (index, event) => {
        const values = [...ul];
        values[index][event.target.name] = event.target.value;
        setUl(values);
    }

    const olAddRow = () => {
        setOl([...ol, {
            ol: ""
        }])
    }

    const ulAddRow = () => {
        setUl([...ul, {
            ul: ""
        }])
    }

    const olSubtractRow = () => {
        const values = [...ol];
        values.splice(ol.length - 1, 1);
        setOl(values);
    }

    const ulSubtractRow = () => {
        const values = [...ul];
        values.splice(ul.length - 1, 1);
        setUl(values);
    }

    const [checker, setChecker] = useState(false);

    const [paraI, setParaI] = useState(false);
    const [paraB, setParaB] = useState(false);
    const [paraU, setParaU] = useState(false);

    const handleParaStyleB = (b) => {
        setParaB(b)
        props.handleCleanSlate()
        setChecker(!checker)
    }
    const handleParaStyleI = (b) => {
        setParaI(b)
        props.handleCleanSlate()
        setChecker(!checker)
    }
    const handleParaStyleU = (b) => {
        setParaU(b)
        props.handleCleanSlate()
        setChecker(!checker)
    }

    const [titleI, setTitleI] = useState(false);
    const [titleB, setTitleB] = useState(false);
    const [titleU, setTitleU] = useState(false);

    const handleTitleStyleB = (b) => {
        setTitleB(b)
        props.handleCleanSlate()
        setChecker(!checker)
    }
    const handleTitleStyleI = (b) => {
        setTitleI(b)
        props.handleCleanSlate()
        setChecker(!checker)
    }
    const handleTitleStyleU = (b) => {
        setTitleU(b)
        props.handleCleanSlate()
        setChecker(!checker)
    }

    const [subTitleI, setSubTitleI] = useState(false);
    const [subTitleB, setSubTitleB] = useState(false);
    const [subTitleU, setSubTitleU] = useState(false);

    const handleSubTitleStyleB = (b) => {
        setSubTitleB(b)
        props.handleCleanSlate()
        setChecker(!checker)
    }
    const handleSubTitleStyleI = (b) => {
        setSubTitleI(b)
        props.handleCleanSlate()
        setChecker(!checker)
    }
    const handleSubTitleStyleU = (b) => {
        setSubTitleU(b)
        props.handleCleanSlate()
        setChecker(!checker)
    }

    useEffect(() => {

        if (contentType === "title" && !titleI && !titleB && !titleU) {
            setContentType("title")
        } else if (contentType === "titleB" && !titleI && !titleB && !titleU) {
            setContentType("title")
        } else if (contentType === "titleI" && !titleI && !titleB && !titleU) {
            setContentType("title")
        } else if (contentType === "titleU" && !titleI && !titleB && !titleU) {
            setContentType("title")
        } else if (contentType === "titleB" && titleI && titleB && !titleU) {
            setContentType("titleBI")
        } else if (contentType === "titleI" && titleI && titleB && !titleU) {
            setContentType("titleBI")
        } else if (contentType === "titleBI" && titleI && !titleB && !titleU) {
            setContentType("titleI")
        } else if (contentType === "titleBI" && !titleI && titleB && !titleU) {
            setContentType("titleB")
        } else if (contentType === "titleB" && !titleI && titleB && titleU) {
            setContentType("titleBU")
        } else if (contentType === "titleU" && !titleI && titleB && titleU) {
            setContentType("titleBU")
        } else if (contentType === "titleBU" && !titleI && !titleB && titleU) {
            setContentType("titleU")
        } else if (contentType === "titleBU" && !titleI && titleB && !titleU) {
            setContentType("titleB")
        } else if (contentType === "titleI" && titleI && !titleB && titleU) {
            setContentType("titleIU")
        } else if (contentType === "titleU" && titleI && !titleB && titleU) {
            setContentType("titleIU")
        } else if (contentType === "titleIU" && !titleI && !titleB && titleU) {
            setContentType("titleU")
        } else if (contentType === "titleIU" && titleI && !titleB && !titleU) {
            setContentType("titleI")
        } else if (contentType === "titleBI" && titleI && titleB && titleU) {
            setContentType("titleBIU")
        } else if (contentType === "titleBU" && titleI && titleB && titleU) {
            setContentType("titleBIU")
        } else if (contentType === "titleIU" && titleI && titleB && titleU) {
            setContentType("titleBIU")
        } else if (contentType === "titleBIU" && !titleI && titleB && titleU) {
            setContentType("titleBU")
        } else if (contentType === "titleBIU" && titleI && titleB && !titleU) {
            setContentType("titleBI")
        } else if (contentType === "titleBIU" && titleI && !titleB && titleU) {
            setContentType("titleIU")
        } else if (titleI) {
            setContentType("titleI")
        } else if (titleB) {
            setContentType("titleB")
        } else if (titleU) {
            setContentType("titleU")
        }

        if (contentType === "subTitle" && !subTitleI && !subTitleB && !subTitleU) {
            setContentType("subTitle")
        } else if (contentType === "subTitleB" && !subTitleI && !subTitleB && !subTitleU) {
            setContentType("subTitle")
        } else if (contentType === "subTitleI" && !subTitleI && !subTitleB && !subTitleU) {
            setContentType("subTitle")
        } else if (contentType === "subTitleU" && !subTitleI && !subTitleB && !subTitleU) {
            setContentType("subTitle")
        } else if (contentType === "subTitleB" && subTitleI && subTitleB && !subTitleU) {
            setContentType("subTitleBI")
        } else if (contentType === "subTitleI" && subTitleI && subTitleB && !subTitleU) {
            setContentType("subTitleBI")
        } else if (contentType === "subTitleBI" && subTitleI && !subTitleB && !subTitleU) {
            setContentType("subTitleI")
        } else if (contentType === "subTitleBI" && !subTitleI && subTitleB && !subTitleU) {
            setContentType("subTitleB")
        } else if (contentType === "subTitleB" && !subTitleI && subTitleB && subTitleU) {
            setContentType("subTitleBU")
        } else if (contentType === "subTitleU" && !subTitleI && subTitleB && subTitleU) {
            setContentType("subTitleBU")
        } else if (contentType === "subTitleBU" && !subTitleI && !subTitleB && subTitleU) {
            setContentType("subTitleU")
        } else if (contentType === "subTitleBU" && !subTitleI && subTitleB && !subTitleU) {
            setContentType("subTitleB")
        } else if (contentType === "subTitleI" && subTitleI && !subTitleB && subTitleU) {
            setContentType("subTitleIU")
        } else if (contentType === "subTitleU" && subTitleI && !subTitleB && subTitleU) {
            setContentType("subTitleIU")
        } else if (contentType === "subTitleIU" && !subTitleI && !subTitleB && subTitleU) {
            setContentType("subTitleU")
        } else if (contentType === "subTitleIU" && subTitleI && !subTitleB && !subTitleU) {
            setContentType("subTitleI")
        } else if (contentType === "subTitleBI" && subTitleI && subTitleB && subTitleU) {
            setContentType("subTitleBIU")
        } else if (contentType === "subTitleBU" && subTitleI && subTitleB && subTitleU) {
            setContentType("subTitleBIU")
        } else if (contentType === "subTitleIU" && subTitleI && subTitleB && subTitleU) {
            setContentType("subTitleBIU")
        } else if (contentType === "subTitleBIU" && !subTitleI && subTitleB && subTitleU) {
            setContentType("subTitleBU")
        } else if (contentType === "subTitleBIU" && subTitleI && subTitleB && !subTitleU) {
            setContentType("subTitleBI")
        } else if (contentType === "subTitleBIU" && subTitleI && !subTitleB && subTitleU) {
            setContentType("subTitleIU")
        } else if (subTitleI) {
            setContentType("subTitleI")
        } else if (subTitleB) {
            setContentType("subTitleB")
        } else if (subTitleU) {
            setContentType("subTitleU")
        }

        if (contentType === "para" && !paraI && !paraB && !paraU) {
            setContentType("para")
        } else if (contentType === "paraB" && !paraI && !paraB && !paraU) {
            setContentType("para")
        } else if (contentType === "paraI" && !paraI && !paraB && !paraU) {
            setContentType("para")
        } else if (contentType === "paraU" && !paraI && !paraB && !paraU) {
            setContentType("para")
        } else if (contentType === "paraB" && paraI && paraB && !paraU) {
            setContentType("paraBI")
        } else if (contentType === "paraI" && paraI && paraB && !paraU) {
            setContentType("paraBI")
        } else if (contentType === "paraBI" && paraI && !paraB && !paraU) {
            setContentType("paraI")
        } else if (contentType === "paraBI" && !paraI && paraB && !paraU) {
            setContentType("paraB")
        } else if (contentType === "paraB" && !paraI && paraB && paraU) {
            setContentType("paraBU")
        } else if (contentType === "paraU" && !paraI && paraB && paraU) {
            setContentType("paraBU")
        } else if (contentType === "paraBU" && !paraI && !paraB && paraU) {
            setContentType("paraU")
        } else if (contentType === "paraBU" && !paraI && paraB && !paraU) {
            setContentType("paraB")
        } else if (contentType === "paraI" && paraI && !paraB && paraU) {
            setContentType("paraIU")
        } else if (contentType === "paraU" && paraI && !paraB && paraU) {
            setContentType("paraIU")
        } else if (contentType === "paraIU" && !paraI && !paraB && paraU) {
            setContentType("paraU")
        } else if (contentType === "paraIU" && paraI && !paraB && !paraU) {
            setContentType("paraI")
        } else if (contentType === "paraBI" && paraI && paraB && paraU) {
            setContentType("paraBIU")
        } else if (contentType === "paraBU" && paraI && paraB && paraU) {
            setContentType("paraBIU")
        } else if (contentType === "paraIU" && paraI && paraB && paraU) {
            setContentType("paraBIU")
        } else if (contentType === "paraBIU" && !paraI && paraB && paraU) {
            setContentType("paraBU")
        } else if (contentType === "paraBIU" && paraI && paraB && !paraU) {
            setContentType("paraBI")
        } else if (contentType === "paraBIU" && paraI && !paraB && paraU) {
            setContentType("paraIU")
        } else if (paraI) {
            setContentType("paraI")
        } else if (paraB) {
            setContentType("paraB")
        } else if (paraU) {
            setContentType("paraU")
        }

    }, [checker])

    // delete props.data?.type
    // delete props.data?.block

    useEffect(() => {
        var objDeepClone = _.cloneDeep(props.data ? props.data : {})
        delete objDeepClone?.type
        delete objDeepClone?.block

        props.data && findNonEmptyField(objDeepClone);
    }, []);

    const [nonEmptyField, setNonEmptyField] = useState('');

    const findNonEmptyField = (obj) => {
        const nonEmptyKey = Object.keys(obj).find(key => obj[key] !== '');
        setNonEmptyField(nonEmptyKey || 'No non-empty field found');
    };

    useEffect(() => {
        setContentType(nonEmptyField !== 'No non-empty field found' ? nonEmptyField : "")

        if (props.data?.ol) { setOl(props.data?.ol) }
        if (props.data?.ul) { setUl(props.data?.ul) }

        // if (nonEmptyField === "paraB" || "paraBI" || "paraBU" || "paraBIU") {
        //     setParaB(true)
        // }
    }, [nonEmptyField])

    const handleSetAdjBlock = (e) => {
        setAdjBlock(e.target.value)
        props.setIntBlockNum(parseFloat(e.target.value))
        setContentType("")
    }

    const handleCancelIntBlock = () => {
        props.setIntermediateBlockModal(false)
        props.setContentBlock(props.blogData?.content);
    }

    const handleDeleteBlock = () => {
        props.contentBlock.splice(props.index, 1)
        props.setContentBlock(props.contentBlock)
        props.handleSaveContentBlock()
    }

    return (
        <ContentBlockContainer>
            {
                props.ambit === "intermediate"
                &&
                <>
                    <FormLabel>
                        Intermediate Block
                    </FormLabel>
                    <SpacerTopBottomLarge />
                    <div>
                        Input Intermediate block number (use decimal points)
                    </div>
                    <SpacerTopBottomMedium />
                    <FlexBox>
                        <FormInput
                            type='number'
                            value={adjBlock}
                            onChange={handleSetAdjBlock}
                            style={{ width: 120 }}
                        />
                        <SpacerLeftRightMedium />
                        {
                            props.blogDataDeepClone?.content.some(el => el.block === parseFloat(props.intBlockNum))
                                ?
                                props.intBlockNum
                                &&
                                "Invalid"
                                :
                                parseFloat(props.intBlockNum) < 1
                                    ?
                                    props.intBlockNum
                                    &&
                                    "Invalid"
                                    :
                                    props.intBlockNum
                                    &&
                                    "Valid"
                        }
                    </FlexBox>
                    <SpacerTopBottomXXXL />
                    <FlexBox>
                        {
                            props.contentBlock?.findIndex((x) => x.block === props.intBlockNum)
                            &&
                            contentType
                            &&
                            <div>
                                This will be between
                                <FlexBox>
                                    Block
                                    <SpacerRightSmall />
                                    {props.contentBlock[props.contentBlock?.findIndex((x) => x.block === props.intBlockNum) - 1]?.block}
                                </FlexBox>
                                and
                                <FlexBox>
                                    Block
                                    <SpacerRightSmall />
                                    {props.contentBlock[props.contentBlock?.findIndex((x) => x.block === props.intBlockNum) + 1]?.block}
                                </FlexBox>
                            </div>
                        }
                    </FlexBox>
                    {/* <FlexBox>
                        <div>
                            <div>
                                Select the previous block
                            </div>
                            <SpacerTopBottomMedium />
                            <FormSelect value={adjBlock} onChange={(e) => handleSetAdjBlock(e)}>
                                <FormSelectOption>
                                    -- choose --
                                </FormSelectOption>
                                {
                                    props.contentBlock?.sort((a, b) => a.block - b.block)
                                        .map((item, index) => (
                                            <FormSelectOption key={index} value={item.block}>
                                                {`Block ${item.block}`}
                                            </FormSelectOption>
                                        ))
                                }
                            </FormSelect>
                        </div>
                    </FlexBox> */}
                    <SpacerTopBottomXXXL />
                </>
            }
            {
                props.ambit !== "intermediate"
                    ?
                    <FormLabel>
                        {
                            props.blogData
                                ?
                                `Block ${props.data?.block}`
                                :
                                `Block ${props.index + 1}`
                        }
                    </FormLabel>
                    :
                    <FormLabel>
                        {adjBlock && `Block ${parseFloat(adjBlock)}`}
                    </FormLabel>
            }
            <SpacerTopBottomMedium />
            {
                contentType === ""
                &&
                <Grid container spacing={2}>
                    <Grid item xl={2} lg={2} md={2} sm={3} xs={4}>
                        <Button
                            color="info"
                            variant='outlined'
                            sx={{ textTransform: "none" }}
                            onClick={() => handleSetContentType("spacing")}>
                            Spacing
                        </Button>
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={3} xs={4}>
                        <Button
                            color="info"
                            variant='outlined'
                            sx={{ textTransform: "none" }}
                            onClick={() => handleSetContentType("title")}>
                            Title
                        </Button>
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={3} xs={4}>
                        <Button
                            color="info"
                            variant='outlined'
                            sx={{ textTransform: "none" }}
                            onClick={() => handleSetContentType("subTitle")}>
                            Sub-Title
                        </Button>
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={3} xs={4}>
                        <Button
                            color="info"
                            variant='outlined'
                            sx={{ textTransform: "none" }}
                            onClick={() => handleSetContentType("para")}>
                            Paragraph
                        </Button>
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={3} xs={4}>
                        <Button
                            color="info"
                            variant='outlined'
                            sx={{ textTransform: "none" }}
                            onClick={() => handleSetContentType("ol")}>
                            Ordered List
                        </Button>
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={3} xs={4}>
                        <Button
                            color="info"
                            variant='outlined'
                            sx={{ textTransform: "none" }}
                            onClick={() => handleSetContentType("ul")}>
                            Unordered List
                        </Button>
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={3} xs={4}>
                        <Button
                            color="info"
                            variant='outlined'
                            sx={{ textTransform: "none" }}
                            onClick={() => handleSetContentType("img")}>
                            Image
                        </Button>
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={3} xs={4}>
                        <Button
                            color="info"
                            variant='outlined'
                            sx={{ textTransform: "none" }}
                            onClick={() => handleSetContentType("vid")}
                            disabled>
                            Video
                        </Button>
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={3} xs={4}>
                        <Button
                            color="info"
                            variant='outlined'
                            sx={{ textTransform: "none" }}
                            onClick={() => handleSetContentType("quote")}
                            disabled>
                            Quote
                        </Button>
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={3} xs={4}>
                        <Button
                            color="info"
                            variant='outlined'
                            sx={{ textTransform: "none" }}
                            onClick={() => handleSetContentType("link")}
                            disabled>
                            Link
                        </Button>
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={3} xs={4}>
                        <Button
                            color="info"
                            variant='outlined'
                            sx={{ textTransform: "none" }}
                            onClick={() => handleSetContentType("textLink")}
                            disabled>
                            Text + Links
                        </Button>
                    </Grid>
                </Grid>
            }
            <SpacerTopBottomMedium />
            {
                contentType === "spacing"
                    ?
                    <>
                        <FlexBoxSB>
                            <div>
                                Spacing
                            </div>
                            <FlexBox>
                                {
                                    props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                        ?
                                        <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                            <DeleteForeverIcon
                                                color='error'
                                                sx={{ cursor: "pointer" }}
                                            />
                                        </Tooltip>
                                        :
                                        props.index + 1 === props.contentBlock.length - 1
                                        &&
                                        <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                            <IndeterminateCheckBoxOutlinedIcon
                                                fontSize='small'
                                                color='error'
                                                sx={{ cursor: "pointer" }}
                                            />
                                        </Tooltip>
                                }
                            </FlexBox>
                        </FlexBoxSB>
                        <SpacerTopBottomMedium />
                        <FormSelect
                            name='spacing'
                            value={props.contentBlock.spacing}
                            onChange={props.onChangeString}>
                            <FormSelectOption value="">
                                {props.data ? props.data?.spacing : "-- choose --"}
                            </FormSelectOption>
                            <FormSelectOption value="small">
                                Small
                            </FormSelectOption>
                            <FormSelectOption value="medium">
                                Medium
                            </FormSelectOption>
                            <FormSelectOption value="large">
                                Large
                            </FormSelectOption>
                            <FormSelectOption value="xl">
                                XL
                            </FormSelectOption>
                            <FormSelectOption value="xxl">
                                XXL
                            </FormSelectOption>
                            <FormSelectOption value="xxxl">
                                XXXL
                            </FormSelectOption>
                            <FormSelectOption value="max1">
                                Max1
                            </FormSelectOption>
                            <FormSelectOption value="max2">
                                Max2
                            </FormSelectOption>
                        </FormSelect>
                    </>
                    :
                    contentType === "para"
                        ?
                        <>
                            <FlexBoxSB>
                                <div style={{ fontWeight: paraB ? 700 : 400, fontStyle: paraI ? "italic" : "normal", textDecoration: paraU ? "underline" : "none" }}>
                                    Paragraph
                                </div>
                                <FlexBox>
                                    <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                        <label style={{ fontWeight: 900 }}>B</label>
                                        <SpacerRightSmall />
                                        <Switch
                                            size='small'
                                            checked={paraB}
                                            onChange={() => handleParaStyleB(true)}
                                        />
                                    </FlexBox>
                                    <SpacerLeftRightSmall />
                                    <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                        <label style={{ fontStyle: "italic" }}>I</label>
                                        <SpacerRightSmall />
                                        <Switch
                                            size='small'
                                            checked={paraI}
                                            onChange={() => handleParaStyleI(true)}
                                        />
                                    </FlexBox>
                                    <SpacerLeftRightSmall />
                                    <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                        <label style={{ textDecoration: "underline" }}>U</label>
                                        <SpacerRightSmall />
                                        <Switch
                                            size='small'
                                            checked={paraU}
                                            onChange={() => handleParaStyleU(true)}
                                        />
                                    </FlexBox>
                                    <SpacerLeftRightMedium />
                                    {
                                        props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                            ?
                                            <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                <DeleteForeverIcon
                                                    color='error'
                                                    sx={{ cursor: "pointer" }}
                                                />
                                            </Tooltip>
                                            :
                                            props.index + 1 === props.contentBlock.length - 1
                                            &&
                                            <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                <IndeterminateCheckBoxOutlinedIcon
                                                    fontSize='small'
                                                    color='error'
                                                    sx={{ cursor: "pointer" }}
                                                />
                                            </Tooltip>
                                    }
                                </FlexBox>
                            </FlexBoxSB>
                            <SpacerTopBottomMedium />
                            <FormTextArea
                                rows={5}
                                name="para"
                                value={props.data?.para ? props.data?.para : props.contentBlock.para}
                                onChange={props.onChangeString}
                                style={{
                                    fontWeight: paraB ? 700 : 400, fontStyle: paraI ? "italic" : "normal", textDecoration: paraU ? "underline" : "none"
                                }}
                            />
                        </>
                        :
                        contentType === "paraB"
                            ?
                            <>
                                <FlexBoxSB>
                                    <div style={{ fontWeight: paraB ? 700 : 400, fontStyle: paraI ? "italic" : "normal", textDecoration: paraU ? "underline" : "none" }}>
                                        Paragraph
                                    </div>
                                    <FlexBox>
                                        <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                            <label style={{ fontWeight: 900 }}>B</label>
                                            <SpacerRightSmall />
                                            <Switch
                                                size='small'
                                                checked={paraB}
                                                onChange={() => handleParaStyleB(false)}
                                            />
                                        </FlexBox>
                                        <SpacerLeftRightSmall />
                                        <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                            <label style={{ fontStyle: "italic" }}>I</label>
                                            <SpacerRightSmall />
                                            <Switch
                                                size='small'
                                                checked={paraI}
                                                onChange={() => handleParaStyleI(true)}
                                            />
                                        </FlexBox>
                                        <SpacerLeftRightSmall />
                                        <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                            <label style={{ textDecoration: "underline" }}>U</label>
                                            <SpacerRightSmall />
                                            <Switch
                                                size='small'
                                                checked={paraU}
                                                onChange={() => handleParaStyleU(true)}
                                            />
                                        </FlexBox>
                                        <SpacerLeftRightMedium />
                                        {
                                            props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                ?
                                                <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                    <DeleteForeverIcon
                                                        color='error'
                                                        sx={{ cursor: "pointer" }}
                                                    />
                                                </Tooltip>
                                                :
                                                props.index + 1 === props.contentBlock.length - 1
                                                &&
                                                <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                    <IndeterminateCheckBoxOutlinedIcon
                                                        fontSize='small'
                                                        color='error'
                                                        sx={{ cursor: "pointer" }}
                                                    />
                                                </Tooltip>
                                        }
                                    </FlexBox>
                                </FlexBoxSB>
                                <SpacerTopBottomMedium />
                                <FormTextArea
                                    rows={5}
                                    name="paraB"
                                    value={props.data?.paraB ? props.data?.paraB : props.contentBlock.paraB}
                                    onChange={props.onChangeString}
                                    style={{
                                        fontWeight: paraB ? 700 : 400, fontStyle: paraI ? "italic" : "normal", textDecoration: paraU ? "underline" : "none"
                                    }}
                                />
                            </>
                            :
                            contentType === "paraI"
                                ?
                                <>
                                    <FlexBoxSB>
                                        <div style={{ fontWeight: paraB ? 700 : 400, fontStyle: paraI ? "italic" : "normal", textDecoration: paraU ? "underline" : "none" }}>
                                            Paragraph
                                        </div>
                                        <FlexBox>
                                            <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                <label style={{ fontWeight: 900 }}>B</label>
                                                <SpacerRightSmall />
                                                <Switch
                                                    size='small'
                                                    checked={paraB}
                                                    onChange={() => handleParaStyleB(true)}
                                                />
                                            </FlexBox>
                                            <SpacerLeftRightSmall />
                                            <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                <label style={{ fontStyle: "italic" }}>I</label>
                                                <SpacerRightSmall />
                                                <Switch
                                                    size='small'
                                                    checked={paraI}
                                                    onChange={() => handleParaStyleI(false)}
                                                />
                                            </FlexBox>
                                            <SpacerLeftRightSmall />
                                            <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                <label style={{ textDecoration: "underline" }}>U</label>
                                                <SpacerRightSmall />
                                                <Switch
                                                    size='small'
                                                    checked={paraU}
                                                    onChange={() => handleParaStyleU(true)}
                                                />
                                            </FlexBox>
                                            <SpacerLeftRightMedium />
                                            {
                                                props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                    ?
                                                    <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                        <DeleteForeverIcon
                                                            color='error'
                                                            sx={{ cursor: "pointer" }}
                                                        />
                                                    </Tooltip>
                                                    :
                                                    props.index + 1 === props.contentBlock.length - 1
                                                    &&
                                                    <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                        <IndeterminateCheckBoxOutlinedIcon
                                                            fontSize='small'
                                                            color='error'
                                                            sx={{ cursor: "pointer" }}
                                                        />
                                                    </Tooltip>
                                            }
                                        </FlexBox>
                                    </FlexBoxSB>
                                    <SpacerTopBottomMedium />
                                    <FormTextArea
                                        rows={5}
                                        name="paraI"
                                        value={props.data?.paraI ? props.data?.paraI : props.contentBlock.paraI}
                                        onChange={props.onChangeString}
                                        style={{
                                            fontWeight: paraB ? 700 : 400, fontStyle: paraI ? "italic" : "normal", textDecoration: paraU ? "underline" : "none"
                                        }}
                                    />
                                </>
                                :
                                contentType === "paraU"
                                    ?
                                    <>
                                        <FlexBoxSB>
                                            <div style={{ fontWeight: paraB ? 700 : 400, fontStyle: paraI ? "italic" : "normal", textDecoration: paraU ? "underline" : "none" }}>
                                                Paragraph
                                            </div>
                                            <FlexBox>
                                                <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                    <label style={{ fontWeight: 900 }}>B</label>
                                                    <SpacerRightSmall />
                                                    <Switch
                                                        size='small'
                                                        checked={paraB}
                                                        onChange={() => handleParaStyleB(true)}
                                                    />
                                                </FlexBox>
                                                <SpacerLeftRightSmall />
                                                <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                    <label style={{ fontStyle: "italic" }}>I</label>
                                                    <SpacerRightSmall />
                                                    <Switch
                                                        size='small'
                                                        checked={paraI}
                                                        onChange={() => handleParaStyleI(true)}
                                                    />
                                                </FlexBox>
                                                <SpacerLeftRightSmall />
                                                <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                    <label style={{ textDecoration: "underline" }}>U</label>
                                                    <SpacerRightSmall />
                                                    <Switch
                                                        size='small'
                                                        checked={paraU}
                                                        onChange={() => handleParaStyleU(false)}
                                                    />
                                                </FlexBox>
                                                <SpacerLeftRightMedium />
                                                {
                                                    props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                        ?
                                                        <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                            <DeleteForeverIcon
                                                                color='error'
                                                                sx={{ cursor: "pointer" }}
                                                            />
                                                        </Tooltip>
                                                        :
                                                        props.index + 1 === props.contentBlock.length - 1
                                                        &&
                                                        <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                            <IndeterminateCheckBoxOutlinedIcon
                                                                fontSize='small'
                                                                color='error'
                                                                sx={{ cursor: "pointer" }}
                                                            />
                                                        </Tooltip>
                                                }
                                            </FlexBox>
                                        </FlexBoxSB>
                                        <SpacerTopBottomMedium />
                                        <FormTextArea
                                            rows={5}
                                            name="paraU"
                                            value={props.data?.paraU ? props.data?.paraU : props.contentBlock.paraU}
                                            onChange={props.onChangeString}
                                            style={{
                                                fontWeight: paraB ? 700 : 400, fontStyle: paraI ? "italic" : "normal", textDecoration: paraU ? "underline" : "none"
                                            }}
                                        />
                                    </>
                                    :
                                    contentType === "paraBI"
                                        ?
                                        <>
                                            <FlexBoxSB>
                                                <div style={{ fontWeight: paraB ? 700 : 400, fontStyle: paraI ? "italic" : "normal", textDecoration: paraU ? "underline" : "none" }}>
                                                    Paragraph
                                                </div>
                                                <FlexBox>
                                                    <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                        <label style={{ fontWeight: 900 }}>B</label>
                                                        <SpacerRightSmall />
                                                        <Switch
                                                            size='small'
                                                            checked={paraB}
                                                            onChange={() => handleParaStyleB(false)}
                                                        />
                                                    </FlexBox>
                                                    <SpacerLeftRightSmall />
                                                    <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                        <label style={{ fontStyle: "italic" }}>I</label>
                                                        <SpacerRightSmall />
                                                        <Switch
                                                            size='small'
                                                            checked={paraI}
                                                            onChange={() => handleParaStyleI(false)}
                                                        />
                                                    </FlexBox>
                                                    <SpacerLeftRightSmall />
                                                    <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                        <label style={{ textDecoration: "underline" }}>U</label>
                                                        <SpacerRightSmall />
                                                        <Switch
                                                            size='small'
                                                            checked={paraU}
                                                            onChange={() => handleParaStyleU(true)}
                                                        />
                                                    </FlexBox>
                                                    <SpacerLeftRightMedium />
                                                    {
                                                        props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                            ?
                                                            <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                <DeleteForeverIcon
                                                                    color='error'
                                                                    sx={{ cursor: "pointer" }}
                                                                />
                                                            </Tooltip>
                                                            :
                                                            props.index + 1 === props.contentBlock.length - 1
                                                            &&
                                                            <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                <IndeterminateCheckBoxOutlinedIcon
                                                                    fontSize='small'
                                                                    color='error'
                                                                    sx={{ cursor: "pointer" }}
                                                                />
                                                            </Tooltip>
                                                    }
                                                </FlexBox>
                                            </FlexBoxSB>
                                            <SpacerTopBottomMedium />
                                            <FormTextArea
                                                rows={5}
                                                name="paraBI"
                                                value={props.data?.paraBI ? props.data?.paraBI : props.contentBlock.paraBI}
                                                onChange={props.onChangeString}
                                                style={{
                                                    fontWeight: paraB ? 700 : 400, fontStyle: paraI ? "italic" : "normal", textDecoration: paraU ? "underline" : "none"
                                                }}
                                            />
                                        </>
                                        :
                                        contentType === "paraBU"
                                            ?
                                            <>
                                                <FlexBoxSB>
                                                    <div style={{ fontWeight: paraB ? 700 : 400, fontStyle: paraI ? "italic" : "normal", textDecoration: paraU ? "underline" : "none" }}>
                                                        Paragraph
                                                    </div>
                                                    <FlexBox>
                                                        <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                            <label style={{ fontWeight: 900 }}>B</label>
                                                            <SpacerRightSmall />
                                                            <Switch
                                                                size='small'
                                                                checked={paraB}
                                                                onChange={() => handleParaStyleB(false)}
                                                            />
                                                        </FlexBox>
                                                        <SpacerLeftRightSmall />
                                                        <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                            <label style={{ fontStyle: "italic" }}>I</label>
                                                            <SpacerRightSmall />
                                                            <Switch
                                                                size='small'
                                                                checked={paraI}
                                                                onChange={() => handleParaStyleI(true)}
                                                            />
                                                        </FlexBox>
                                                        <SpacerLeftRightSmall />
                                                        <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                            <label style={{ textDecoration: "underline" }}>U</label>
                                                            <SpacerRightSmall />
                                                            <Switch
                                                                size='small'
                                                                checked={paraU}
                                                                onChange={() => handleParaStyleU(false)}
                                                            />
                                                        </FlexBox>
                                                        <SpacerLeftRightMedium />
                                                        {
                                                            props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                ?
                                                                <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                    <DeleteForeverIcon
                                                                        color='error'
                                                                        sx={{ cursor: "pointer" }}
                                                                    />
                                                                </Tooltip>
                                                                :
                                                                props.index + 1 === props.contentBlock.length - 1
                                                                &&
                                                                <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                    <IndeterminateCheckBoxOutlinedIcon
                                                                        fontSize='small'
                                                                        color='error'
                                                                        sx={{ cursor: "pointer" }}
                                                                    />
                                                                </Tooltip>
                                                        }
                                                    </FlexBox>
                                                </FlexBoxSB>
                                                <SpacerTopBottomMedium />
                                                <FormTextArea
                                                    rows={5}
                                                    name="paraBU"
                                                    value={props.data?.paraBU ? props.data?.paraBU : props.contentBlock.paraBU}
                                                    onChange={props.onChangeString}
                                                    style={{
                                                        fontWeight: paraB ? 700 : 400, fontStyle: paraI ? "italic" : "normal", textDecoration: paraU ? "underline" : "none"
                                                    }}
                                                />
                                            </>
                                            :
                                            contentType === "paraIU"
                                                ?
                                                <>
                                                    <FlexBoxSB>
                                                        <div style={{ fontWeight: paraB ? 700 : 400, fontStyle: paraI ? "italic" : "normal", textDecoration: paraU ? "underline" : "none" }}>
                                                            Paragraph
                                                        </div>
                                                        <FlexBox>
                                                            <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                <label style={{ fontWeight: 900 }}>B</label>
                                                                <SpacerRightSmall />
                                                                <Switch
                                                                    size='small'
                                                                    checked={paraB}
                                                                    onChange={() => handleParaStyleB(true)}
                                                                />
                                                            </FlexBox>
                                                            <SpacerLeftRightSmall />
                                                            <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                <label style={{ fontStyle: "italic" }}>I</label>
                                                                <SpacerRightSmall />
                                                                <Switch
                                                                    size='small'
                                                                    checked={paraI}
                                                                    onChange={() => handleParaStyleI(false)}
                                                                />
                                                            </FlexBox>
                                                            <SpacerLeftRightSmall />
                                                            <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                <label style={{ textDecoration: "underline" }}>U</label>
                                                                <SpacerRightSmall />
                                                                <Switch
                                                                    size='small'
                                                                    checked={paraU}
                                                                    onChange={() => handleParaStyleU(false)}
                                                                />
                                                            </FlexBox>
                                                            <SpacerLeftRightMedium />
                                                            {
                                                                props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                    ?
                                                                    <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                        <DeleteForeverIcon
                                                                            color='error'
                                                                            sx={{ cursor: "pointer" }}
                                                                        />
                                                                    </Tooltip>
                                                                    :
                                                                    props.index + 1 === props.contentBlock.length - 1
                                                                    &&
                                                                    <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                        <IndeterminateCheckBoxOutlinedIcon
                                                                            fontSize='small'
                                                                            color='error'
                                                                            sx={{ cursor: "pointer" }}
                                                                        />
                                                                    </Tooltip>
                                                            }
                                                        </FlexBox>
                                                    </FlexBoxSB>
                                                    <SpacerTopBottomMedium />
                                                    <FormTextArea
                                                        rows={5}
                                                        name="paraIU"
                                                        value={props.data?.paraIU ? props.data?.paraIU : props.contentBlock.paraIU}
                                                        onChange={props.onChangeString}
                                                        style={{
                                                            fontWeight: paraB ? 700 : 400, fontStyle: paraI ? "italic" : "normal", textDecoration: paraU ? "underline" : "none"
                                                        }}
                                                    />
                                                </>
                                                :
                                                contentType === "paraBIU"
                                                    ?
                                                    <>
                                                        <FlexBoxSB>
                                                            <div style={{ fontWeight: paraB ? 700 : 400, fontStyle: paraI ? "italic" : "normal", textDecoration: paraU ? "underline" : "none" }}>
                                                                Paragraph
                                                            </div>
                                                            <FlexBox>
                                                                <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                    <label style={{ fontWeight: 900 }}>B</label>
                                                                    <SpacerRightSmall />
                                                                    <Switch
                                                                        size='small'
                                                                        checked={paraB}
                                                                        onChange={() => handleParaStyleB(false)}
                                                                    />
                                                                </FlexBox>
                                                                <SpacerLeftRightSmall />
                                                                <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                    <label style={{ fontStyle: "italic" }}>I</label>
                                                                    <SpacerRightSmall />
                                                                    <Switch
                                                                        size='small'
                                                                        checked={paraI}
                                                                        onChange={() => handleParaStyleI(false)}
                                                                    />
                                                                </FlexBox>
                                                                <SpacerLeftRightSmall />
                                                                <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                    <label style={{ textDecoration: "underline" }}>U</label>
                                                                    <SpacerRightSmall />
                                                                    <Switch
                                                                        size='small'
                                                                        checked={paraU}
                                                                        onChange={() => handleParaStyleU(false)}
                                                                    />
                                                                </FlexBox>
                                                                <SpacerLeftRightMedium />
                                                                {
                                                                    props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                        ?
                                                                        <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                            <DeleteForeverIcon
                                                                                color='error'
                                                                                sx={{ cursor: "pointer" }}
                                                                            />
                                                                        </Tooltip>
                                                                        :
                                                                        props.index + 1 === props.contentBlock.length - 1
                                                                        &&
                                                                        <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                            <IndeterminateCheckBoxOutlinedIcon
                                                                                fontSize='small'
                                                                                color='error'
                                                                                sx={{ cursor: "pointer" }}
                                                                            />
                                                                        </Tooltip>
                                                                }
                                                            </FlexBox>
                                                        </FlexBoxSB>
                                                        <SpacerTopBottomMedium />
                                                        <FormTextArea
                                                            rows={5}
                                                            name="paraBIU"
                                                            value={props.data?.paraBIU ? props.data?.paraBIU : props.contentBlock.paraBIU}
                                                            onChange={props.onChangeString}
                                                            style={{
                                                                fontWeight: paraB ? 700 : 400, fontStyle: paraI ? "italic" : "normal", textDecoration: paraU ? "underline" : "none"
                                                            }}
                                                        />
                                                    </>
                                                    :
                                                    contentType === "title"
                                                        ?
                                                        <>
                                                            <FlexBoxSB>
                                                                <div style={{ fontWeight: titleB ? 700 : 400, fontStyle: titleI ? "italic" : "normal", textDecoration: titleU ? "underline" : "none" }}>
                                                                    Title
                                                                </div>
                                                                <FlexBox>
                                                                    <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                        <label style={{ fontWeight: 900 }}>B</label>
                                                                        <SpacerRightSmall />
                                                                        <Switch
                                                                            size='small'
                                                                            checked={titleB}
                                                                            onChange={() => handleTitleStyleB(true)}
                                                                        />
                                                                    </FlexBox>
                                                                    <SpacerLeftRightSmall />
                                                                    <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                        <label style={{ fontStyle: "italic" }}>I</label>
                                                                        <SpacerRightSmall />
                                                                        <Switch
                                                                            size='small'
                                                                            checked={titleI}
                                                                            onChange={() => handleTitleStyleI(true)}
                                                                        />
                                                                    </FlexBox>
                                                                    <SpacerLeftRightSmall />
                                                                    <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                        <label style={{ textDecoration: "underline" }}>U</label>
                                                                        <SpacerRightSmall />
                                                                        <Switch
                                                                            size='small'
                                                                            checked={titleU}
                                                                            onChange={() => handleTitleStyleU(true)}
                                                                        />
                                                                    </FlexBox>
                                                                    <SpacerLeftRightMedium />
                                                                    {
                                                                        props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                            ?
                                                                            <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                <DeleteForeverIcon
                                                                                    color='error'
                                                                                    sx={{ cursor: "pointer" }}
                                                                                />
                                                                            </Tooltip>
                                                                            :
                                                                            props.index + 1 === props.contentBlock.length - 1
                                                                            &&
                                                                            <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                <IndeterminateCheckBoxOutlinedIcon
                                                                                    fontSize='small'
                                                                                    color='error'
                                                                                    sx={{ cursor: "pointer" }}
                                                                                />
                                                                            </Tooltip>
                                                                    }
                                                                </FlexBox>
                                                            </FlexBoxSB>
                                                            <SpacerTopBottomMedium />
                                                            <FormInput
                                                                name="title"
                                                                type='text'
                                                                value={props.data?.title ? props.data?.title : props.contentBlock.title}
                                                                onChange={props.onChangeString}
                                                                style={{
                                                                    fontWeight: titleB ? 700 : 400, fontStyle: titleI ? "italic" : "normal", textDecoration: titleU ? "underline" : "none"
                                                                }}
                                                            />
                                                        </>
                                                        :
                                                        contentType === "titleB"
                                                            ?
                                                            <>
                                                                <FlexBoxSB>
                                                                    <div style={{ fontWeight: titleB ? 700 : 400, fontStyle: titleI ? "italic" : "normal", textDecoration: titleU ? "underline" : "none" }}>
                                                                        Title
                                                                    </div>
                                                                    <FlexBox>
                                                                        <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                            <label style={{ fontWeight: 900 }}>B</label>
                                                                            <SpacerRightSmall />
                                                                            <Switch
                                                                                size='small'
                                                                                checked={titleB}
                                                                                onChange={() => handleTitleStyleB(false)}
                                                                            />
                                                                        </FlexBox>
                                                                        <SpacerLeftRightSmall />
                                                                        <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                            <label style={{ fontStyle: "italic" }}>I</label>
                                                                            <SpacerRightSmall />
                                                                            <Switch
                                                                                size='small'
                                                                                checked={titleI}
                                                                                onChange={() => handleTitleStyleI(true)}
                                                                            />
                                                                        </FlexBox>
                                                                        <SpacerLeftRightSmall />
                                                                        <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                            <label style={{ textDecoration: "underline" }}>U</label>
                                                                            <SpacerRightSmall />
                                                                            <Switch
                                                                                size='small'
                                                                                checked={titleU}
                                                                                onChange={() => handleTitleStyleU(true)}
                                                                            />
                                                                        </FlexBox>
                                                                        <SpacerLeftRightMedium />
                                                                        {
                                                                            props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                ?
                                                                                <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                    <DeleteForeverIcon
                                                                                        color='error'
                                                                                        sx={{ cursor: "pointer" }}
                                                                                    />
                                                                                </Tooltip>
                                                                                :
                                                                                props.index + 1 === props.contentBlock.length - 1
                                                                                &&
                                                                                <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                    <IndeterminateCheckBoxOutlinedIcon
                                                                                        fontSize='small'
                                                                                        color='error'
                                                                                        sx={{ cursor: "pointer" }}
                                                                                    />
                                                                                </Tooltip>
                                                                        }
                                                                    </FlexBox>
                                                                </FlexBoxSB>
                                                                <SpacerTopBottomMedium />
                                                                <FormInput
                                                                    name="titleB"
                                                                    type='text'
                                                                    value={props.data?.titleB ? props.data?.titleB : props.contentBlock.titleB}
                                                                    onChange={props.onChangeString}
                                                                    style={{
                                                                        fontWeight: titleB ? 700 : 400, fontStyle: titleI ? "italic" : "normal", textDecoration: titleU ? "underline" : "none"
                                                                    }}
                                                                />
                                                            </>
                                                            :
                                                            contentType === "titleI"
                                                                ?
                                                                <>
                                                                    <FlexBoxSB>
                                                                        <div style={{ fontWeight: titleB ? 700 : 400, fontStyle: titleI ? "italic" : "normal", textDecoration: titleU ? "underline" : "none" }}>
                                                                            Title
                                                                        </div>
                                                                        <FlexBox>
                                                                            <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                <label style={{ fontWeight: 900 }}>B</label>
                                                                                <SpacerRightSmall />
                                                                                <Switch
                                                                                    size='small'
                                                                                    checked={titleB}
                                                                                    onChange={() => handleTitleStyleB(true)}
                                                                                />
                                                                            </FlexBox>
                                                                            <SpacerLeftRightSmall />
                                                                            <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                <label style={{ fontStyle: "italic" }}>I</label>
                                                                                <SpacerRightSmall />
                                                                                <Switch
                                                                                    size='small'
                                                                                    checked={titleI}
                                                                                    onChange={() => handleTitleStyleI(false)}
                                                                                />
                                                                            </FlexBox>
                                                                            <SpacerLeftRightSmall />
                                                                            <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                <label style={{ textDecoration: "underline" }}>U</label>
                                                                                <SpacerRightSmall />
                                                                                <Switch
                                                                                    size='small'
                                                                                    checked={titleU}
                                                                                    onChange={() => handleTitleStyleU(true)}
                                                                                />
                                                                            </FlexBox>
                                                                            <SpacerLeftRightMedium />
                                                                            {
                                                                                props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                    ?
                                                                                    <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                        <DeleteForeverIcon
                                                                                            color='error'
                                                                                            sx={{ cursor: "pointer" }}
                                                                                        />
                                                                                    </Tooltip>
                                                                                    :
                                                                                    props.index + 1 === props.contentBlock.length - 1
                                                                                    &&
                                                                                    <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                        <IndeterminateCheckBoxOutlinedIcon
                                                                                            fontSize='small'
                                                                                            color='error'
                                                                                            sx={{ cursor: "pointer" }}
                                                                                        />
                                                                                    </Tooltip>
                                                                            }
                                                                        </FlexBox>
                                                                    </FlexBoxSB>
                                                                    <SpacerTopBottomMedium />
                                                                    <FormInput
                                                                        name="titleI"
                                                                        type='text'
                                                                        value={props.data?.titleI ? props.data?.titleI : props.contentBlock.titleI}
                                                                        onChange={props.onChangeString}
                                                                        style={{
                                                                            fontWeight: titleB ? 700 : 400, fontStyle: titleI ? "italic" : "normal", textDecoration: titleU ? "underline" : "none"
                                                                        }}
                                                                    />
                                                                </>
                                                                :
                                                                contentType === "titleU"
                                                                    ?
                                                                    <>
                                                                        <FlexBoxSB>
                                                                            <div style={{ fontWeight: titleB ? 700 : 400, fontStyle: titleI ? "italic" : "normal", textDecoration: titleU ? "underline" : "none" }}>
                                                                                Title
                                                                            </div>
                                                                            <FlexBox>
                                                                                <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                    <label style={{ fontWeight: 900 }}>B</label>
                                                                                    <SpacerRightSmall />
                                                                                    <Switch
                                                                                        size='small'
                                                                                        checked={titleB}
                                                                                        onChange={() => handleTitleStyleB(true)}
                                                                                    />
                                                                                </FlexBox>
                                                                                <SpacerLeftRightSmall />
                                                                                <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                    <label style={{ fontStyle: "italic" }}>I</label>
                                                                                    <SpacerRightSmall />
                                                                                    <Switch
                                                                                        size='small'
                                                                                        checked={titleI}
                                                                                        onChange={() => handleTitleStyleI(true)}
                                                                                    />
                                                                                </FlexBox>
                                                                                <SpacerLeftRightSmall />
                                                                                <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                    <label style={{ textDecoration: "underline" }}>U</label>
                                                                                    <SpacerRightSmall />
                                                                                    <Switch
                                                                                        size='small'
                                                                                        checked={titleU}
                                                                                        onChange={() => handleTitleStyleU(false)}
                                                                                    />
                                                                                </FlexBox>
                                                                                <SpacerLeftRightMedium />
                                                                                {
                                                                                    props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                        ?
                                                                                        <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                            <DeleteForeverIcon
                                                                                                color='error'
                                                                                                sx={{ cursor: "pointer" }}
                                                                                            />
                                                                                        </Tooltip>
                                                                                        :
                                                                                        props.index + 1 === props.contentBlock.length - 1
                                                                                        &&
                                                                                        <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                            <IndeterminateCheckBoxOutlinedIcon
                                                                                                fontSize='small'
                                                                                                color='error'
                                                                                                sx={{ cursor: "pointer" }}
                                                                                            />
                                                                                        </Tooltip>
                                                                                }
                                                                            </FlexBox>
                                                                        </FlexBoxSB>
                                                                        <SpacerTopBottomMedium />
                                                                        <FormInput
                                                                            name="titleU"
                                                                            type='text'
                                                                            value={props.data?.titleU ? props.data?.titleU : props.contentBlock.titleU}
                                                                            onChange={props.onChangeString}
                                                                            style={{
                                                                                fontWeight: titleB ? 700 : 400, fontStyle: titleI ? "italic" : "normal", textDecoration: titleU ? "underline" : "none"
                                                                            }}
                                                                        />
                                                                    </>
                                                                    :
                                                                    contentType === "titleBI"
                                                                        ?
                                                                        <>
                                                                            <FlexBoxSB>
                                                                                <div style={{ fontWeight: titleB ? 700 : 400, fontStyle: titleI ? "italic" : "normal", textDecoration: titleU ? "underline" : "none" }}>
                                                                                    Title
                                                                                </div>
                                                                                <FlexBox>
                                                                                    <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                        <label style={{ fontWeight: 900 }}>B</label>
                                                                                        <SpacerRightSmall />
                                                                                        <Switch
                                                                                            size='small'
                                                                                            checked={titleB}
                                                                                            onChange={() => handleTitleStyleB(false)}
                                                                                        />
                                                                                    </FlexBox>
                                                                                    <SpacerLeftRightSmall />
                                                                                    <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                        <label style={{ fontStyle: "italic" }}>I</label>
                                                                                        <SpacerRightSmall />
                                                                                        <Switch
                                                                                            size='small'
                                                                                            checked={titleI}
                                                                                            onChange={() => handleTitleStyleI(false)}
                                                                                        />
                                                                                    </FlexBox>
                                                                                    <SpacerLeftRightSmall />
                                                                                    <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                        <label style={{ textDecoration: "underline" }}>U</label>
                                                                                        <SpacerRightSmall />
                                                                                        <Switch
                                                                                            size='small'
                                                                                            checked={titleU}
                                                                                            onChange={() => handleTitleStyleU(true)}
                                                                                        />
                                                                                    </FlexBox>
                                                                                    <SpacerLeftRightMedium />
                                                                                    {
                                                                                        props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                            ?
                                                                                            <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                                <DeleteForeverIcon
                                                                                                    color='error'
                                                                                                    sx={{ cursor: "pointer" }}
                                                                                                />
                                                                                            </Tooltip>
                                                                                            :
                                                                                            props.index + 1 === props.contentBlock.length - 1
                                                                                            &&
                                                                                            <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                                <IndeterminateCheckBoxOutlinedIcon
                                                                                                    fontSize='small'
                                                                                                    color='error'
                                                                                                    sx={{ cursor: "pointer" }}
                                                                                                />
                                                                                            </Tooltip>
                                                                                    }
                                                                                </FlexBox>
                                                                            </FlexBoxSB>
                                                                            <SpacerTopBottomMedium />
                                                                            <FormInput
                                                                                name="titleBI"
                                                                                type='text'
                                                                                value={props.data?.titleBI ? props.data?.titleBI : props.contentBlock.titleBI}
                                                                                onChange={props.onChangeString}
                                                                                style={{
                                                                                    fontWeight: titleB ? 700 : 400, fontStyle: titleI ? "italic" : "normal", textDecoration: titleU ? "underline" : "none"
                                                                                }}
                                                                            />
                                                                        </>
                                                                        :
                                                                        contentType === "titleBU"
                                                                            ?
                                                                            <>
                                                                                <FlexBoxSB>
                                                                                    <div style={{ fontWeight: titleB ? 700 : 400, fontStyle: titleI ? "italic" : "normal", textDecoration: titleU ? "underline" : "none" }}>
                                                                                        Title
                                                                                    </div>
                                                                                    <FlexBox>
                                                                                        <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                            <label style={{ fontWeight: 900 }}>B</label>
                                                                                            <SpacerRightSmall />
                                                                                            <Switch
                                                                                                size='small'
                                                                                                checked={titleB}
                                                                                                onChange={() => handleTitleStyleB(false)}
                                                                                            />
                                                                                        </FlexBox>
                                                                                        <SpacerLeftRightSmall />
                                                                                        <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                            <label style={{ fontStyle: "italic" }}>I</label>
                                                                                            <SpacerRightSmall />
                                                                                            <Switch
                                                                                                size='small'
                                                                                                checked={titleI}
                                                                                                onChange={() => handleTitleStyleI(true)}
                                                                                            />
                                                                                        </FlexBox>
                                                                                        <SpacerLeftRightSmall />
                                                                                        <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                            <label style={{ textDecoration: "underline" }}>U</label>
                                                                                            <SpacerRightSmall />
                                                                                            <Switch
                                                                                                size='small'
                                                                                                checked={titleU}
                                                                                                onChange={() => handleTitleStyleU(false)}
                                                                                            />
                                                                                        </FlexBox>
                                                                                        <SpacerLeftRightMedium />
                                                                                        {
                                                                                            props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                                ?
                                                                                                <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                                    <DeleteForeverIcon
                                                                                                        color='error'
                                                                                                        sx={{ cursor: "pointer" }}
                                                                                                    />
                                                                                                </Tooltip>
                                                                                                :
                                                                                                props.index + 1 === props.contentBlock.length - 1
                                                                                                &&
                                                                                                <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                                    <IndeterminateCheckBoxOutlinedIcon
                                                                                                        fontSize='small'
                                                                                                        color='error'
                                                                                                        sx={{ cursor: "pointer" }}
                                                                                                    />
                                                                                                </Tooltip>
                                                                                        }
                                                                                    </FlexBox>
                                                                                </FlexBoxSB>
                                                                                <SpacerTopBottomMedium />
                                                                                <FormInput
                                                                                    name="titleBU"
                                                                                    type='text'
                                                                                    value={props.data?.titleBU ? props.data?.titleBU : props.contentBlock.titleBU}
                                                                                    onChange={props.onChangeString}
                                                                                    style={{
                                                                                        fontWeight: titleB ? 700 : 400, fontStyle: titleI ? "italic" : "normal", textDecoration: titleU ? "underline" : "none"
                                                                                    }}
                                                                                />
                                                                            </>
                                                                            :
                                                                            contentType === "titleIU"
                                                                                ?
                                                                                <>
                                                                                    <FlexBoxSB>
                                                                                        <div style={{ fontWeight: titleB ? 700 : 400, fontStyle: titleI ? "italic" : "normal", textDecoration: titleU ? "underline" : "none" }}>
                                                                                            Title
                                                                                        </div>
                                                                                        <FlexBox>
                                                                                            <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                <label style={{ fontWeight: 900 }}>B</label>
                                                                                                <SpacerRightSmall />
                                                                                                <Switch
                                                                                                    size='small'
                                                                                                    checked={titleB}
                                                                                                    onChange={() => handleTitleStyleB(true)}
                                                                                                />
                                                                                            </FlexBox>
                                                                                            <SpacerLeftRightSmall />
                                                                                            <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                <label style={{ fontStyle: "italic" }}>I</label>
                                                                                                <SpacerRightSmall />
                                                                                                <Switch
                                                                                                    size='small'
                                                                                                    checked={titleI}
                                                                                                    onChange={() => handleTitleStyleI(false)}
                                                                                                />
                                                                                            </FlexBox>
                                                                                            <SpacerLeftRightSmall />
                                                                                            <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                <label style={{ textDecoration: "underline" }}>U</label>
                                                                                                <SpacerRightSmall />
                                                                                                <Switch
                                                                                                    size='small'
                                                                                                    checked={titleU}
                                                                                                    onChange={() => handleTitleStyleU(false)}
                                                                                                />
                                                                                            </FlexBox>
                                                                                            <SpacerLeftRightMedium />
                                                                                            {
                                                                                                props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                                    ?
                                                                                                    <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                                        <DeleteForeverIcon
                                                                                                            color='error'
                                                                                                            sx={{ cursor: "pointer" }}
                                                                                                        />
                                                                                                    </Tooltip>
                                                                                                    :
                                                                                                    props.index + 1 === props.contentBlock.length - 1
                                                                                                    &&
                                                                                                    <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                                        <IndeterminateCheckBoxOutlinedIcon
                                                                                                            fontSize='small'
                                                                                                            color='error'
                                                                                                            sx={{ cursor: "pointer" }}
                                                                                                        />
                                                                                                    </Tooltip>
                                                                                            }
                                                                                        </FlexBox>
                                                                                    </FlexBoxSB>
                                                                                    <SpacerTopBottomMedium />
                                                                                    <FormInput
                                                                                        name="titleIU"
                                                                                        type='text'
                                                                                        value={props.data?.titleIU ? props.data?.titleIU : props.contentBlock.titleIU}
                                                                                        onChange={props.onChangeString}
                                                                                        style={{
                                                                                            fontWeight: titleB ? 700 : 400, fontStyle: titleI ? "italic" : "normal", textDecoration: titleU ? "underline" : "none"
                                                                                        }}
                                                                                    />
                                                                                </>
                                                                                :
                                                                                contentType === "titleBIU"
                                                                                    ?
                                                                                    <>
                                                                                        <FlexBoxSB>
                                                                                            <div style={{ fontWeight: titleB ? 700 : 400, fontStyle: titleI ? "italic" : "normal", textDecoration: titleU ? "underline" : "none" }}>
                                                                                                Title
                                                                                            </div>
                                                                                            <FlexBox>
                                                                                                <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                    <label style={{ fontWeight: 900 }}>B</label>
                                                                                                    <SpacerRightSmall />
                                                                                                    <Switch
                                                                                                        size='small'
                                                                                                        checked={titleB}
                                                                                                        onChange={() => handleTitleStyleB(false)}
                                                                                                    />
                                                                                                </FlexBox>
                                                                                                <SpacerLeftRightSmall />
                                                                                                <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                    <label style={{ fontStyle: "italic" }}>I</label>
                                                                                                    <SpacerRightSmall />
                                                                                                    <Switch
                                                                                                        size='small'
                                                                                                        checked={titleI}
                                                                                                        onChange={() => handleTitleStyleI(false)}
                                                                                                    />
                                                                                                </FlexBox>
                                                                                                <SpacerLeftRightSmall />
                                                                                                <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                    <label style={{ textDecoration: "underline" }}>U</label>
                                                                                                    <SpacerRightSmall />
                                                                                                    <Switch
                                                                                                        size='small'
                                                                                                        checked={titleU}
                                                                                                        onChange={() => handleTitleStyleU(false)}
                                                                                                    />
                                                                                                </FlexBox>
                                                                                                <SpacerLeftRightMedium />
                                                                                                {
                                                                                                    props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                                        ?
                                                                                                        <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                                            <DeleteForeverIcon
                                                                                                                color='error'
                                                                                                                sx={{ cursor: "pointer" }}
                                                                                                            />
                                                                                                        </Tooltip>
                                                                                                        :
                                                                                                        props.index + 1 === props.contentBlock.length - 1
                                                                                                        &&
                                                                                                        <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                                            <IndeterminateCheckBoxOutlinedIcon
                                                                                                                fontSize='small'
                                                                                                                color='error'
                                                                                                                sx={{ cursor: "pointer" }}
                                                                                                            />
                                                                                                        </Tooltip>
                                                                                                }
                                                                                            </FlexBox>
                                                                                        </FlexBoxSB>
                                                                                        <SpacerTopBottomMedium />
                                                                                        <FormInput
                                                                                            name="titleBIU"
                                                                                            type='text'
                                                                                            value={props.data?.titleBIU ? props.data?.titleBIU : props.contentBlock.titleBIU}
                                                                                            onChange={props.onChangeString}
                                                                                            style={{
                                                                                                fontWeight: titleB ? 700 : 400, fontStyle: titleI ? "italic" : "normal", textDecoration: titleU ? "underline" : "none"
                                                                                            }}
                                                                                        />
                                                                                    </>
                                                                                    :
                                                                                    contentType === "subTitle"
                                                                                        ?
                                                                                        <>
                                                                                            <FlexBoxSB>
                                                                                                <div style={{ fontWeight: subTitleB ? 700 : 400, fontStyle: subTitleI ? "italic" : "normal", textDecoration: subTitleU ? "underline" : "none" }}>
                                                                                                    Sub-Title
                                                                                                </div>
                                                                                                <FlexBox>
                                                                                                    <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                        <label style={{ fontWeight: 900 }}>B</label>
                                                                                                        <SpacerRightSmall />
                                                                                                        <Switch
                                                                                                            size='small'
                                                                                                            checked={subTitleB}
                                                                                                            onChange={() => handleSubTitleStyleB(true)}
                                                                                                        />
                                                                                                    </FlexBox>
                                                                                                    <SpacerLeftRightSmall />
                                                                                                    <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                        <label style={{ fontStyle: "italic" }}>I</label>
                                                                                                        <SpacerRightSmall />
                                                                                                        <Switch
                                                                                                            size='small'
                                                                                                            checked={subTitleI}
                                                                                                            onChange={() => handleSubTitleStyleI(true)}
                                                                                                        />
                                                                                                    </FlexBox>
                                                                                                    <SpacerLeftRightSmall />
                                                                                                    <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                        <label style={{ textDecoration: "underline" }}>U</label>
                                                                                                        <SpacerRightSmall />
                                                                                                        <Switch
                                                                                                            size='small'
                                                                                                            checked={subTitleU}
                                                                                                            onChange={() => handleSubTitleStyleU(true)}
                                                                                                        />
                                                                                                    </FlexBox>
                                                                                                    <SpacerLeftRightMedium />
                                                                                                    {
                                                                                                        props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                                            ?
                                                                                                            <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                                                <DeleteForeverIcon
                                                                                                                    color='error'
                                                                                                                    sx={{ cursor: "pointer" }}
                                                                                                                />
                                                                                                            </Tooltip>
                                                                                                            :
                                                                                                            props.index + 1 === props.contentBlock.length - 1
                                                                                                            &&
                                                                                                            <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                                                <IndeterminateCheckBoxOutlinedIcon
                                                                                                                    fontSize='small'
                                                                                                                    color='error'
                                                                                                                    sx={{ cursor: "pointer" }}
                                                                                                                />
                                                                                                            </Tooltip>
                                                                                                    }
                                                                                                </FlexBox>
                                                                                            </FlexBoxSB>
                                                                                            <SpacerTopBottomMedium />
                                                                                            <FormInput
                                                                                                name="subTitle"
                                                                                                type='text'
                                                                                                value={props.data?.subTitle ? props.data?.subTitle : props.contentBlock.subTitle}
                                                                                                onChange={props.onChangeString}
                                                                                                style={{
                                                                                                    fontWeight: subTitleB ? 700 : 400, fontStyle: subTitleI ? "italic" : "normal", textDecoration: subTitleU ? "underline" : "none"
                                                                                                }}
                                                                                            />
                                                                                        </>
                                                                                        :
                                                                                        contentType === "subTitleB"
                                                                                            ?
                                                                                            <>
                                                                                                <FlexBoxSB>
                                                                                                    <div style={{ fontWeight: subTitleB ? 700 : 400, fontStyle: subTitleI ? "italic" : "normal", textDecoration: subTitleU ? "underline" : "none" }}>
                                                                                                        Sub-Title
                                                                                                    </div>
                                                                                                    <FlexBox>
                                                                                                        <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                            <label style={{ fontWeight: 900 }}>B</label>
                                                                                                            <SpacerRightSmall />
                                                                                                            <Switch
                                                                                                                size='small'
                                                                                                                checked={subTitleB}
                                                                                                                onChange={() => handleSubTitleStyleB(false)}
                                                                                                            />
                                                                                                        </FlexBox>
                                                                                                        <SpacerLeftRightSmall />
                                                                                                        <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                            <label style={{ fontStyle: "italic" }}>I</label>
                                                                                                            <SpacerRightSmall />
                                                                                                            <Switch
                                                                                                                size='small'
                                                                                                                checked={subTitleI}
                                                                                                                onChange={() => handleSubTitleStyleI(true)}
                                                                                                            />
                                                                                                        </FlexBox>
                                                                                                        <SpacerLeftRightSmall />
                                                                                                        <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                            <label style={{ textDecoration: "underline" }}>U</label>
                                                                                                            <SpacerRightSmall />
                                                                                                            <Switch
                                                                                                                size='small'
                                                                                                                checked={subTitleU}
                                                                                                                onChange={() => handleSubTitleStyleU(true)}
                                                                                                            />
                                                                                                        </FlexBox>
                                                                                                        <SpacerLeftRightMedium />
                                                                                                        {
                                                                                                            props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                                                ?
                                                                                                                <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                                                    <DeleteForeverIcon
                                                                                                                        color='error'
                                                                                                                        sx={{ cursor: "pointer" }}
                                                                                                                    />
                                                                                                                </Tooltip>
                                                                                                                :
                                                                                                                props.index + 1 === props.contentBlock.length - 1
                                                                                                                &&
                                                                                                                <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                                                    <IndeterminateCheckBoxOutlinedIcon
                                                                                                                        fontSize='small'
                                                                                                                        color='error'
                                                                                                                        sx={{ cursor: "pointer" }}
                                                                                                                    />
                                                                                                                </Tooltip>
                                                                                                        }
                                                                                                    </FlexBox>
                                                                                                </FlexBoxSB>
                                                                                                <SpacerTopBottomMedium />
                                                                                                <FormInput
                                                                                                    name="subTitleB"
                                                                                                    type='text'
                                                                                                    value={props.data?.subTitleB ? props.data?.subTitleB : props.contentBlock.subTitleB}
                                                                                                    onChange={props.onChangeString}
                                                                                                    style={{
                                                                                                        fontWeight: subTitleB ? 700 : 400, fontStyle: subTitleI ? "italic" : "normal", textDecoration: subTitleU ? "underline" : "none"
                                                                                                    }}
                                                                                                />
                                                                                            </>
                                                                                            :
                                                                                            contentType === "subTitleI"
                                                                                                ?
                                                                                                <>
                                                                                                    <FlexBoxSB>
                                                                                                        <div style={{ fontWeight: subTitleB ? 700 : 400, fontStyle: subTitleI ? "italic" : "normal", textDecoration: subTitleU ? "underline" : "none" }}>
                                                                                                            Sub-Title
                                                                                                        </div>
                                                                                                        <FlexBox>
                                                                                                            <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                                <label style={{ fontWeight: 900 }}>B</label>
                                                                                                                <SpacerRightSmall />
                                                                                                                <Switch
                                                                                                                    size='small'
                                                                                                                    checked={subTitleB}
                                                                                                                    onChange={() => handleSubTitleStyleB(true)}
                                                                                                                />
                                                                                                            </FlexBox>
                                                                                                            <SpacerLeftRightSmall />
                                                                                                            <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                                <label style={{ fontStyle: "italic" }}>I</label>
                                                                                                                <SpacerRightSmall />
                                                                                                                <Switch
                                                                                                                    size='small'
                                                                                                                    checked={subTitleI}
                                                                                                                    onChange={() => handleSubTitleStyleI(false)}
                                                                                                                />
                                                                                                            </FlexBox>
                                                                                                            <SpacerLeftRightSmall />
                                                                                                            <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                                <label style={{ textDecoration: "underline" }}>U</label>
                                                                                                                <SpacerRightSmall />
                                                                                                                <Switch
                                                                                                                    size='small'
                                                                                                                    checked={subTitleU}
                                                                                                                    onChange={() => handleSubTitleStyleU(true)}
                                                                                                                />
                                                                                                            </FlexBox>
                                                                                                            <SpacerLeftRightMedium />
                                                                                                            {
                                                                                                                props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                                                    ?
                                                                                                                    <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                                                        <DeleteForeverIcon
                                                                                                                            color='error'
                                                                                                                            sx={{ cursor: "pointer" }}
                                                                                                                        />
                                                                                                                    </Tooltip>
                                                                                                                    :
                                                                                                                    props.index + 1 === props.contentBlock.length - 1
                                                                                                                    &&
                                                                                                                    <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                                                        <IndeterminateCheckBoxOutlinedIcon
                                                                                                                            fontSize='small'
                                                                                                                            color='error'
                                                                                                                            sx={{ cursor: "pointer" }}
                                                                                                                        />
                                                                                                                    </Tooltip>
                                                                                                            }
                                                                                                        </FlexBox>
                                                                                                    </FlexBoxSB>
                                                                                                    <SpacerTopBottomMedium />
                                                                                                    <FormInput
                                                                                                        name="subTitleI"
                                                                                                        type='text'
                                                                                                        value={props.data?.subTitleI ? props.data?.subTitleI : props.contentBlock.subTitleI}
                                                                                                        onChange={props.onChangeString}
                                                                                                        style={{
                                                                                                            fontWeight: subTitleB ? 700 : 400, fontStyle: subTitleI ? "italic" : "normal", textDecoration: subTitleU ? "underline" : "none"
                                                                                                        }}
                                                                                                    />
                                                                                                </>
                                                                                                :
                                                                                                contentType === "subTitleU"
                                                                                                    ?
                                                                                                    <>
                                                                                                        <FlexBoxSB>
                                                                                                            <div style={{ fontWeight: subTitleB ? 700 : 400, fontStyle: subTitleI ? "italic" : "normal", textDecoration: subTitleU ? "underline" : "none" }}>
                                                                                                                Sub-Title
                                                                                                            </div>
                                                                                                            <FlexBox>
                                                                                                                <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                                    <label style={{ fontWeight: 900 }}>B</label>
                                                                                                                    <SpacerRightSmall />
                                                                                                                    <Switch
                                                                                                                        size='small'
                                                                                                                        checked={subTitleB}
                                                                                                                        onChange={() => handleSubTitleStyleB(true)}
                                                                                                                    />
                                                                                                                </FlexBox>
                                                                                                                <SpacerLeftRightSmall />
                                                                                                                <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                                    <label style={{ fontStyle: "italic" }}>I</label>
                                                                                                                    <SpacerRightSmall />
                                                                                                                    <Switch
                                                                                                                        size='small'
                                                                                                                        checked={subTitleI}
                                                                                                                        onChange={() => handleSubTitleStyleI(true)}
                                                                                                                    />
                                                                                                                </FlexBox>
                                                                                                                <SpacerLeftRightSmall />
                                                                                                                <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                                    <label style={{ textDecoration: "underline" }}>U</label>
                                                                                                                    <SpacerRightSmall />
                                                                                                                    <Switch
                                                                                                                        size='small'
                                                                                                                        checked={subTitleU}
                                                                                                                        onChange={() => handleSubTitleStyleU(false)}
                                                                                                                    />
                                                                                                                </FlexBox>
                                                                                                                <SpacerLeftRightMedium />
                                                                                                                {
                                                                                                                    props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                                                        ?
                                                                                                                        <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                                                            <DeleteForeverIcon
                                                                                                                                color='error'
                                                                                                                                sx={{ cursor: "pointer" }}
                                                                                                                            />
                                                                                                                        </Tooltip>
                                                                                                                        :
                                                                                                                        props.index + 1 === props.contentBlock.length - 1
                                                                                                                        &&
                                                                                                                        <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                                                            <IndeterminateCheckBoxOutlinedIcon
                                                                                                                                fontSize='small'
                                                                                                                                color='error'
                                                                                                                                sx={{ cursor: "pointer" }}
                                                                                                                            />
                                                                                                                        </Tooltip>
                                                                                                                }
                                                                                                            </FlexBox>
                                                                                                        </FlexBoxSB>
                                                                                                        <SpacerTopBottomMedium />
                                                                                                        <FormInput
                                                                                                            name="subTitleU"
                                                                                                            type='text'
                                                                                                            value={props.data?.subTitleU ? props.data?.subTitleU : props.contentBlock.subTitleU}
                                                                                                            onChange={props.onChangeString}
                                                                                                            style={{
                                                                                                                fontWeight: subTitleB ? 700 : 400, fontStyle: subTitleI ? "italic" : "normal", textDecoration: subTitleU ? "underline" : "none"
                                                                                                            }}
                                                                                                        />
                                                                                                    </>
                                                                                                    :
                                                                                                    contentType === "subTitleBI"
                                                                                                        ?
                                                                                                        <>
                                                                                                            <FlexBoxSB>
                                                                                                                <div style={{ fontWeight: subTitleB ? 700 : 400, fontStyle: subTitleI ? "italic" : "normal", textDecoration: subTitleU ? "underline" : "none" }}>
                                                                                                                    Sub-Title
                                                                                                                </div>
                                                                                                                <FlexBox>
                                                                                                                    <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                                        <label style={{ fontWeight: 900 }}>B</label>
                                                                                                                        <SpacerRightSmall />
                                                                                                                        <Switch
                                                                                                                            size='small'
                                                                                                                            checked={subTitleB}
                                                                                                                            onChange={() => handleSubTitleStyleB(false)}
                                                                                                                        />
                                                                                                                    </FlexBox>
                                                                                                                    <SpacerLeftRightSmall />
                                                                                                                    <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                                        <label style={{ fontStyle: "italic" }}>I</label>
                                                                                                                        <SpacerRightSmall />
                                                                                                                        <Switch
                                                                                                                            size='small'
                                                                                                                            checked={subTitleI}
                                                                                                                            onChange={() => handleSubTitleStyleI(false)}
                                                                                                                        />
                                                                                                                    </FlexBox>
                                                                                                                    <SpacerLeftRightSmall />
                                                                                                                    <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                                        <label style={{ textDecoration: "underline" }}>U</label>
                                                                                                                        <SpacerRightSmall />
                                                                                                                        <Switch
                                                                                                                            size='small'
                                                                                                                            checked={subTitleU}
                                                                                                                            onChange={() => handleSubTitleStyleU(true)}
                                                                                                                        />
                                                                                                                    </FlexBox>
                                                                                                                    <SpacerLeftRightMedium />
                                                                                                                    {
                                                                                                                        props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                                                            ?
                                                                                                                            <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                                                                <DeleteForeverIcon
                                                                                                                                    color='error'
                                                                                                                                    sx={{ cursor: "pointer" }}
                                                                                                                                />
                                                                                                                            </Tooltip>
                                                                                                                            :
                                                                                                                            props.index + 1 === props.contentBlock.length - 1
                                                                                                                            &&
                                                                                                                            <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                                                                <IndeterminateCheckBoxOutlinedIcon
                                                                                                                                    fontSize='small'
                                                                                                                                    color='error'
                                                                                                                                    sx={{ cursor: "pointer" }}
                                                                                                                                />
                                                                                                                            </Tooltip>
                                                                                                                    }
                                                                                                                </FlexBox>
                                                                                                            </FlexBoxSB>
                                                                                                            <SpacerTopBottomMedium />
                                                                                                            <FormInput
                                                                                                                name="subTitleBI"
                                                                                                                type='text'
                                                                                                                value={props.data?.subTitleBI ? props.data?.subTitleBI : props.contentBlock.subTitleBI}
                                                                                                                onChange={props.onChangeString}
                                                                                                                style={{
                                                                                                                    fontWeight: subTitleB ? 700 : 400, fontStyle: subTitleI ? "italic" : "normal", textDecoration: subTitleU ? "underline" : "none"
                                                                                                                }}
                                                                                                            />
                                                                                                        </>
                                                                                                        :
                                                                                                        contentType === "subTitleBU"
                                                                                                            ?
                                                                                                            <>
                                                                                                                <FlexBoxSB>
                                                                                                                    <div style={{ fontWeight: subTitleB ? 700 : 400, fontStyle: subTitleI ? "italic" : "normal", textDecoration: subTitleU ? "underline" : "none" }}>
                                                                                                                        Sub-Title
                                                                                                                    </div>
                                                                                                                    <FlexBox>
                                                                                                                        <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                                            <label style={{ fontWeight: 900 }}>B</label>
                                                                                                                            <SpacerRightSmall />
                                                                                                                            <Switch
                                                                                                                                size='small'
                                                                                                                                checked={subTitleB}
                                                                                                                                onChange={() => handleSubTitleStyleB(false)}
                                                                                                                            />
                                                                                                                        </FlexBox>
                                                                                                                        <SpacerLeftRightSmall />
                                                                                                                        <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                                            <label style={{ fontStyle: "italic" }}>I</label>
                                                                                                                            <SpacerRightSmall />
                                                                                                                            <Switch
                                                                                                                                size='small'
                                                                                                                                checked={subTitleI}
                                                                                                                                onChange={() => handleSubTitleStyleI(true)}
                                                                                                                            />
                                                                                                                        </FlexBox>
                                                                                                                        <SpacerLeftRightSmall />
                                                                                                                        <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                                            <label style={{ textDecoration: "underline" }}>U</label>
                                                                                                                            <SpacerRightSmall />
                                                                                                                            <Switch
                                                                                                                                size='small'
                                                                                                                                checked={subTitleU}
                                                                                                                                onChange={() => handleSubTitleStyleU(false)}
                                                                                                                            />
                                                                                                                        </FlexBox>
                                                                                                                        <SpacerLeftRightMedium />
                                                                                                                        {
                                                                                                                            props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                                                                ?
                                                                                                                                <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                                                                    <DeleteForeverIcon
                                                                                                                                        color='error'
                                                                                                                                        sx={{ cursor: "pointer" }}
                                                                                                                                    />
                                                                                                                                </Tooltip>
                                                                                                                                :
                                                                                                                                props.index + 1 === props.contentBlock.length - 1
                                                                                                                                &&
                                                                                                                                <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                                                                    <IndeterminateCheckBoxOutlinedIcon
                                                                                                                                        fontSize='small'
                                                                                                                                        color='error'
                                                                                                                                        sx={{ cursor: "pointer" }}
                                                                                                                                    />
                                                                                                                                </Tooltip>
                                                                                                                        }
                                                                                                                    </FlexBox>
                                                                                                                </FlexBoxSB>
                                                                                                                <SpacerTopBottomMedium />
                                                                                                                <FormInput
                                                                                                                    name="subTitleBU"
                                                                                                                    type='text'
                                                                                                                    value={props.data?.subTitleBU ? props.data?.subTitleBU : props.contentBlock.subTitleBU}
                                                                                                                    onChange={props.onChangeString}
                                                                                                                    style={{
                                                                                                                        fontWeight: subTitleB ? 700 : 400, fontStyle: subTitleI ? "italic" : "normal", textDecoration: subTitleU ? "underline" : "none"
                                                                                                                    }}
                                                                                                                />
                                                                                                            </>
                                                                                                            :
                                                                                                            contentType === "subTitleIU"
                                                                                                                ?
                                                                                                                <>
                                                                                                                    <FlexBoxSB>
                                                                                                                        <div style={{ fontWeight: subTitleB ? 700 : 400, fontStyle: subTitleI ? "italic" : "normal", textDecoration: subTitleU ? "underline" : "none" }}>
                                                                                                                            Sub-Title
                                                                                                                        </div>
                                                                                                                        <FlexBox>
                                                                                                                            <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                                                <label style={{ fontWeight: 900 }}>B</label>
                                                                                                                                <SpacerRightSmall />
                                                                                                                                <Switch
                                                                                                                                    size='small'
                                                                                                                                    checked={subTitleB}
                                                                                                                                    onChange={() => handleSubTitleStyleB(true)}
                                                                                                                                />
                                                                                                                            </FlexBox>
                                                                                                                            <SpacerLeftRightSmall />
                                                                                                                            <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                                                <label style={{ fontStyle: "italic" }}>I</label>
                                                                                                                                <SpacerRightSmall />
                                                                                                                                <Switch
                                                                                                                                    size='small'
                                                                                                                                    checked={subTitleI}
                                                                                                                                    onChange={() => handleSubTitleStyleI(false)}
                                                                                                                                />
                                                                                                                            </FlexBox>
                                                                                                                            <SpacerLeftRightSmall />
                                                                                                                            <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                                                <label style={{ textDecoration: "underline" }}>U</label>
                                                                                                                                <SpacerRightSmall />
                                                                                                                                <Switch
                                                                                                                                    size='small'
                                                                                                                                    checked={subTitleU}
                                                                                                                                    onChange={() => handleSubTitleStyleU(false)}
                                                                                                                                />
                                                                                                                            </FlexBox>
                                                                                                                            <SpacerLeftRightMedium />
                                                                                                                            {
                                                                                                                                props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                                                                    ?
                                                                                                                                    <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                                                                        <DeleteForeverIcon
                                                                                                                                            color='error'
                                                                                                                                            sx={{ cursor: "pointer" }}
                                                                                                                                        />
                                                                                                                                    </Tooltip>
                                                                                                                                    :
                                                                                                                                    props.index + 1 === props.contentBlock.length - 1
                                                                                                                                    &&
                                                                                                                                    <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                                                                        <IndeterminateCheckBoxOutlinedIcon
                                                                                                                                            fontSize='small'
                                                                                                                                            color='error'
                                                                                                                                            sx={{ cursor: "pointer" }}
                                                                                                                                        />
                                                                                                                                    </Tooltip>
                                                                                                                            }
                                                                                                                        </FlexBox>
                                                                                                                    </FlexBoxSB>
                                                                                                                    <SpacerTopBottomMedium />
                                                                                                                    <FormInput
                                                                                                                        name="subTitleIU"
                                                                                                                        type='text'
                                                                                                                        value={props.data?.subTitleIU ? props.data?.subTitleIU : props.contentBlock.subTitleIU}
                                                                                                                        onChange={props.onChangeString}
                                                                                                                        style={{
                                                                                                                            fontWeight: subTitleB ? 700 : 400, fontStyle: subTitleI ? "italic" : "normal", textDecoration: subTitleU ? "underline" : "none"
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </>
                                                                                                                :
                                                                                                                contentType === "subTitleBIU"
                                                                                                                    ?
                                                                                                                    <>
                                                                                                                        <FlexBoxSB>
                                                                                                                            <div style={{ fontWeight: subTitleB ? 700 : 400, fontStyle: subTitleI ? "italic" : "normal", textDecoration: subTitleU ? "underline" : "none" }}>
                                                                                                                                Sub-Title
                                                                                                                            </div>
                                                                                                                            <FlexBox>
                                                                                                                                <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                                                    <label style={{ fontWeight: 900 }}>B</label>
                                                                                                                                    <SpacerRightSmall />
                                                                                                                                    <Switch
                                                                                                                                        size='small'
                                                                                                                                        checked={subTitleB}
                                                                                                                                        onChange={() => handleSubTitleStyleB(false)}
                                                                                                                                    />
                                                                                                                                </FlexBox>
                                                                                                                                <SpacerLeftRightSmall />
                                                                                                                                <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                                                    <label style={{ fontStyle: "italic" }}>I</label>
                                                                                                                                    <SpacerRightSmall />
                                                                                                                                    <Switch
                                                                                                                                        size='small'
                                                                                                                                        checked={subTitleI}
                                                                                                                                        onChange={() => handleSubTitleStyleI(false)}
                                                                                                                                    />
                                                                                                                                </FlexBox>
                                                                                                                                <SpacerLeftRightSmall />
                                                                                                                                <FlexBox style={{ border: "1px solid gray", padding: "2px 4px", borderRadius: 2 }}>
                                                                                                                                    <label style={{ textDecoration: "underline" }}>U</label>
                                                                                                                                    <SpacerRightSmall />
                                                                                                                                    <Switch
                                                                                                                                        size='small'
                                                                                                                                        checked={subTitleU}
                                                                                                                                        onChange={() => handleSubTitleStyleU(false)}
                                                                                                                                    />
                                                                                                                                </FlexBox>
                                                                                                                                <SpacerLeftRightMedium />
                                                                                                                                {
                                                                                                                                    props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                                                                        ?
                                                                                                                                        <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                                                                            <DeleteForeverIcon
                                                                                                                                                color='error'
                                                                                                                                                sx={{ cursor: "pointer" }}
                                                                                                                                            />
                                                                                                                                        </Tooltip>
                                                                                                                                        :
                                                                                                                                        props.index + 1 === props.contentBlock.length - 1
                                                                                                                                        &&
                                                                                                                                        <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                                                                            <IndeterminateCheckBoxOutlinedIcon
                                                                                                                                                fontSize='small'
                                                                                                                                                color='error'
                                                                                                                                                sx={{ cursor: "pointer" }}
                                                                                                                                            />
                                                                                                                                        </Tooltip>
                                                                                                                                }
                                                                                                                            </FlexBox>
                                                                                                                        </FlexBoxSB>
                                                                                                                        <SpacerTopBottomMedium />
                                                                                                                        <FormInput
                                                                                                                            name="subTitleBIU"
                                                                                                                            type='text'
                                                                                                                            value={props.data?.subTitleBIU ? props.data?.subTitleBIU : props.contentBlock.subTitleBIU}
                                                                                                                            onChange={props.onChangeString}
                                                                                                                            style={{
                                                                                                                                fontWeight: subTitleB ? 700 : 400, fontStyle: subTitleI ? "italic" : "normal", textDecoration: subTitleU ? "underline" : "none"
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    contentType === "img"
                                                                                                                        ?
                                                                                                                        <>
                                                                                                                            <FlexBoxSB>
                                                                                                                                <FormSubLabel>
                                                                                                                                    Image
                                                                                                                                </FormSubLabel>
                                                                                                                                <SpacerTopBottomMedium />
                                                                                                                                <FlexBox>
                                                                                                                                    {
                                                                                                                                        props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                                                                            ?
                                                                                                                                            <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                                                                                <DeleteForeverIcon
                                                                                                                                                    color='error'
                                                                                                                                                    sx={{ cursor: "pointer" }}
                                                                                                                                                />
                                                                                                                                            </Tooltip>
                                                                                                                                            :
                                                                                                                                            props.index + 1 === props.contentBlock.length - 1
                                                                                                                                            &&
                                                                                                                                            <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                                                                                <IndeterminateCheckBoxOutlinedIcon
                                                                                                                                                    fontSize='small'
                                                                                                                                                    color='error'
                                                                                                                                                    sx={{ cursor: "pointer" }}
                                                                                                                                                />
                                                                                                                                            </Tooltip>
                                                                                                                                    }
                                                                                                                                </FlexBox>
                                                                                                                            </FlexBoxSB>
                                                                                                                            <FormInput
                                                                                                                                name="img"
                                                                                                                                type='file'
                                                                                                                                value={props.contentBlock.img}
                                                                                                                                onChange={props.onChangeMedia}
                                                                                                                            />
                                                                                                                            {
                                                                                                                                typeof props.data?.img === "string"
                                                                                                                                    ?
                                                                                                                                    props.ambit !== "intermediate"
                                                                                                                                    &&
                                                                                                                                    <BlogContentImg
                                                                                                                                        src={props.data?.img}
                                                                                                                                    />
                                                                                                                                    :
                                                                                                                                    props.ambit !== "intermediate"
                                                                                                                                    &&
                                                                                                                                    props.data?.img
                                                                                                                                    &&
                                                                                                                                    <BlogContentImg
                                                                                                                                        src={URL.createObjectURL(props.data?.img)}
                                                                                                                                    />

                                                                                                                            }
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        contentType === "vid"
                                                                                                                            ?
                                                                                                                            <>
                                                                                                                                <FlexBoxSB>
                                                                                                                                    <FormSubLabel>
                                                                                                                                        Video
                                                                                                                                    </FormSubLabel>
                                                                                                                                    <FlexBox>
                                                                                                                                        {
                                                                                                                                            props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                                                                                ?
                                                                                                                                                <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                                                                                    <DeleteForeverIcon
                                                                                                                                                        color='error'
                                                                                                                                                        sx={{ cursor: "pointer" }}
                                                                                                                                                    />
                                                                                                                                                </Tooltip>
                                                                                                                                                :
                                                                                                                                                props.index + 1 === props.contentBlock.length - 1
                                                                                                                                                &&
                                                                                                                                                <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                                                                                    <IndeterminateCheckBoxOutlinedIcon
                                                                                                                                                        fontSize='small'
                                                                                                                                                        color='error'
                                                                                                                                                        sx={{ cursor: "pointer" }}
                                                                                                                                                    />
                                                                                                                                                </Tooltip>
                                                                                                                                        }
                                                                                                                                    </FlexBox>
                                                                                                                                </FlexBoxSB>
                                                                                                                                <SpacerTopBottomMedium />
                                                                                                                                <FormInput
                                                                                                                                    name="vid"
                                                                                                                                    type='file'
                                                                                                                                    value={props.contentBlock.vid}
                                                                                                                                    onChange={props.onChangeMedia}
                                                                                                                                />
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            contentType === "quote"
                                                                                                                                ?
                                                                                                                                <>
                                                                                                                                    <FlexBoxSB>
                                                                                                                                        <FormSubLabel>
                                                                                                                                            Quote
                                                                                                                                        </FormSubLabel>
                                                                                                                                        <FlexBox>
                                                                                                                                            {
                                                                                                                                                props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                                                                                    ?
                                                                                                                                                    <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                                                                                        <DeleteForeverIcon
                                                                                                                                                            color='error'
                                                                                                                                                            sx={{ cursor: "pointer" }}
                                                                                                                                                        />
                                                                                                                                                    </Tooltip>
                                                                                                                                                    :
                                                                                                                                                    props.index + 1 === props.contentBlock.length - 1
                                                                                                                                                    &&
                                                                                                                                                    <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                                                                                        <IndeterminateCheckBoxOutlinedIcon
                                                                                                                                                            fontSize='small'
                                                                                                                                                            color='error'
                                                                                                                                                            sx={{ cursor: "pointer" }}
                                                                                                                                                        />
                                                                                                                                                    </Tooltip>
                                                                                                                                            }
                                                                                                                                        </FlexBox>
                                                                                                                                    </FlexBoxSB>
                                                                                                                                    <SpacerTopBottomMedium />
                                                                                                                                    <FormInput
                                                                                                                                        name="quote"
                                                                                                                                        type='text'
                                                                                                                                        value={props.data?.quote ? props.data?.quote : props.contentBlock.quote}
                                                                                                                                        onChange={props.onChangeString}
                                                                                                                                    />
                                                                                                                                </>
                                                                                                                                :
                                                                                                                                contentType === "ol"
                                                                                                                                    ?
                                                                                                                                    <>
                                                                                                                                        <FlexBoxSB>
                                                                                                                                            <FormSubLabel>
                                                                                                                                                Ordered List
                                                                                                                                            </FormSubLabel>
                                                                                                                                            <FlexBox>
                                                                                                                                                <Tooltip title='Add list' placement='top' onClick={olAddRow}>
                                                                                                                                                    <AddCircleOutlineRoundedIcon
                                                                                                                                                        fontSize='small'
                                                                                                                                                        sx={{ cursor: "pointer" }}
                                                                                                                                                    />
                                                                                                                                                </Tooltip>
                                                                                                                                                <SpacerRightSmall />
                                                                                                                                                <Tooltip title='Remove list' placement='top' onClick={olSubtractRow}>
                                                                                                                                                    <RemoveCircleOutlineRoundedIcon
                                                                                                                                                        fontSize='small'
                                                                                                                                                        sx={{ cursor: "pointer" }}
                                                                                                                                                    />
                                                                                                                                                </Tooltip>
                                                                                                                                                <SpacerLeftRightMedium />
                                                                                                                                                {
                                                                                                                                                    props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                                                                                        ?
                                                                                                                                                        <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                                                                                            <DeleteForeverIcon
                                                                                                                                                                color='error'
                                                                                                                                                                sx={{ cursor: "pointer" }}
                                                                                                                                                            />
                                                                                                                                                        </Tooltip>
                                                                                                                                                        :
                                                                                                                                                        props.index + 1 === props.contentBlock.length - 1
                                                                                                                                                        &&
                                                                                                                                                        <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                                                                                            <IndeterminateCheckBoxOutlinedIcon
                                                                                                                                                                fontSize='small'
                                                                                                                                                                color='error'
                                                                                                                                                                sx={{ cursor: "pointer" }}
                                                                                                                                                            />
                                                                                                                                                        </Tooltip>
                                                                                                                                                }
                                                                                                                                            </FlexBox>
                                                                                                                                        </FlexBoxSB>
                                                                                                                                        <SpacerTopBottomMedium />
                                                                                                                                        {
                                                                                                                                            ol.map((item, index) => (
                                                                                                                                                <FlexBox key={index}>
                                                                                                                                                    <h4>
                                                                                                                                                        {index + 1}.
                                                                                                                                                    </h4>
                                                                                                                                                    <SpacerLeftRightSmall />
                                                                                                                                                    <FormInput
                                                                                                                                                        name="ol"
                                                                                                                                                        type='text'
                                                                                                                                                        value={props.data ? item.ol : ol.ol}
                                                                                                                                                        onChange={(e) => handleStringChangeOl(index, e)}
                                                                                                                                                        placeholder={props.data ? item.ol : ""}
                                                                                                                                                    />
                                                                                                                                                </FlexBox>
                                                                                                                                            ))
                                                                                                                                        }
                                                                                                                                    </>
                                                                                                                                    :
                                                                                                                                    contentType === "ul"
                                                                                                                                        ?
                                                                                                                                        <>
                                                                                                                                            <FlexBoxSB>
                                                                                                                                                <FormSubLabel>
                                                                                                                                                    Unordered List
                                                                                                                                                </FormSubLabel>
                                                                                                                                                <FlexBox>
                                                                                                                                                    <Tooltip title='Add list' placement='top' onClick={ulAddRow}>
                                                                                                                                                        <AddCircleOutlineRoundedIcon
                                                                                                                                                            fontSize='small'
                                                                                                                                                            sx={{ cursor: "pointer" }}
                                                                                                                                                        />
                                                                                                                                                    </Tooltip>
                                                                                                                                                    <SpacerLeftRightMedium />
                                                                                                                                                    {
                                                                                                                                                        props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                                                                                            ?
                                                                                                                                                            <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                                                                                                <DeleteForeverIcon
                                                                                                                                                                    color='error'
                                                                                                                                                                    sx={{ cursor: "pointer" }}
                                                                                                                                                                />
                                                                                                                                                            </Tooltip>
                                                                                                                                                            :
                                                                                                                                                            props.index + 1 === props.contentBlock.length - 1
                                                                                                                                                            &&
                                                                                                                                                            <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                                                                                                <IndeterminateCheckBoxOutlinedIcon
                                                                                                                                                                    fontSize='small'
                                                                                                                                                                    color='error'
                                                                                                                                                                    sx={{ cursor: "pointer" }}
                                                                                                                                                                />
                                                                                                                                                            </Tooltip>
                                                                                                                                                    }
                                                                                                                                                </FlexBox>
                                                                                                                                            </FlexBoxSB>
                                                                                                                                            <SpacerTopBottomMedium />
                                                                                                                                            {
                                                                                                                                                ul.map((item, index) => (
                                                                                                                                                    <FlexBox key={index}>
                                                                                                                                                        <FiberManualRecordIcon
                                                                                                                                                            fontSize='small'
                                                                                                                                                        />
                                                                                                                                                        <SpacerLeftRightSmall />
                                                                                                                                                        <FormInput
                                                                                                                                                            name="ul"
                                                                                                                                                            type='text'
                                                                                                                                                            value={props.data ? item.ul : ul.ul}
                                                                                                                                                            onChange={(e) => handleStringChangeUl(index, e)}
                                                                                                                                                        />
                                                                                                                                                    </FlexBox>
                                                                                                                                                ))
                                                                                                                                            }
                                                                                                                                        </>
                                                                                                                                        :
                                                                                                                                        contentType === "link"
                                                                                                                                        &&
                                                                                                                                        <>
                                                                                                                                            <FlexBoxSB>
                                                                                                                                                <FormSubLabel>
                                                                                                                                                    Link
                                                                                                                                                </FormSubLabel>
                                                                                                                                                <FlexBox>
                                                                                                                                                    {
                                                                                                                                                        props.blogDataDeepClone?.content.some(e => e.block === props.data?.block)
                                                                                                                                                            ?
                                                                                                                                                            <Tooltip title='Remove block' placement='top' onClick={() => setDeleteBlockModal(true)}>
                                                                                                                                                                <DeleteForeverIcon
                                                                                                                                                                    color='error'
                                                                                                                                                                    sx={{ cursor: "pointer" }}
                                                                                                                                                                />
                                                                                                                                                            </Tooltip>
                                                                                                                                                            :
                                                                                                                                                            props.index + 1 === props.contentBlock.length - 1
                                                                                                                                                            &&
                                                                                                                                                            <Tooltip title='Remove block' placement='top' onClick={blockSubtractRow}>
                                                                                                                                                                <IndeterminateCheckBoxOutlinedIcon
                                                                                                                                                                    fontSize='small'
                                                                                                                                                                    color='error'
                                                                                                                                                                    sx={{ cursor: "pointer" }}
                                                                                                                                                                />
                                                                                                                                                            </Tooltip>
                                                                                                                                                    }
                                                                                                                                                </FlexBox>
                                                                                                                                            </FlexBoxSB>
                                                                                                                                            <SpacerTopBottomMedium />
                                                                                                                                            <FormInput
                                                                                                                                                name="link"
                                                                                                                                                type='url'
                                                                                                                                                value={props.data?.link ? props.data?.link : props.contentBlock.link}
                                                                                                                                                onChange={props.onChangeString}
                                                                                                                                            />
                                                                                                                                        </>
            }
            {
                props.ambit === "intermediate"
                &&
                <>
                    <SpacerTopBottomXXXL />
                    <FlexBoxSA>
                        <FlexBox>
                            {
                                props.loadingUpdate
                                    ?
                                    <LoadingButton
                                        loading
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        sx={{ textTransform: "none" }}
                                        variant="outlined"
                                    >
                                        Saving
                                    </LoadingButton>
                                    :
                                    <Button
                                        variant='contained'
                                        color='success'
                                        sx={{ textTransform: "none", width: 80 }}
                                        onClick={props.handleSaveContentBlock}
                                        disabled={!props.intBlockNum || props.blogDataDeepClone?.content.some(el => el.block === parseFloat(props.intBlockNum)) || parseFloat(props.intBlockNum) < 1}>
                                        Save
                                    </Button>
                            }
                            <SpacerLeftRightMedium />
                            <Button
                                variant='contained'
                                color='error'
                                sx={{ textTransform: "none", width: 80 }}
                                onClick={handleCancelIntBlock}>
                                Cancel
                            </Button>
                        </FlexBox>
                    </FlexBoxSA>
                </>
            }
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={deleteBlockModal}
                onClose={() => setDeleteBlockModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={deleteBlockModal}>
                    <Box sx={DeleteModalStyle}>
                        <DeleteAlert
                            handleCancel={() => setDeleteBlockModal(false)}
                            handleDelete={handleDeleteBlock}
                            deleteItemDetail={`Block ${props.data?.block}`}
                        />
                    </Box>
                </Fade>
            </Modal>
        </ContentBlockContainer>
    )
}
