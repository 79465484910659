import React from 'react'
import { AbsoluteCenter, AddNewBtncontainer, AddNewBtncontainerDisabled, AlignCenter, FlexBoxSA } from '../style/global.styles'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { SpacerTopBottomMedium } from '../../design/spacer/spacer';
import { Tooltip } from '@mui/material';

export const AddNewBtn = (props) => {
    return (
        <>
            {
                props.disabled
                    ?
                    <Tooltip title='Upgrade to access this feature' placement='bottom'>
                        <AddNewBtncontainerDisabled>
                            <AbsoluteCenter>
                                <FlexBoxSA>
                                    <AddCircleOutlineRoundedIcon
                                        sx={{
                                            color: "#C9C9C9"
                                        }}
                                    />
                                </FlexBoxSA>
                                <SpacerTopBottomMedium />
                                <AlignCenter>
                                    <h5 style={{ color: "#C9C9C9" }}>
                                        {props.title}
                                    </h5>
                                </AlignCenter>
                            </AbsoluteCenter>
                        </AddNewBtncontainerDisabled>
                    </Tooltip>
                    :
                    <AddNewBtncontainer href={props.link} onClick={props.clicked}>
                        <AbsoluteCenter>
                            <FlexBoxSA>
                                <AddCircleOutlineRoundedIcon
                                    sx={{
                                        color: "#00B594"
                                    }}
                                />
                            </FlexBoxSA>
                            <SpacerTopBottomMedium />
                            <AlignCenter>
                                <h5 style={{ color: "#00B594" }}>
                                    {props.title}
                                </h5>
                            </AlignCenter>
                        </AbsoluteCenter>
                    </AddNewBtncontainer>
            }
        </>
    )
}
