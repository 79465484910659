import styled from 'styled-components'

export const NavbarContainer = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    padding-top: ${(props) => props.theme.space[4]};
    padding-bottom: ${(props) => props.theme.space[4]};
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[20]};

    @media only screen and (min-width: 1800px) {
        padding-top: ${(props) => props.theme.space[6]};
        padding-bottom: ${(props) => props.theme.space[6]};
        padding-left: ${(props) => props.theme.space[32]};
        padding-right: ${(props) => props.theme.space[32]};
    }
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[12]};
        padding-right: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 850px) {
        padding-top: ${(props) => props.theme.space[4]};
        padding-bottom: ${(props) => props.theme.space[4]};
        padding-left: ${(props) => props.theme.space[6]};
        padding-right: ${(props) => props.theme.space[6]};
        position: static;
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[4]};
        padding-right: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const NavLogoHolder = styled.div`
    width: 40px;
    height: 41px; 
    cursor: pointer;

    &:hover {
        transform: scale(1.05);
        transition: all 0.05s ease;
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.3s ease;
    }
`;

export const NavLogo = styled.img`
   width: 100%;
   height: 100%; 
`;


export const HamburgerContainer = styled.div`
    background-color: ${(props) => props.theme.colors.text.grey80};
    border-radius: 4px;
    padding: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
`;

export const MenuContainer = styled.div`
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]};
`;