// DEFAULT
import React, { useState, useContext } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import { AuthContext } from '../../../context/auth/auth.provider';
import { LoginFormComponent } from '../style/login.styles';
import { AlignCenter, FlexBoxSA } from '../../../global/style/global.styles';
import { ImgHolder } from '../../../global/components/imgHolder';
import { SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomXXL } from '../../../design/spacer/spacer';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// MAIN
export const LoginForm = (props) => {

    const { logInWithEmailAndPassword, loadingLogin } = useContext(AuthContext);

    const [role, setRole] = useState("Managing Director")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    return (
        <>
            <LoginFormComponent>
                <FlexBoxSA>
                    <ImgHolder
                        img={process.env.REACT_APP_CLIENT_LOGO}
                        width={props.width > 450 ? 80 : props.width > 350 ? 64 : 48}
                    />
                </FlexBoxSA>
                <SpacerTopBottomMedium />
                <AlignCenter>
                    <div>
                        {process.env.REACT_APP_CLIENT_NAME}
                    </div>
                </AlignCenter>
                <SpacerTopBottomXXL />
                <FormControl fullWidth size='small' disabled>
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                    >
                        <MenuItem />
                        <MenuItem value={"Managing Director"}>Managing Director</MenuItem>
                        <MenuItem value={"Chief Officer"}>Chief Officer</MenuItem>
                        <MenuItem value={"Department Head"}>Department Head</MenuItem>
                        <MenuItem value={"Manager"}>Manager</MenuItem>
                        <MenuItem value={"Associate"}>Associate</MenuItem>
                    </Select>
                </FormControl>
                <SpacerTopBottomLarge />
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="Email"
                    name="email"
                    type='email'
                    size='small'
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <SpacerTopBottomLarge />
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="Password"
                    name="password"
                    type='password'
                    size='small'
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <SpacerTopBottomLarge />
                {
                    loadingLogin
                        ?
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                            sx={{ textTransform: "none" }}
                            fullWidth
                        >
                            Logging in
                        </LoadingButton>
                        :
                        <Button
                            variant="contained"
                            fullWidth
                            color='info'
                            sx={{ textTransform: "none" }}
                            onClick={() => logInWithEmailAndPassword(email, password)}>
                            Login
                        </Button>
                }
            </LoginFormComponent>
        </>
    )
}