import React from 'react'
import { ProfileDetails } from './interface/profileDetails'
import { Navbar } from '../../global/components/navbar/navbar'
import PageStart from '../../global/components/pageStart'
import { Footer } from '../../global/components/footer'
import { Squeezer } from '../../global/style/global.styles'
import PageEnd from '../../global/components/pageEnd'

export const Profile = (props) => {
    return (
        <>
            <Navbar />
            <PageStart />
            <ProfileDetails
                width={props.width}
            />
             <PageEnd />
            <Footer />
        </>
    )
}