import React, { useContext } from 'react'
import { FlexBoxSA, Sandwich, Squeezer } from '../../../global/style/global.styles'
import { ApiContext } from '../../../context/api/api.provider';
import { BlogInfoTile } from '../components/blogInfoTile';
import { Grid, LinearProgress } from '@mui/material';
import { SpacerTopBottomXL } from '../../../design/spacer/spacer';
import { ImgHolder } from '../../../global/components/imgHolder';
import shrugEg from '../../../assets/images/shrugImg.png'

export const Drafts = (props) => {

    const { blogDrafts, blogsCollectionRef, loadingBlogDrafts } = useContext(ApiContext);

    return (
        <>
            <Squeezer>
                <h3>
                    Draft Blogs
                </h3>
                <SpacerTopBottomXL />
                {
                    loadingBlogDrafts
                        ?
                        <LinearProgress
                            color='warning'
                        />
                        :
                        blogDrafts.length > 0
                            ?
                            <Grid container spacing={4}>
                                {
                                    blogDrafts?.map((item, index) => (
                                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} key={index}>
                                            <BlogInfoTile
                                                width={props.width}
                                                location={props.location}
                                                navigate={props.navigate}
                                                data={item}
                                                blogsCollectionRef={blogsCollectionRef}
                                                refresh={props.refresh}
                                                setRefresh={props.setRefresh}
                                            />
                                        </Grid>
                                    ))
                                }
                            </Grid>
                            :
                            !loadingBlogDrafts
                            &&
                            <FlexBoxSA>
                                <Sandwich>
                                    <ImgHolder
                                        img={shrugEg}
                                        width={props.width > 850 ? 600 : "100%"}
                                    />
                                </Sandwich>
                            </FlexBoxSA>
                }
            </Squeezer>
        </>
    )
}
