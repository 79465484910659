import moment from 'moment'
import React, { useState } from 'react'
import { FlexBoxSE } from '../../../global/style/global.styles'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import { Backdrop, Box, Fade, Modal, Tooltip } from '@mui/material';
import { deleteDoc, doc, setDoc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import { DeleteAlert } from '../../../global/components/deleteAlert';

export const CMSTableRow = (props) => {

    const DeleteModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px',
        outline: 'none'
    };

    const [deleteModal, setDeleteModal] = useState(false)

    const handleService = async () => {
        const updateData = {
            serviced: true,
            updatedAt: new Date()
        }

        const docRef = doc(db, "leads", props.id);
        await setDoc(docRef, updateData, {
            merge: true
        }).then(() => console.log("Document updated"));
        
        props.setRefresh(!props.refresh)
    }

    const handleDelete = async () => {
        await deleteDoc(doc(db, "leads", props.id))
        props.setRefresh(!props.refresh)
        setDeleteModal(false)
    }

    return (
        <>
            <tr>
                <td style={{
                    padding: '8px',
                    border: '1px solid #B0B2B2',
                }}>
                    {moment(props.createdAt).format('DD-MM-YYYY')}
                </td>
                <td style={{
                    padding: '8px',
                    border: '1px solid #B0B2B2',
                }}>
                    {props.name}
                </td>
                <td style={{
                    padding: '8px',
                    border: '1px solid #B0B2B2',
                }}>
                    {props.email}
                </td>
                <td style={{
                    padding: '8px',
                    border: '1px solid #B0B2B2',
                }}>
                    {props.phone}
                </td>
                <td style={{
                    padding: '8px',
                    border: '1px solid #B0B2B2',
                }}>
                    {props.comName}
                </td>
                <td style={{
                    padding: '8px',
                    border: '1px solid #B0B2B2',
                }}>
                    {props.captured}
                </td>
                <td style={{
                    padding: '8px',
                    border: '1px solid #B0B2B2',
                }}>
                    {props.req}
                </td>
                <td style={{
                    padding: '8px',
                    border: '1px solid #B0B2B2',
                }}>
                    <FlexBoxSE>
                        <Tooltip title='Serviced' placement='top' onClick={handleService}>
                            <CheckBoxOutlinedIcon
                                color='success'
                                sx={{
                                    cursor: "pointer"
                                }}
                            />
                        </Tooltip>
                        <Tooltip title='Remove' placement='top' onClick={() => setDeleteModal(true)}>
                            <CancelPresentationOutlinedIcon
                                color='error'
                                sx={{
                                    cursor: "pointer"
                                }}

                            />
                        </Tooltip>
                    </FlexBoxSE>
                </td>
            </tr>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={deleteModal}
                onClose={() => setDeleteModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={deleteModal}>
                    <Box sx={DeleteModalStyle}>
                        <DeleteAlert
                            handleCancel={() => setDeleteModal(false)}
                            handleDelete={handleDelete}
                            deleteItemDetail={props.name}
                        />
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}
