// DEFAULT
import React from 'react'
import { SpacerLeftRightLarge, SpacerLeftRightMedium, SpacerLeftRightSmall, SpacerRightSmall } from '../../design/spacer/spacer'
import { BodyMediumWhite, BodyWhite, ExternalLink, FlexBox, FlexBoxSB, FlexColumnSA, FooterContainer, SubBodyMediumWhite, SubBodyWhite } from '../style/global.styles'

// STYLES


// COMPONENTS


// FUNCTIONS


// LIBRARIES
import CopyrightOutlinedIcon from '@mui/icons-material/CopyrightOutlined';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import { useLocation, useNavigate } from 'react-router-dom';
import useWindowDimensions from './screenSize';
import { PropaneSharp } from '@mui/icons-material';

// MAIN
export const Footer = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { height, width } = useWindowDimensions();

    return (
        <>
            <FooterContainer>
                <FlexBoxSB>
                    <FlexBox>
                        <CopyrightOutlinedIcon sx={{ fontSize: 14, color: '#FFFFFF' }} />
                        <SpacerRightSmall />
                        <div style={{ color: "white", fontSize: 10 }}>{new Date().getFullYear()} Admin Inspirise</div>
                        {/* <SpacerLeftRightSmall>
                            <FlexColumnSA>
                                <FiberManualRecordRoundedIcon sx={{ fontSize: 4, color: '#FFFFFF' }} />
                            </FlexColumnSA>
                        </SpacerLeftRightSmall>
                        <BodyWhite>All rights reserved</BodyWhite> */}
                    </FlexBox>
                    {
                        PropaneSharp.width > 650
                        &&
                        <FlexBox>
                            <div style={{ color: '#FFFFFF', fontSize: 12 }}>
                                Attribution
                            </div>
                            <SpacerLeftRightLarge />
                            <div style={{ color: '#FFFFFF', fontSize: 12 }}>
                                Academia
                            </div>
                            <SpacerLeftRightLarge />
                            <div style={{ color: '#FFFFFF', fontSize: 12 }}>
                                Feedback
                            </div>
                            <SpacerLeftRightLarge />
                            <div style={{ color: '#FFFFFF', fontSize: 12 }}>
                                Support
                            </div>
                        </FlexBox>
                    }
                    <ExternalLink href='http://www.inspirise.com/' target="_blank">
                        Powered by Inspirise
                    </ExternalLink>
                </FlexBoxSB>
            </FooterContainer>
        </>
    )
}