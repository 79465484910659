import styled from 'styled-components'

// ----------------------------------------CONTAINERS----------------------------------------//

export const CMSWrapper = styled.div`
    background-color: #F5F5F9;
    min-height: 100vh;
`;

export const ContentBlockContainer = styled.div`
    padding-top: ${(props) => props.theme.space[4]};
    padding-bottom: ${(props) => props.theme.space[4]};
`;

export const BlogInfoTileWrapper = styled.div`
    width: 440px;
    padding: 8px;
    border-radius: 4;
    cursor: pointer;
    position: relative;
    box-shadow: 0px 1px 5px 0px rgba(208,208,208,0.75);
-webkit-box-shadow: 0px 1px 5px 0px rgba(208,208,208,0.75);
-moz-box-shadow: 0px 1px 5px 0px rgba(208,208,208,0.75);

    &:hover {
        transform: scale(1.03);
        transition: all 0.3s ease;
        box-shadow: 0px 1px 6px 1px rgba(178,178,178,0.75);
-webkit-box-shadow: 0px 1px 6px 1px rgba(178,178,178,0.75);
-moz-box-shadow: 0px 1px 6px 1px rgba(178,178,178,0.75);
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.5s ease;
    }

    @media only screen and (min-width: 1800px) {
        
    }
    @media only screen and (max-width: 1300px) {
        
    }
    @media only screen and (max-width: 1100px) {
      
    }
    @media only screen and (max-width: 850px) {
        
    }
    @media only screen and (max-width: 650px) {
        
    }
    @media only screen and (max-width: 450px) {
        width: 100%;
    }
    @media only screen and (max-width: 370px) {
    }
`;

export const BlogInfoTileDateText = styled.div`
    font-family: 'Open Sans', sans-serif;
    font-size: ${(props) => props.theme.fontSizes.caption};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const BlogInfoTileHeading = styled.div`
    font-family: 'Libre Baskerville', serif;
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    line-height: 28px;
`;

export const BlogInfoTileDescText = styled.div`
    font-family: 'Open Sans', sans-serif;
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    letter-spacing: 0.02em;
`;

export const BlogTileActBarPosition = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
`;

export const BlogTileActBarContainer = styled.div`
    ${'' /* background-color: rgba(0, 228, 187, 0.8); */}
    background-color: rgba(0,0,0,0.2);
    padding-top: ${(props) => props.theme.space[3]};
    padding-bottom: ${(props) => props.theme.space[3]};
    padding-left: ${(props) => props.theme.space[2]};
    padding-right: ${(props) => props.theme.space[2]};
    border-radius: 80px;
    width: 64px;
    height: 200px;
    z-index: 2;
`;

export const AddNewBlogWrapper = styled.div`
    position: relative;
`;

export const PreviewPosition = styled.div`
    position: fixed;
    bottom: 40px;
    right: 40px;
`;

export const PreviewContainer = styled.div`
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: relative;
`;

export const BlogWrapper = styled.div`
   
`;

export const BlogContainer = styled.div`
    padding-top: ${(props) => props.theme.space[8]};
    padding-bottom: ${(props) => props.theme.space[8]};
    padding-left: ${(props) => props.theme.space[26]};
    padding-right: ${(props) => props.theme.space[26]};
    
    @media only screen and (min-width: 1800px) {
        padding-left: 280px;
        padding-right: 280px;
    }
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[15]};
        padding-right: ${(props) => props.theme.space[15]};
    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[11]};
        padding-right: ${(props) => props.theme.space[11]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[7]};
        padding-right: ${(props) => props.theme.space[7]};
    }
    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
    @media only screen and (max-width: 450px) {
        
    }
    @media only screen and (max-width: 370px) {
    }
`;

export const BlogHeroWrapper = styled.div`
    height: 100vh;
    position: relative;
`;

export const BlogHeroHeading = styled.div`
    font-family: 'Libre Baskerville', serif;
    font-size: ${(props) => props.theme.fontSizes.heading};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    line-height: 48px;
    max-width: 900px;

    @media only screen and (min-width: 1800px) {
        font-size: 48px;
        line-height: 64px;
        max-width: 1200px;
    }
    @media only screen and (max-width: 1300px) {
    }
    @media only screen and (max-width: 1100px) {
    }
    @media only screen and (max-width: 850px) {
    }
    @media only screen and (max-width: 650px) {
    }
    @media only screen and (max-width: 450px) {
        font-size: ${(props) => props.theme.fontSizes.subHeading};
    }
    @media only screen and (max-width: 370px) {
        font-size: ${(props) => props.theme.fontSizes.title};
    }
`;

export const BlogHeroHeadingPosition = styled.div`
    position: absolute;
    left: ${(props) => props.theme.space[26]};
    top: 50%;
    transform: translate(0,-50%);

    @media only screen and (min-width: 1800px) {
        left: 280px;
    }
    @media only screen and (max-width: 1300px) {
        left: ${(props) => props.theme.space[15]};
    }
    @media only screen and (max-width: 1100px) {
        left: ${(props) => props.theme.space[11]};
    }
    @media only screen and (max-width: 850px) {
        left: ${(props) => props.theme.space[7]};
    }
    @media only screen and (max-width: 650px) {
    }
    @media only screen and (max-width: 450px) {
        left: ${(props) => props.theme.space[1]};
    }
    @media only screen and (max-width: 370px) {
    }
`;

export const BlogHeroTopicText = styled.div`
    font-family: 'Open Sans', sans-serif;
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.bold};

    @media only screen and (min-width: 1800px) {
        font-size: ${(props) => props.theme.fontSizes.subTitle};
    }
`;

export const BlogHeroTopicPosition = styled.div`
    position: absolute;
    left: ${(props) => props.theme.space[26]};
    top: 30%;
    transform: translate(0,-50%);

    @media only screen and (min-width: 1800px) {
        left: 280px;
    }
    @media only screen and (max-width: 1300px) {
        left: ${(props) => props.theme.space[15]};
    }
    @media only screen and (max-width: 1100px) {
        left: ${(props) => props.theme.space[11]};
    }
    @media only screen and (max-width: 850px) {
        left: ${(props) => props.theme.space[7]};
    }
    @media only screen and (max-width: 650px) {
        top: 10%;
    }
    @media only screen and (max-width: 450px) {
        top: 20%;
        left: ${(props) => props.theme.space[1]};
    }
    @media only screen and (max-width: 370px) {
    }
`;

export const BlogHeroDateText = styled.div`
    font-family: 'Open Sans', sans-serif;
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.bold};

    @media only screen and (min-width: 1800px) {
        font-size: ${(props) => props.theme.fontSizes.subTitle};
    }
`;

export const BlogHeroDatePosition = styled.div`
    position: absolute;
    left: ${(props) => props.theme.space[26]};
    bottom: ${(props) => props.theme.space[8]};

    @media only screen and (min-width: 1800px) {
        left: 280px;
    }
    @media only screen and (max-width: 1300px) {
        left: ${(props) => props.theme.space[15]};
    }
    @media only screen and (max-width: 1100px) {
        left: ${(props) => props.theme.space[11]};
    }
    @media only screen and (max-width: 850px) {
        left: ${(props) => props.theme.space[7]};
    }
    @media only screen and (max-width: 650px) {
    }
    @media only screen and (max-width: 450px) {
        left: ${(props) => props.theme.space[1]};
    }
    @media only screen and (max-width: 370px) {
    }
`;

export const BlogHeroDurationText = styled.div`
    font-family: 'Open Sans', sans-serif;
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};

    @media only screen and (min-width: 1800px) {
        font-size: ${(props) => props.theme.fontSizes.subTitle};
    }
`;

export const BlogHeroDurationPosition = styled.div`
    position: absolute;
    right: ${(props) => props.theme.space[26]};
    bottom: ${(props) => props.theme.space[8]};

    @media only screen and (min-width: 1800px) {
        right: 280px;
    }
    @media only screen and (max-width: 1300px) {
        right: ${(props) => props.theme.space[15]};
    }
    @media only screen and (max-width: 1100px) {
        right: ${(props) => props.theme.space[11]};
    }
    @media only screen and (max-width: 850px) {
        right: ${(props) => props.theme.space[7]};
    }
    @media only screen and (max-width: 650px) {
    }
    @media only screen and (max-width: 450px) {
        right: ${(props) => props.theme.space[1]};
        bottom: ${(props) => props.theme.space[6]};
    }
    @media only screen and (max-width: 370px) {
    }
`;

export const BlogContentPara = styled.div`
    font-family: 'Open Sans', sans-serif;
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    letter-spacing: 0.04em;
    line-height: 36px;
    text-align: justify;
`;

export const BlogRecommendWrapper = styled.div`
    background-color: #D9C9B7;
    opacity: 0.8;
    background-image:  radial-gradient(#826020 0.5px, transparent 0.5px), radial-gradient(#826020 0.5px, #D9C9B7 0.5px);
    background-size: 20px 20px;
    background-position: 0 0,10px 10px;
    border-radius: 8px;
`;

export const ModalClosePosition = styled.div`
   position: absolute;
   top: 16px;
   right: 16px;
   z-index: 2;
`;

export const BlogContentTitle = styled.div`
    font-family: 'Open Sans', sans-serif;
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    letter-spacing: 0.05em;
    line-height: 40px;
`;

export const BlogContentSubTitle = styled.div`
    font-family: 'Open Sans', sans-serif;
    font-size: ${(props) => props.theme.fontSizes.title};
    letter-spacing: 0.04em;
    line-height: 36px;
`;

export const BlogContentLi = styled.li`
    font-family: 'Open Sans', sans-serif;   
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    letter-spacing: 0.04em;
    line-height: 36px;
    padding: 4px 0;
`;

export const BlogContentImg = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

export const PagesTileContainer = styled.div`
    margin: ${(props) => props.theme.space[3]};
    position: relative;
    border-radius: 16px;
    background-color: #F7FFFE;
    opacity: 0.8;
    background-image: radial-gradient(#00E4BB 0.5px, #F7FFFE 0.5px);
    background-size: 10px 10px;
    width: 260px;
    height: 320px;
    cursor: pointer;
    box-shadow: 0px 1px 5px 0px rgba(206,206,206,0.75);
-webkit-box-shadow: 0px 1px 5px 0px rgba(206,206,206,0.75);
-moz-box-shadow: 0px 1px 5px 0px rgba(206,206,206,0.75);

    &:hover {
        transform: scale(1.03);
        transition: all 0.3s ease;
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.5s ease;
    }
`;