import React, { createContext, useState, useEffect, useContext } from 'react'
import { auth, db } from '../../firebase/firebase';
import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut
} from "firebase/auth";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
    setDoc,
    doc,
} from "firebase/firestore";
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {

    const navigate = useNavigate();

    const [user, setUser] = useState({});

    const [loadingLogin, setLoadingLogin] = useState(false);

    const loginAction = () => {
        navigate("/dashboard");
    }

    const logInWithEmailAndPassword = async (email, password) => {

        setLoadingLogin(true);

        try {
            await signInWithEmailAndPassword(
                auth,
                email,
                password
            ).then(loginAction);
        } catch (err) {
            console.error(err);
            alert(err.message);
        }

        setLoadingLogin(false);
    };

    const registerWithEmailAndPassword = async (values) => {
        try {
            const res = await createUserWithEmailAndPassword(
                auth,
                values.email,
                values.password
            );
            const user = res.user;
            setUser(user);
            await setDoc(doc(db, "users", user.uid), {
                uid: user.uid,
                firstName: values.firstName,
                lastName: values.lastName,
                phoneNumber: values.phoneNumber,
                email: values.email,
                permission: values.permission,
                authProvider: "local",
            }).then(loginAction)

        } catch (err) {
            console.error(err);
            alert(err.message);
        }
    };

    const sendPasswordReset = async (email) => {
        try {
            await sendPasswordResetEmail(auth, email);
            alert("Password reset link sent!");
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
    };
    const logout = () => {
        signOut(auth);
        navigate("/")
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentuser) => {

            setUser(currentuser);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                registerWithEmailAndPassword,
                logInWithEmailAndPassword,
                logout,
                loadingLogin
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}