import React, { useState } from 'react'
import { FlexBox, FlexColumnSB } from '../../../global/style/global.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import { SpacerBottomMedium, SpacerLeftRightLarge, SpacerLeftRightMedium, SpacerRightLarge, SpacerRightXL, SpacerTopBottomMedium } from '../../../design/spacer/spacer'
import { BlogInfoTileDateText, BlogInfoTileDescText, BlogInfoTileHeading, BlogInfoTileWrapper, BlogTileActBarPosition } from '../style/cms.styles'
import moment from 'moment'
import { Backdrop, Box, Fade, Grid, Modal } from '@mui/material'
import { BlogTileActBar } from './blogTileActBar'
import { deleteDoc, doc, setDoc } from 'firebase/firestore'
import { db } from '../../../firebase/firebase'
import { DeleteAlert } from '../../../global/components/deleteAlert'
import axios from 'axios'
import { extractPublicId } from 'cloudinary-build-url'

export const BlogInfoTile = (props) => {

    const DeleteModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px',
        outline: 'none'
    };

    const [showActBar, setshowActBar] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)

    const clickedEdit = () => {
        props.navigate(`/cms/blog/live/${props.data.id}`)
    }

    const clickedFeature = async () => {
        const updateData = {
            feature: props.data?.feature === 1 ? 2 : 1,
            updatedAt: new Date()
        }

        const docRef = doc(db, "blogs", props.data?.id);
        await setDoc(docRef, updateData, {
            merge: true
        }).then(() => console.log("Document updated"));

        props.setRefresh(!props.refresh);
    }

    const clickedUnFeature = async () => {
        const updateData = {
            feature: 0,
            updatedAt: new Date()
        }

        const docRef = doc(db, "blogs", props.data?.id);
        await setDoc(docRef, updateData, {
            merge: true
        }).then(() => console.log("Document updated"));

        props.setRefresh(!props.refresh);
    }

    const clickedInactive = async () => {
        const updateData = {
            status: "inactive",
            updatedAt: new Date()
        }

        const docRef = doc(db, "blogs", props.data?.id);
        await setDoc(docRef, updateData, {
            merge: true
        }).then(() => console.log("Document updated"));

        props.setRefresh(!props.refresh);
    }

    const clickedActive = async () => {
        const updateData = {
            status: "active",
            updatedAt: new Date()
        }

        const docRef = doc(db, "blogs", props.data?.id);
        await setDoc(docRef, updateData, {
            merge: true
        }).then(() => console.log("Document updated"));

        props.setRefresh(!props.refresh);
    }

    const clickedDelete = () => {
        setDeleteModal(true)
    }

    const dpArr = props.data?.dp?.map((item) => extractPublicId(item.url))
    const contentArr = props.data?.content?.filter((x) => x.img).map((item) => extractPublicId(item.img))
    const mediaArr = [
        ...dpArr,
        ...contentArr
    ]

    const handleDeleteBlog = async () => {

        try {
            mediaArr.map(async (item) => {
                if (item) {
                    await axios.delete(`${process.env.REACT_APP_SERVER_HOST}/${item}`);
                }
            })
            if (props.location.pathname === "/cms/blog/drafts") {
                await deleteDoc(doc(db, "blog-drafts", props.data?.id));
            } else {
                await deleteDoc(doc(db, "blogs", props.data?.id));
            }
            props.setRefresh(!props.refresh)
            setDeleteModal(false)
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <BlogInfoTileWrapper
                onMouseEnter={() => setshowActBar(true)}
                onMouseLeave={() => setshowActBar(false)}
                style={{
                    backgroundColor: props.data?.status === "active" ? "rgba(255, 255, 255, 0.8)" : "rgba(0, 0, 0, 0.5)"
                }}>
                <a href={
                    props.location.pathname === "/cms/blog/drafts"
                        ?
                        `/cms/blog/draft/${props.data.id}`
                        :
                        `https://blog.inspirise.com/${props.data.id}`
                } style={{ textDecoration: "none" }} target={
                    props.location.pathname === "/cms/blog/drafts"
                        ?
                        '_self'
                        :
                        '_blank'
                }>
                    <FlexBox style={{ alignItems: "normal", display: props.width > 450 ? "flex" : "block" }}>
                        <div>
                            <ImgHolder
                                img={props.data?.dp.filter((x) => x.type === "dp23")[0]?.url}
                                width={props.width > 450 ? 200 : "100%"}
                                height={props.width > 450 ? 300 : "100%"}
                            />
                        </div>
                        {
                            props.width > 450
                                ?
                                <SpacerRightLarge />
                                :
                                <SpacerBottomMedium />
                        }
                        <FlexColumnSB>
                            <div>
                                <BlogInfoTileDateText>
                                    {moment(props.data?.createdAt.toDate()).format("MMM YYYY")}
                                </BlogInfoTileDateText>
                                <SpacerTopBottomMedium />
                                <BlogInfoTileHeading>
                                    {props.data?.heading}
                                </BlogInfoTileHeading>
                            </div>
                            <div>
                                <BlogInfoTileDescText>
                                    {props.data?.description}
                                </BlogInfoTileDescText>
                            </div>
                        </FlexColumnSB>
                    </FlexBox>
                </a>
                {
                    showActBar
                    &&
                    <BlogTileActBarPosition>
                        <BlogTileActBar
                            clickedEdit={clickedEdit}
                            clickedFeature={clickedFeature}
                            clickedUnFeature={clickedUnFeature}
                            clickedInactive={clickedInactive}
                            clickedActive={clickedActive}
                            clickedDelete={clickedDelete}
                            status={props.data?.status}
                            feature={props.data?.feature}
                            draft={props.location.pathname === "/cms/blog/drafts"}
                        />
                    </BlogTileActBarPosition>
                }
            </BlogInfoTileWrapper>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={deleteModal}
                onClose={() => setDeleteModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={deleteModal}>
                    <Box sx={DeleteModalStyle}>
                        <DeleteAlert
                            handleCancel={() => setDeleteModal(false)}
                            handleDelete={handleDeleteBlog}
                            deleteItemDetail={props.data?.heading}
                        />
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}