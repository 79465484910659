import React, { useContext } from 'react'
import { Navbar } from '../../global/components/navbar/navbar'
import { Footer } from '../../global/components/footer'
import { Container, FlexBox, FlexBoxSA } from '../../global/style/global.styles'
import { Button } from '@mui/material'
import { SpacerLeftRightLarge, SpacerTopBottomLarge, SpacerTopBottomXL, SpacerTopBottomXXXL } from '../../design/spacer/spacer'
import { Outlet } from 'react-router-dom'
import PageStart from '../../global/components/pageStart'
import PageEnd from '../../global/components/pageEnd'
import blogBtnBg from '../../assets/images/blogBtnBg.png'
import websiteBtnBg from '../../assets/images/websiteBtnBg.png'
import { ActionBtn } from '../../global/components/actionBtn'
import { CMSWrapper } from './style/cms.styles'
import ScrollToTop from '../../global/components/scrollToTop'
import '../../global/style/global.css'

export const CMS = (props) => {

  return (
    <CMSWrapper className='cf'>
      <ScrollToTop />
      <Navbar />
      <PageStart />
      {
        props.location.pathname === "/cms"
        &&
        <Container>
          <h1>
            Content Management System
          </h1>
          <SpacerTopBottomXXXL />
          <FlexBox>
            <ActionBtn
              title="Website"
              bgImg={websiteBtnBg}
              link="/cms/website"
            />
            <SpacerLeftRightLarge />
            <ActionBtn
              title="Blog"
              bgImg={blogBtnBg}
              link="/cms/blog"
            />
          </FlexBox>
          {/* <a href="/cms/website" style={{ textDecoration: "none" }}>
            <Button
              variant='contained'
              sx={{ textTransform: "none", width: props.width > 850 ? "50%" : "100%" }}
              color="info"
              size='large'
              disabled={props.location.pathname.includes("website")}>
              Website
            </Button>
          </a>
          <SpacerTopBottomXL />
          <a href="/cms/blog" style={{ textDecoration: "none" }}>
            <Button
              variant='contained'
              sx={{ textTransform: "none", width: props.width > 850 ? "50%" : "100%" }}
              color="info"
              size='large'
              disabled={props.location.pathname.includes("blog")}>
              Blog
            </Button>
          </a> */}
        </Container>
      }
      {/* {
        props.location.pathname !== "/cms"
        &&
        <FlexBoxSA>
          <FlexBox>
            <Button
              variant='outlined'
              sx={{ textTransform: "none" }}
              disabled={props.location.pathname.includes("website")}
              onClick={() => props.navigate("/cms/website")}>
              Website
            </Button>
            <SpacerLeftRightLarge />
            <Button
              variant='outlined'
              sx={{ textTransform: "none" }}
              disabled={props.location.pathname.includes("blog")}
              onClick={() => props.navigate("/cms/blog")}>
              Blog
            </Button>
          </FlexBox>
        </FlexBoxSA>
      } */}
      <Outlet />
      <PageEnd />
      <Footer />
    </CMSWrapper>
  )
}
