import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/auth/auth.provider";

const ProtectedRoute = ({ children }) => {

    const { user } = useContext(AuthContext);

    if (!user) {
        return <Navigate to="/" />;
    }
    return children;
};

export default ProtectedRoute;