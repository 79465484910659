import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import React, { createContext, useState, useEffect, useContext } from 'react'
import { db } from '../../firebase/firebase';
import { UpdateContext } from '../update/update.provider';
import { AuthContext } from '../auth/auth.provider';

export const ApiContext = createContext()

export const ApiProvider = ({ children }) => {

    const { user } = useContext(AuthContext);
    const { refresh, profileRefresh } = useContext(UpdateContext);

    const [profile, setProfile] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [blogDrafts, setBlogDrafts] = useState([]);
    const [leads, setLeads] = useState([]);
    const [newsSubscribers, setNewsSubscribers] = useState([]);
    const [generalArchive, setGeneralArchive] = useState([]);
    const [socialArchive, setSocialArchive] = useState([]);

    const [loadingProfile, setLoadingProfile] = useState(true);
    const [loadingBlogs, setLoadingBlogs] = useState(true);
    const [loadingBlogDrafts, setLoadingBlogDrafts] = useState(true);
    const [loadingLeads, setLoadingLeads] = useState(true);
    const [loadingNewsSubscribers, setLoadingNewsSubscribers] = useState(true);
    const [loadingGeneralArchive, setLoadingGeneralArchive] = useState(true);
    const [loadingSocialArchive, setLoadingSocialArchive] = useState(true);

    const leadsCollectionRef = collection(db, "leads");
    const newsSubscribersCollectionRef = collection(db, "newsletter-subscribers");
    const blogsCollectionRef = collection(db, "blogs");
    const blogDraftsCollectionRef = collection(db, "blog-drafts");
    const generalArchiveCollectionRef = collection(db, "general-archive");
    const socialArchiveCollectionRef = collection(db, "social-archive");

    useEffect(() => {
        try {
            const getBlogs = async () => {
                setLoadingBlogs(true)
                const data = await getDocs(blogsCollectionRef);
                setBlogs(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingBlogs(false)
            };
            getBlogs()

            const getBlogDrafts = async () => {
                setLoadingBlogDrafts(true)
                const data = await getDocs(blogDraftsCollectionRef);
                setBlogDrafts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingBlogDrafts(false)
            };
            getBlogDrafts()

            const getLeads = async () => {
                setLoadingLeads(true)
                const data = await getDocs(leadsCollectionRef);
                setLeads(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingLeads(false)
            };
            getLeads()

            const getNewsSubscribers = async () => {
                setLoadingNewsSubscribers(true)
                const data = await getDocs(newsSubscribersCollectionRef);
                setNewsSubscribers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingNewsSubscribers(false)
            };
            getNewsSubscribers()

            const getGeneralArchive = async () => {
                setLoadingGeneralArchive(true)
                const data = await getDocs(generalArchiveCollectionRef);
                setGeneralArchive(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingGeneralArchive(false)
            };
            getGeneralArchive()

            const getSocialArchive = async () => {
                setLoadingSocialArchive(true)
                const data = await getDocs(socialArchiveCollectionRef);
                setSocialArchive(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingSocialArchive(false)
            };
            getSocialArchive()

        } catch (error) {
            const { code, message } = error;
            console.log(error);
        }
    }, [user])

    useEffect(() => {
        try {
            const getBlogs = async () => {
                setLoadingBlogs(true)
                const data = await getDocs(blogsCollectionRef);
                setBlogs(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingBlogs(false)
            };
            getBlogs()

            const getBlogDrafts = async () => {
                setLoadingBlogDrafts(true)
                const data = await getDocs(blogDraftsCollectionRef);
                setBlogDrafts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingBlogDrafts(false)
            };
            getBlogDrafts()

            const getLeads = async () => {
                setLoadingLeads(true)
                const data = await getDocs(leadsCollectionRef);
                setLeads(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingLeads(false)
            };
            getLeads()

            const getNewsSubscribers = async () => {
                setLoadingNewsSubscribers(true)
                const data = await getDocs(newsSubscribersCollectionRef);
                setNewsSubscribers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingNewsSubscribers(false)
            };
            getNewsSubscribers()

            const getGeneralArchive = async () => {
                setLoadingGeneralArchive(true)
                const data = await getDocs(generalArchiveCollectionRef);
                setGeneralArchive(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingGeneralArchive(false)
            };
            getGeneralArchive()

            const getSocialArchive = async () => {
                setLoadingSocialArchive(true)
                const data = await getDocs(socialArchiveCollectionRef);
                setSocialArchive(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingSocialArchive(false)
            };
            getSocialArchive()

        } catch (error) {
            const { code, message } = error;
            console.log(error);
        }
    }, [refresh])

    useEffect(() => {
        setLoadingProfile(true);
        if (user) {
            const { email, uid } = user;
            try {
                const userDocRef = doc(db, "users", user.uid)
                const getUserProfile = async () => {
                    const data = await getDoc(userDocRef)
                        .then((doc) => {
                            setProfile(doc.data())
                        })
                };
                getUserProfile();

            } catch (error) {
                const { code, message } = error;
                console.log(error);
            }
        }
        setLoadingProfile(false);
    }, [user, profileRefresh])

    return (
        <ApiContext.Provider
            value={{
                leads,
                leadsCollectionRef,
                loadingLeads,
                newsSubscribers,
                newsSubscribersCollectionRef,
                loadingNewsSubscribers,
                blogs,
                blogsCollectionRef,
                loadingBlogs,
                blogDrafts,
                blogDraftsCollectionRef,
                loadingBlogDrafts,
                profile,
                setProfile,
                loadingProfile,
                generalArchive,
                generalArchiveCollectionRef,
                loadingGeneralArchive,
                socialArchive,
                socialArchiveCollectionRef,
                loadingSocialArchive
            }}
        >
            {children}
        </ApiContext.Provider>
    )
}