import React from 'react'
import styled from 'styled-components'

const PageEnd = (props) => {
    return (
        <PageEndContainer />
    )
}

const PageEndContainer = styled.div`
    padding-bottom: 64px;

    @media only screen and (max-width: 850px) {
        padding-top: 0;
    }
`;

export default PageEnd