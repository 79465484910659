import React from 'react'
import { BlogContent } from '../components/blogContent'
import { BlogHero } from '../components/blogHero'
import { Tooltip } from '@mui/material'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { ModalClosePosition } from '../style/cms.styles';

export const BlogPage = (props) => {

    return (
        <>
            <ModalClosePosition onClick={props.closeModal}>
                <Tooltip title='Close Preview' placement='right'>
                    <CancelRoundedIcon
                        color='error'
                        fontSize='large'
                        sx={{
                            cursor: "pointer"
                        }}
                    />
                </Tooltip>
            </ModalClosePosition>
            <BlogHero
                width={props.width}
                heading={props.heading}
                subHeading={props.subHeading}
                author={props.author}
                duration={props.duration}
                topic={props.topic}
                banner169={props.bannerFile169}
                banner54={props.bannerFile54}
                banner34={props.bannerFile34}
                heroTextColor={props.heroTextcolor}
                date={new Date()}
                blogData={props.blogData}
            />
            <BlogContent
                width={props.width}
                format={props.format}
                contentBlock={props.contentBlock}
                blogData={props.blogData}
            />
        </>
    )
}
